import { useQuery } from "@tanstack/react-query";
import { httpClient } from "common/httpClient";
import { numeric } from "common/utils";
import { Panel, PanelBody } from "components/Panel";
import NoData from "components/NoData/NoData";
import SortableTable from "components/new/SortableTable";
import SortableTableHeader from "components/new/SortableTableHeader";
import { EditUserActionLink } from "containers/Admin/Users";
import { EditOrgActionLink } from "containers/Admin/Orgs";

export default function SessionRecording() {
    return (
        <>
            <UsersWithSessionRecording />
            <OrgsWithSessionRecording />
        </>
    )
}

function UsersWithSessionRecording() {
    const { isFetching, isLoading, data: users } = useUsersWithSessionRecording();
    const hasUsers = users?.length > 0;

    return (
        <Panel title="Users with active session recording" waiting={isFetching} green>
            <PanelBody loading={isLoading}>
                {hasUsers
                    ? <>
                        <SortableTable data={users} defaultSortCriteria={{ fieldName: "fullName", fieldType: "string" }}>
                            {({ data, sortCriteria, handleSort }) => {
                                return (
                                    <table>
                                        <thead>
                                            <tr>
                                                <SortableTableHeader label="User" fieldName="fullName" sortCriteria={sortCriteria} handleSort={handleSort} />
                                                <SortableTableHeader label="Org" fieldName="accountName" sortCriteria={sortCriteria} handleSort={handleSort} />
                                                <th>Id</th>
                                                <SortableTableHeader label="Email" fieldName="email" sortCriteria={sortCriteria} handleSort={handleSort} />
                                                <th className="th--shrink"></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {data.map(user => {
                                                return (
                                                    <tr key={user.id} id={user.id} className="hover">
                                                        <td>{user.fullName}</td>
                                                        <td>{user.accountName}</td>
                                                        <td>{user.id}</td>
                                                        <td>{user.email}</td>
                                                        <td>
                                                            <EditUserActionLink user={user} className="icon icon-edit" />
                                                        </td>
                                                    </tr>
                                                );
                                            })}
                                        </tbody>
                                    </table>
                                );
                            }}
                        </SortableTable>
                    </>
                    : <NoData />
                }
            </PanelBody>
        </Panel>
    )
}

function OrgsWithSessionRecording() {
    const { isFetching, isLoading, data: orgs } = useOrgsWithSessionRecording();
    const hasOrgs = orgs?.length > 0;

    return (
        <Panel title="Orgs with active session recording" waiting={isFetching} green>
            <PanelBody loading={isLoading}>
                {hasOrgs
                    ? <>
                        <SortableTable data={orgs} defaultSortCriteria={{ fieldName: "name", fieldType: "string" }}>
                            {({ data, sortCriteria, handleSort }) => {
                                return (
                                    <table>
                                        <thead>
                                            <tr>
                                                <SortableTableHeader label="Org" fieldName="name" sortCriteria={sortCriteria} handleSort={handleSort} />
                                                <th>Id</th>
                                                <th className="numeric">User count</th>
                                                <th className="th--shrink"></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {data.map(org => {
                                                return (
                                                    <tr key={org.id} id={org.id} className="hover">
                                                        <td>{org.name}</td>
                                                        <td>{org.id}</td>
                                                        <td className="numeric">{numeric.format(org.userCount)}</td>
                                                        <td>
                                                            <EditOrgActionLink org={org} className="icon icon-edit" />
                                                        </td>
                                                    </tr>
                                                );
                                            })}
                                        </tbody>
                                    </table>
                                );
                            }}
                        </SortableTable>
                    </>
                    : <NoData />
                }
            </PanelBody>
        </Panel>
    )
}

function useUsersWithSessionRecording() {
    const query = useQuery({
        queryKey: ["orgs", "sessionRecordings", "users"],
        queryFn: async () => httpClient.get(`admin/sessionRecording/users`),
        retry: false
    });

    return {
        isFetching: query.isFetching,
        isLoading: query.isLoading,
        data: query.data,
    };
}

function useOrgsWithSessionRecording() {
    const query = useQuery({
        queryKey: ["orgs", "sessionRecordings", "accounts"],
        queryFn: async () => httpClient.get(`admin/sessionRecording/accounts`),
        retry: false
    });

    return {
        isFetching: query.isFetching,
        isLoading: query.isLoading,
        data: query.data,
    };
}
