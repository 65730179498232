import React from "react";
import { Switch, Route } from "react-router-dom";
import { Panel, PanelBody } from "components/Panel";
import UpgradeRuns from "./UpgradeRuns";
import AnalysisDiagnostics from "./AnalysisDiagnostics";
import OtherTools from "./OtherTools";

export default function Index() {
    const baseUrl = "/app/admin/devTeamOnly";
    const tabs = {
        upgrade: { title: "Upgrade runs", path: `${baseUrl}`, exact: true },
        diagnostics: { title: "Diagnostics", path: `${baseUrl}/diagnostics`, exact: true },
        otherTools: { title: "Other tools", path: `${baseUrl}/tools`, exact: true },
    };
    return (
        <Panel tabs={tabs}>
            <PanelBody>
                <Switch>
                    <Route exact path={tabs.upgrade.path} render={() => <UpgradeRuns />} />
                    <Route exact path={tabs.diagnostics.path} render={() => <AnalysisDiagnostics />} />
                    <Route exact path={tabs.otherTools.path} render={() => <OtherTools />} />
                </Switch>
            </PanelBody>
        </Panel>
    );
}
