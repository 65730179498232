import React, { useState } from "react";
import ActionLink from "components/ActionLink";
import Page from "components/Page";
import PageBody from "components/PageBody";
import Reporting from "containers/Reporting/Reporting";
import { useAccountReportData } from "containers/hooks";
import { useOnline } from "common/hooks";
import { useQueryString } from "common/effects";
import { useFarmTagsModal } from "containers/FarmTags/FarmTagsModal";
import { useRefData } from "common/hooks";

const PublicationReporting = () => {
    const queryString = useQueryString();
    const year = queryString.get("year") || new Date().getFullYear();
    const statusQs = queryString.get("status");
    const Default_Rule = { type: "Year", condition: "Equals", values: [String(year)] };
    const { publicationStatuses } = useRefData();
    const statusRule = statusQs && publicationStatuses.find((s) => s.value === statusQs) && { type: "Status", condition: "Equals", values: [statusQs] };
    const initRules = [{ ...Default_Rule }];
    if (statusRule) {
        initRules.push(statusRule);
    }

    const [filter, setFilter] = useState({ filterGroups: [{ rules: initRules, operator: "And" }], operator: "Or" });
    const data = useAccountReportData(filter);
    const { farms } = data;
    const online = useOnline();
    const [farmTagsModal, openFarmTagsModal] = useFarmTagsModal(farms);

    return (
        <>
            <Page>
                <section className="PageHead">
                    <div className="Container">
                        <div className="FarmTitle">
                            <div className="FarmTitle-heading FarmTitle-heading--lg">
                                <span id="page-head-title" className="FarmTitle-name">
                                    Publication Reporting
                                </span>
                            </div>
                        </div>
                        {online && farms?.length > 0 && (
                            <div className="PageHead-actions u-print-none">
                                <ActionLink id="tag-farms-link" className="IconLink--tag u-link" onClick={openFarmTagsModal}>
                                    Tag farms
                                </ActionLink>
                            </div>
                        )}
                    </div>
                </section>
                <PageBody>
                    <Reporting {...data} year={year} onFilterChanged={setFilter} filter={filter} defaultRule={Default_Rule} />
                </PageBody>
            </Page>
            {farmTagsModal}
        </>
    );
};

export default PublicationReporting;
