import { useEffect, useState } from "react";
import numeral from "numeral";
import moment from "moment";
import * as utils from "common/utils";
import { useAuthContext } from "common/hooks";
import { useQuery } from "@tanstack/react-query";
import { httpClient } from "common/httpClient";

export const useFarmGroupHistory = (farmGroupId, filter) => {
    const query = useQuery({
        queryKey: ["reporting", "farmGroup", farmGroupId, "history", "filters", JSON.stringify(filter)],
        queryFn: async () => httpClient.post(`reporting/farmGroup/${farmGroupId}/trends`, { farmGroupId, filterSet: filter }),
        enabled: !!farmGroupId && !!filter,
        retry: false,
        refetchOnWindowFocus: false,
    });

    return {
        isFetching: query.isFetching,
        isLoading: query.isLoading,
        data: query.data,
        error: query.error,
    };
};

export const useAccountHistory = (accountId, filter) => {
    const query = useQuery({
        queryKey: ["reporting", "account", accountId, "history", "filters", JSON.stringify(filter)],
        queryFn: async () => httpClient.post(`reporting/account/${accountId}/trends`, { accountId, filterSet: filter }),
        enabled: !!accountId && !!filter,
        retry: false,
        refetchOnWindowFocus: false,
    });

    return {
        isFetching: query.isFetching,
        isLoading: query.isLoading,
        data: query.data,
        error: query.error,
    };
};

export const useFarmGroupPublications = (farmGroupId, filter) => {
    const query = useQuery({
        queryKey: ["reporting", "farmgroup", farmGroupId, "filters", JSON.stringify(filter)],
        queryFn: async () => httpClient.post(`reporting/farmgroup/${farmGroupId}/publications`, { farmGroupId, filterSet: filter }),
        enabled: !!farmGroupId && !!filter,
        retry: false,
        refetchOnWindowFocus: false,
    });

    return {
        isFetching: query.isFetching,
        isLoading: query.isLoading,
        data: query.data,
        error: query.error,
    };
};

export function useAccountReportContent(accountId, farmIds = [], filter) {
    const query = useQuery({
        queryKey: ["reporting", "account", accountId, "farmIds", farmIds.join("|"), "filters", JSON.stringify(filter)],
        queryFn: async () => httpClient.post(`reporting/account/${accountId}/content`, { accountId, filterSet: filter, farmIds }),
        enabled: !!accountId && !!filter,
        retry: false,
        refetchOnWindowFocus: false,
    });

    return {
        isFetching: query.isFetching,
        isLoading: query.isLoading,
        data: query.data,
        error: query.error,
    };
}

export function useAccountReportPublications(accountId, filter) {
    const query = useQuery({
        queryKey: ["reporting", "account", accountId, "publications", "filters", JSON.stringify(filter)],
        queryFn: async () => httpClient.post(`reporting/account/${accountId}/publications`, { accountId, filterSet: filter }),
        enabled: !!accountId && !!filter,
        retry: false,
        refetchOnWindowFocus: false,
    });

    return {
        isFetching: query.isFetching,
        isLoading: query.isLoading,
        data: query.data,
        error: query.error,
    };
}

export function useMyPublicationsSummary() {
    const query = useQuery({
        queryKey: ["dashboard", "my-publications-summary"],
        queryFn: async () => httpClient.get(`publications/summary`),
        retry: false,
        refetchOnWindowFocus: false,
    });

    return {
        isFetching: query.isFetching,
        isLoading: query.isLoading,
        data: query.data,
        error: query.error,
    };
}

export function useFarmTags() {
    const query = useQuery({
        queryKey: ["reporting", "farmTags"],
        queryFn: async () => httpClient.get(`account/farmTags`),
        retry: false,
        refetchOnWindowFocus: false,
    });

    return {
        isFetching: query.isFetching,
        isLoading: query.isLoading,
        data: query.data,
        error: query.error,
    };
}

export function useNationalReportingData(filter, criteria) {
    const query = useQuery({
        queryKey: ["reporting", "national", "content", JSON.stringify(filter), JSON.stringify(criteria)],
        queryFn: async () => httpClient.post(`reporting/national/content`, { filterSet: filter, ...criteria }),
        retry: false,
    });

    return {
        loading: query.isLoading,
        error: query.error?.message,
        data: query.data,
    };
}

export function useNationalBenchmarkData(criteria) {
    const query = useQuery({
        queryKey: ["reporting", "national", "benchmarks", JSON.stringify(criteria)],
        queryFn: async () => httpClient.post(`reporting/national/benchmarks`, { ...criteria }),
        retry: false,
    });

    return {
        loading: query.isLoading,
        error: query.error?.message,
        data: query.data,
    };
}

export function useBenchmarkData(analysisBeingBenchmarked) {
    const [benchmarkData, setBenchmarkData] = useState();
    const criteria = useBenchmarkSearchCriteria(analysisBeingBenchmarked);

    const { loading: nationalReportingDataLoading, data: nationalReportingData } = useNationalBenchmarkData(criteria);

    useEffect(() => {
        if (nationalReportingData) {
            if (nationalReportingData.benchmarkData && nationalReportingData.farmCount > 0) {
                setBenchmarkData(nationalReportingData.benchmarkData);
            } else {
                setBenchmarkData({});
            }
        }
    }, [nationalReportingData]);

    return {
        loading: nationalReportingDataLoading || !benchmarkData,
        data: benchmarkData,
    };
}

export function useBenchmarkChartData(benchmarkData, showDownloadOptions = false) {
    const [loading, setLoading] = useState(benchmarkData !== null);
    const [data, setData] = useState();

    const isSystemAdmin = useAuthContext()?.isSystemAdmin;

    useEffect(() => {
        let isMounted = true;

        if (benchmarkData) {
            setLoading(true);
            getBenchmarkChartData(benchmarkData, isSystemAdmin && showDownloadOptions)
                .then((result) => isMounted && setData(result))
                .catch(() => isMounted && setData({}))
                .finally(() => isMounted && setLoading(false));
        }

        return () => (isMounted = false);
    }, [benchmarkData, showDownloadOptions, isSystemAdmin]);

    return {
        loading: loading || (benchmarkData && !data),
        data,
    };
}

function useBenchmarkSearchCriteria(analysisBeingBenchmarked) {
    const [criteria, setCriteria] = useState();

    useEffect(() => {
        if (analysisBeingBenchmarked) {
            if (criteria && criteria.benchmarkAnalysisId === analysisBeingBenchmarked.id) return;

            const year = analysisBeingBenchmarked && analysisBeingBenchmarked.year ? analysisBeingBenchmarked.year : moment().year() + 1;
            const defaultRule = { type: "Year", condition: "IsSelectedYearOrLatest", values: [String(year)] };
            const filterSet = { filterGroups: [{ rules: [{ ...defaultRule }], operator: "And" }], operator: "Or" };

            setCriteria({
                year,
                benchmarkFarmId: analysisBeingBenchmarked && analysisBeingBenchmarked.farmId,
                benchmarkAnalysisId: analysisBeingBenchmarked && analysisBeingBenchmarked.id,
                rollup: "true",
                filterSet,
            });
        }
    }, [analysisBeingBenchmarked, criteria]);

    return criteria;
}

function getBenchmarkChartData(benchmarkData, showDownloadOptions) {
    return new Promise((resolve, reject) => {
        if (benchmarkData && Array.isArray(benchmarkData) && benchmarkData.length > 0 && benchmarkData[0].histogram && benchmarkData[0].histogram.length > 0) {
            const chartData = benchmarkData.map((benchmarkDataItem) => getChartDataForBenchmarkDataItem(benchmarkDataItem, showDownloadOptions));
            resolve(chartData);
        } else {
            reject();
        }
    });
}

function getChartDataForBenchmarkDataItem(benchmarkDataItem, showDownloadOptions) {
    const data = benchmarkDataItem.histogram;
    const notOutlier = benchmarkDataItem.farmValue >= 0 && data.some((d) => benchmarkDataItem.farmValue >= d.from && benchmarkDataItem.farmValue <= d.to);

    const numLabels = data.length < 20 ? 1 : utils.round(data.length / 15, 0);

    let filteredDataItem = null;
    let remainingDataItems = data;
    if (data && data.length > 1) {
        const firstFrequency = data[0].frequency;
        const sumOfAllFrequencies = data.reduce((sum, item) => (sum += item.frequency), 0);
        const firstFrequencyAsPercentageOfTotal = (firstFrequency / sumOfAllFrequencies) * 100;
        // Assumption is... if first histogram item is disproportionately large frequency. See PRD-5402 && PRD-5068.
        if (firstFrequencyAsPercentageOfTotal > 10) {
            filteredDataItem = data[0];
            remainingDataItems = data.filter((d, i) => i > 0);
        }
    }

    const chartData = data && {
        type: benchmarkDataItem.type,
        title: `${benchmarkDataItem.name} (${benchmarkDataItem.units}) ${benchmarkDataItem.farmValue !== undefined ? "- Farm value: " + numeral(benchmarkDataItem.farmValue).format("0,0") + " " + benchmarkDataItem.units : ""}`,
        labels: remainingDataItems.map((_, i) => {
            if (i % numLabels !== 0) return "";

            if (_.from === _.to) return _.from.toLocaleString();

            const value = utils.round(_.from + (_.to - _.from) / 2, 1);
            return value.toLocaleString();
        }),
        datasets: [
            {
                data: remainingDataItems.map((_) => _.frequency),
                farmIds: remainingDataItems.map((_) => _.farmIds),
                borderColor: benchmarkDataItem.colour,
                backgroundColor: benchmarkDataItem.farmValue >= 0 ? remainingDataItems.map((d) => (benchmarkDataItem.farmValue >= d.from && benchmarkDataItem.farmValue < d.to ? utils.graphColoursMuted[2] : benchmarkDataItem.colour)) : benchmarkDataItem.colour,
                fill: false,
            },
        ],
        units: benchmarkDataItem.units,
        filteredDataItem,
    };

    const chartOptions = {
        plugins: {
            legend: {
                display: false,
            },
            tooltip: {
                callbacks: {
                    title: (tooltipItem) => `${remainingDataItems[tooltipItem[0].dataIndex].from}-${remainingDataItems[tooltipItem[0].dataIndex].to} ${benchmarkDataItem.units}`,
                    label: (tooltipItem) => {
                        return ` ${tooltipItem.raw} farm${Number(tooltipItem.raw) === 1 ? "" : "s"}`;
                    },
                    afterLabel: () => {
                        return showDownloadOptions ? "Click for download options" : "";
                    },
                },
            },
        },
        scales: {
            y: {
                ticks: { beginAtZero: true, precision: 0 },
                title: {
                    display: true,
                    text: "# of farms",
                },
            },
            x: {
                title: {
                    display: true,
                    text: `${benchmarkDataItem.units}`,
                },
            },
        },
    };

    return { data: chartData, options: chartOptions, statistics: benchmarkDataItem.statistics, farmValue: benchmarkDataItem.farmValue, units: benchmarkDataItem.units, outlier: !notOutlier };
}
