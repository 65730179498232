import { v4 as uuidv4 } from "uuid";
import * as domain from "common/domain";
import * as utils from "common/utils";
import * as fertiliserUtils from "../BudgetHome/Fertiliser/_utils";
import * as animalUtils from "containers/BudgetHome/Animals/_utils";
import * as cropUtils from "../BudgetHome/Crops/_utils";

const createEnterpriseChanges = (enterpriseId) => {
    return { id: enterpriseId, animalME: 0, harvestME: 0, pastureME: 0, importedME: 0, warnings: [], errors: [], messages: [] };
};

export const winterOff = (budget, startMonth, returnMonth, percentage) => {
    const scenarioResults = { name: "Wintering off", enterprises: [], warnings: [], errors: [], messages: [`Winter off ${percentage}% animals of the milking herd from ${startMonth} to ${returnMonth}.`] };
    const dairyEnterprise = budget.enterprises.find((ed) => ed.type === "Dairy");

    if (!dairyEnterprise) {
        scenarioResults.errors.push("There is no dairy enterprise on this farm.");
        return scenarioResults;
    }

    const enterpriseChanges = createEnterpriseChanges(dairyEnterprise.id);
    scenarioResults.enterprises.push(enterpriseChanges);

    const startIndex = domain.farmYear.findIndex((m) => m === startMonth) + 1;
    const endIndex = domain.farmYear.findIndex((m) => m === returnMonth) + 1;

    const overLapsYear = endIndex < startIndex;

    const months = domain.farmYear.reduce((a, m, i) => {
        const mi = i + 1;
        if (overLapsYear) {
            if (mi >= startIndex && mi <= 12) a.push({ month: m, index: mi });
            else if (mi < endIndex && mi >= 0) a.push({ month: m, index: mi });
        } else if (mi >= startIndex && mi < endIndex) a.push({ month: m, index: mi });
        return a;
    }, []);

    let monthlyPercentages = [];
    const animalResults = budget.currentResults.animalResults[dairyEnterprise.id];
    const cropPercentagesByMonth = animalResults.sources.Crops.produced;

    // adjust animals on the farm
    if (dairyEnterprise.specificationMethod === "DairyPeakCowNumbers") {
        if (dairyEnterprise.animalsGrazingOff && dairyEnterprise.animalsGrazingOff.length > 0) scenarioResults.messages.push(`Replaced all existing grazing off rules with new scenario.`);

        const grazingOff = {
            id: uuidv4(),
            months: months.map((m) => m.month),
            percentageOfAnimals: percentage,
        };
        monthlyPercentages = months.map((m) => {
            return { month: m.month, proportion: percentage / 100 };
        });
        dairyEnterprise.animalsGrazingOff = [grazingOff];
    } else {
        const monthlyNumbers = months.reduce((map, m) => {
            map[m.month] = { animalsRemoved: 0, totalAnimals: 0 };
            const cropsEaten = cropPercentagesByMonth.find((cm) => cm.reportingYear && cm.month === m.month);
            if (cropsEaten && cropsEaten.proportion > 0.1) scenarioResults.warnings.push(`Animals are being fed >10% crops in ${m.month}. If the "Run scenario" button is clicked, an over allocation of feed error may occur because the quantity of crops fed are not adjusted when using the scenario builder. To proceed with this scenario you will need to exit the scenario builder, adjust the analysis in terms of the amount of crop being fed to stock and re-run the scenario.`);
            return map;
        }, {});

        // adjust for milking herd mobs only
        dairyEnterprise.mobs.forEach((mob) => {
            const stockCounts = animalUtils.getStockCountsForMob(mob);
            let remainingAnimals = 0;

            if (mob.dairyStockClass === "MilkingHerd") {
                const startingStockCount = stockCounts[startIndex - 1].stockCount;
                remainingAnimals = utils.round(startingStockCount * (1 - percentage / 100), 0);
                const numAnimalsToSell = startingStockCount - remainingAnimals;

                mob.events = mob.events.filter((e) => e.month === startMonth || (e.month !== returnMonth && !months.find((m) => m.month === e.month)));

                if (numAnimalsToSell > 0) {
                    // take animals off
                    mob.events.push({
                        eventType: "Sale",
                        day: 1,
                        month: startMonth,
                        numberAnimals: numAnimalsToSell,
                    });
                }

                const returningStockCount = stockCounts[endIndex - 1].stockCount;
                const numAnimalsReturned = returningStockCount - remainingAnimals;

                if (numAnimalsReturned > 0) {
                    // bring animals on
                    mob.events.push({
                        eventType: "Purchase",
                        day: 1,
                        month: returnMonth,
                        numberAnimals: numAnimalsReturned,
                    });
                } else if (numAnimalsReturned < 0) {
                    mob.events.push({
                        eventType: "Sale",
                        day: 1,
                        month: returnMonth,
                        numberAnimals: -numAnimalsReturned,
                    });
                }

                if (overLapsYear && remainingAnimals > 0) {
                    mob.events.push({
                        eventType: "Starting",
                        day: 1,
                        month: "July",
                        numberAnimals: remainingAnimals,
                    });
                }
                months.forEach((m) => {
                    const sc = stockCounts.find((c) => c.month === m.index).stockCount;
                    monthlyNumbers[m.month].totalAnimals += sc;
                    monthlyNumbers[m.month].animalsRemoved += sc - remainingAnimals;
                });
            }
        });
        monthlyPercentages = months.map((m) => {
            return { month: m.month, proportion: monthlyNumbers[m.month].animalsRemoved / monthlyNumbers[m.month].totalAnimals };
        });
    }

    // calcuate the reduction in animal me on farm using percentage reduction in animals for each month multiplied by me for that month
    // need to consider other types of mobs (so animal me may include other animals). Will need to assume all similar me requirements so lump them together. Will clean up when run model
    const meResults = budget.currentResults.animalResults[dairyEnterprise.id].monthlyResults.MetabolicRequirements.results;
    enterpriseChanges.animalME = utils.round(
        monthlyPercentages.reduce((t, m) => {
            t -= m.proportion * meResults[m.month];
            return t;
        }, 0),
        0
    );

    // clear out month settings so model can distribute
    budget.blocks
        .filter((b) => b.animals && b.animals.length > 0)
        .forEach((b) => {
            b.animals.filter((a) => a.enterpriseId === dairyEnterprise.id).forEach((a) => (a.months = []));
        });
    scenarioResults.messages.push(`Any monthly distribution of dairy animals to blocks has been removed to allow the model to redistribute.`);

    // clear out monthly allocation of supplements
    const blockInfo = getBlockInfo(budget).blocks;
    budget.feedSupplements.forEach((fs) => {
        fs.destinations
            .filter((d) => d.type !== "Storage" || d.type !== "OffFarm")
            .forEach((d) => {
                if (getEnterpriseIdsForSupplement(budget, d, blockInfo).find((e) => e.id === dairyEnterprise.id)) {
                    if (d.type === "SpecifiedBlocks") d.type = "AllBlocks";
                    d.applications = [];
                }
            });
    });

    scenarioResults.messages.push(`Any monthly distribution of supplements to dairy animals has been removed to allow model to redistribute.`);

    // we dont actually know the change in ME, becasue of the adjustments made in milk production over time
    enterpriseChanges.animalME = 0;
    enterpriseChanges.messages = ["Unable to calculate change in ME. You will need to run the scenario to see impact of change."];
    return scenarioResults;
};

export const addStructure = (budget, refData, structureType, enterprise, percentage, hours, months, storageCondition, utilisationType, category, feed, importAll, newAnimalResults) => {
    const structureName = utils.valueToText(refData.structureType, structureType);
    const enterpriseChanges = createEnterpriseChanges(enterprise.id);
    const scenarioResults = { name: "Add structure", enterprises: [enterpriseChanges], warnings: [], errors: [], messages: [`Add a new ${structureName} for ${enterprise.name} for months ${months.join(", ")}.`] };
    storageCondition = storageCondition || "Average";
    utilisationType = utilisationType || "Verygood";

    const bunker = structureType === "CoveredWinteringPad" ? { bunkerLining: "Noliningmaterial", cleaningMethod: "Flushing" } : undefined;
    const beddingPad = structureType === "UncoveredWinteringPad" || structureType === "StandoffPad" ? { padSurface: "Carbonrichsawdustbarkwoodchips" } : undefined;
    const cleaningMethod = structureType === "FeedingPad" ? "Flushing" : undefined;
    const effluentSystem = structureType === "FeedingPad" ? {} : structureType === "StandoffPad" || structureType === "UncoveredWinteringPad" ? { solidManagement: { disposalMethod: "Exported", effluentStorageMethod: "NoStorage" } } : { effluentDisposal: "Exported" };

    // create a new wintering pad
    const structureId = uuidv4();
    const structure = {
        id: structureId,
        type: structureType,
        cleaningMethod: cleaningMethod,
        enterpriseId: enterprise.id,
        isFarmGrazedOutPrior: false,
        grazingHours: hours ? true : false,
        bunker: bunker,
        pad: beddingPad,
        animals: [{ id: uuidv4(), months: months, percentageOfAnimals: percentage, time: hours }],
        effluentSystem: effluentSystem,
    };
    budget.structures = budget.structures || [];
    budget.structures.push(structure);

    // get ME required to feed animals by month on the structure. multiply total ME by month by percentage of time spent on structure
    const animalResults = newAnimalResults ? newAnimalResults[enterprise.id] : budget.currentResults.animalResults[enterprise.id];
    const totalMEBymonth = animalResults.monthlyResults.MetabolicRequirements.results;
    const moveMEBymonth = budget.currentResults.animalResults[enterprise.id].monthlyResults.MoveMetabolicRequirements && budget.currentResults.animalResults[enterprise.id].monthlyResults.MoveMetabolicRequirements.results;
    const activityMEBymonth = budget.currentResults.animalResults[enterprise.id].monthlyResults.ActivityMetabolicRequirements && budget.currentResults.animalResults[enterprise.id].monthlyResults.ActivityMetabolicRequirements.results;
    const cropPercentagesByMonth = budget.currentResults.animalResults[enterprise.id].sources.Crops.produced;

    let remainingME = 0;
    // get the me required from supplements for the animals on the pad. activity and movement is reduced because animals not moving
    const initialMonthData = Object.keys(totalMEBymonth)
        .filter((m) => months.includes(m))
        .map((m) => {
            // If hours have not been entered we will remove move and activity from animal me
            const move = (!hours && structureType !== "StandoffPad" && moveMEBymonth && moveMEBymonth[m]) || 0;
            const activity = (!hours && structureType !== "StandoffPad" && activityMEBymonth && activityMEBymonth[m]) || 0;
            const proportion = ((hours || 24) / 24) * (percentage / 100);

            // apply move and activity me to the proportion and toal
            const meToRemove = move * proportion + activity * proportion;

            // if we have new results from the model do not remove move and activity because it already has been done
            const me = newAnimalResults ? totalMEBymonth[m] * proportion : (totalMEBymonth[m] - meToRemove) * proportion;

            // animal me is lowered by removing movement and activity. We always do this because the caller is comparing to the orginal me values (not the new ones)
            enterpriseChanges.animalME -= meToRemove;
            remainingME += me;

            const cropsEaten = cropPercentagesByMonth.find((cm) => cm.reportingYear && cm.month === m);
            if (cropsEaten && cropsEaten.proportion > 0.1) scenarioResults.warnings.push(`Animals are being fed >10% crops in ${m}. If the "Run scenario" button is clicked, an over allocation of feed error may occur because the quantity of crops fed are not adjusted when using the scenario builder. To proceed with this scenario you will need to exit the scenario builder, adjust the analysis in terms of the amount of crop being fed to stock and re-run the scenario.`);

            return { month: m, meRequired: me, totalRSU: 0 };
        });

    const startingME = remainingME;
    enterpriseChanges.animalME = utils.round(enterpriseChanges.animalME, 0);
    const fodderBlockAreas = utils.getFodderBlockAreas(budget.blocks);
    const totalBlockInfo = getBlockInfo(budget);
    const blockInfo = totalBlockInfo.blocks;

    enterpriseChanges.messages.push(`New pad for ${percentage}% of animals was created.`);

    // no feed required on standoff pad
    if (structureType === "StandoffPad") return scenarioResults;

    if (category && feed && importAll) {
        // import supplements for feeding on the structure
        const importedChanges = createImportedSupplement(budget, refData, enterprise, remainingME, category, feed, structure, storageCondition, utilisationType);
        enterpriseChanges.importedME = remainingME;
        enterpriseChanges.messages = enterpriseChanges.messages.concat(importedChanges.enterprises[0].messages);
        scenarioResults.messages.push(`${importedChanges.enterprises[0].dm} kg of ${feed} imported for new structure.`);
        return scenarioResults;
    }

    // Try and find existing supplements that can be moved to the pad
    budget.feedSupplements
        .filter((s) => s.amountType !== "Cuts")
        .forEach((s) => {
            if (remainingME > 0) {
                const supplementType = getSupplementType(s, refData);
                if (supplementType) {
                    const meContentForThisSupplementType = s.category === "Userdefined" ? s.me : supplementType.meContent;
                    const totalAmount = getSupplementTotalAmount(s);
                    const newDestinations = [];
                    s.destinations.forEach((d) => {
                        if (d.type === "Structure" || d.type === "OffFarm" || d.type === "Storage") newDestinations.push(d);
                        else {
                            const enterpriseProportions = getEnterpriseIdsForSupplement(budget, d, blockInfo);
                            const oldME = getSupplementME(supplementType, d, s, totalAmount, meContentForThisSupplementType).me;

                            enterpriseProportions.forEach((p) => {
                                const newDestination = {
                                    id: uuidv4(),
                                    type: p.id === enterprise.id ? "Structure" : "Enterprise",
                                    structureId: p.id === enterprise.id ? structureId : undefined,
                                    amount: utils.round(d.amount * p.proportion, 1),
                                    enterpriseId: p.id,
                                    structureUtilisation: p.id === enterprise.id ? utilisationType : undefined,
                                    utilisation: p.id === enterprise.id ? undefined : d.utilisation,
                                    storageCondition: p.id === enterprise.id ? storageCondition : d.storageCondition,
                                };

                                newDestinations.push(newDestination);
                                const meEnterprise = getSupplementME(supplementType, newDestination, s, totalAmount, meContentForThisSupplementType).me;
                                if (p.id === enterprise.id) {
                                    if (meEnterprise > remainingME) {
                                        // have more me from supps than is required, so split off remaining for enterprise
                                        const proportionRequired = remainingME / meEnterprise;

                                        const remainingDestination = {
                                            id: uuidv4(),
                                            type: "Enterprise",
                                            amount: utils.round(newDestination.amount * (1 - proportionRequired), 1),
                                            enterpriseId: p.id,
                                            utilisation: d.utilisation,
                                            storageCondition: d.storageCondition,
                                        };
                                        newDestinations.push(remainingDestination);
                                        newDestination.amount = utils.round(newDestination.amount * proportionRequired, 1);
                                    }

                                    // can just remove total regardless as will simply go negative if excess and therefore stop
                                    remainingME -= meEnterprise;
                                }

                                // what is the change in ME for this enterprise
                                const changeInME = meEnterprise - oldME * p.proportion;

                                // now update the me changes in the record
                                if (s.type === "Purchased") enterpriseChanges.importedME += changeInME;
                                else enterpriseChanges.harvestME += changeInME;
                            });
                        }
                    });
                    s.destinations = newDestinations;
                    // balance sources with destinations to ensure no rounding errors
                    const difference = s.sources.reduce((t, s) => (t += s.amount), 0) - s.destinations.reduce((t, d) => (t += d.amount), 0);
                    if (difference !== 0) s.destinations[0].amount = utils.round(s.destinations[0].amount + difference, 1);
                }
            }
        });

    scenarioResults.messages.push("Monthly feeding of existing supplements has been reset to allow model to redistribute feed.");

    remainingME = remainingME < 0 ? 0 : remainingME;
    if (startingME > remainingME) {
        enterpriseChanges.messages.push(`${utils.round(startingME - remainingME, 0).toLocaleString()} MJ was moved from existing supplements to the new structure. This may increase ME available because of the improved utilisation on the structure.`);
    }

    // we are done
    if (remainingME <= 1000) {
        enterpriseChanges.messages.push("All feed was found using existing supplements. No need to harvest or import additional food.");
        return scenarioResults;
    }

    if (category && feed) {
        // import supplements for feeding on the structure
        const importedChanges = createImportedSupplement(budget, refData, enterprise, remainingME, category, feed, structure, storageCondition, utilisationType);
        enterpriseChanges.importedME = remainingME;
        enterpriseChanges.messages = enterpriseChanges.messages.concat(importedChanges.enterprises[0].messages);
        scenarioResults.messages.push(`${importedChanges.enterprises[0].dm} kg of ${feed} imported for new structure.`);
        return scenarioResults;
    }

    // get remaining ME from harvesting supplements
    // Harvest remaining ME
    const proportionRemaining = remainingME / startingME;
    const monthData = initialMonthData.map((m) => {
        return { month: m.month, meRequired: m.meRequired * proportionRemaining, totalRSU: 0, meRemaining: m.meRequired * proportionRemaining };
    });

    // for each block that currently grows pasture get the RSU by month for this enterprise
    const blockRsu = budget.blocks
        .filter((b) => b.type !== domain.BlockType.ProductiveFruit && b.type !== domain.BlockType.FodderCrop && b.currentResults.pastureResults && b.currentResults.pastureResults.pastureRsu && b.currentResults.pastureResults.pastureRsu[enterprise.id])
        .map((b) => {
            if (b.crops && b.crops.length > 0) {
                const year = cropUtils.rotationYear(b);
                const crops = cropUtils.sortCrops(
                    b.crops.filter((c) => c.defoliationManagement && (c.defoliationManagement === "GrazeOnly" || c.defoliationManagement === "GrazeCut")),
                    year
                );

                if (!crops || crops.length === 0) return { id: b.id, months: [], pasture: "crops" };
            }
            const area = fodderBlockAreas.pastureBlockIds.has(b.id) ? b.areaInHectares - b.areaInHectares * fodderBlockAreas.ratio : b.areaInHectares || b.rotationArea;

            const blockMonths = monthData.map((mData) => {
                const rsu = b.currentResults.pastureResults.pastureRsu[enterprise.id].find((m) => m.reportingYear && m.month === mData.month);
                mData.totalRSU += rsu ? rsu.value * area : 0;
                return { month: mData.month, rsu: rsu ? rsu.value * area : 0 };
            });
            return { id: b.id, months: blockMonths, name: b.name, dm: (b.currentResults.pastureResults.pastureGrowth * area) / 1000, pasture: b.pasture && b.pasture.pastureCategory ? b.pasture.pastureCategory : "crops" };
        });

    const hay = "Silage";
    const hayCategory = refData.supplementCategories.find((c) => c.value === hay);
    const storageLoss = 1 - hayCategory.storageLoss[storageCondition] / 100;
    const utilisation = 1 - hayCategory.structureUtilisation[utilisationType] / 100;
    let totalSupplementDM = 0;

    // create the harvests grouped by pasture type.
    const sources = blockRsu
        .filter((b) => b.months.length > 0)
        .reduce((d, b) => {
            // get ME required to be harvested from each block using the proprtion of RSU this block supports (total of the months)
            const meForBlock = b.months.reduce((total, m) => {
                const month = monthData.find((md) => md.month === m.month);

                const proportion = month && month.totalRSU ? m.rsu / month.totalRSU : 0;
                total += proportion * month.meRequired;
                month.meRemaining -= proportion * month.meRequired;
                return total;
            }, 0);
            const amountDM = utils.round(meForBlock / (hayCategory.meContent * storageLoss * utilisation) / 1000, 1);
            if (amountDM > b.dm) scenarioResults.errors.push(`There is not enough pasture available on block ${b.name} to harvest. This is likely because animals are getting their feed from crops. Either import feed for the structure or move the animals off crops before running again.`);

            if (!d[b.pasture]) d[b.pasture] = [];
            d[b.pasture].push({ blockId: b.id, amount: amountDM, month: months[0] });
            return d;
        }, {});

    monthData.forEach((m) => {
        // should be 0 but allow for some rounding so compare to 10
        if (m.meRemaining > 10) scenarioResults.errors.push(`There is not enough pasture available for month ${m.month} to harvest. This is likely because animals are getting their feed from crops. Either import feed for the structure or move the animals off crops before running again.`);
    });

    Object.keys(sources).forEach((p) => {
        // create a harvest event for each block on first month
        const totalDM = utils.round(
            sources[p].reduce((t, s) => {
                t += s.amount;
                return t;
            }, 0),
            1
        );
        totalSupplementDM += totalDM;

        if (totalDM > 0) {
            const supplement = {
                id: uuidv4(),
                type: "Harvested",
                categoryGeneralType: "HaySilage",
                category: hay,
                amountType: "Weight",
                isDryWeight: true,
                dryWeight: totalDM,
                sources: sources[p].filter((s) => s.amount > 0),
                destinations: [
                    {
                        id: uuidv4(),
                        type: "Structure",
                        structureId: structureId,
                        amount: totalDM,
                        structureUtilisation: utilisationType,
                        storageCondition: storageCondition,
                    },
                ],
            };
            budget.feedSupplements.push(supplement);

            if (p === "crops") {
                budget.blocks
                    .filter((b) => supplement.sources.find((s) => s.blockId === b.id))
                    .forEach((b) => {
                        b.crops.filter((c) => c.defoliationManagement && c.defoliationManagement === "GrazeOnly").forEach((c) => (c.defoliationManagement = "GrazeCut"));
                    });
            }
        }
    });
    if (!blockRsu || blockRsu.length === 0) {
        enterpriseChanges.messages.push(`Unable to find any pastoral blocks to harvest. You will need to import supplements or reduce your animals.`);
    } else {
        // harvested feed goes up
        enterpriseChanges.harvestME = remainingME;
        enterpriseChanges.pastureME = -remainingME / storageLoss;

        enterpriseChanges.messages.push(`${utils.round(totalSupplementDM, 1)} kg (${utils.round(remainingME, 0).toLocaleString()} MJ ME) was harvested from existing pastoral blocks and fed to the animals on the pad.`);
    }

    return scenarioResults;
};

export const adjustIrrigation = (budget, refData, irrigator, strategy) => {
    const { irrigators = [] } = budget;
    const strategies = ["Manual", "Probes", "SoilMoistureTapes"];

    budget.irrigators = irrigators.map((i, j) => {
        i.type = irrigator ? irrigator : i.type;
        i.name = "New irrigator from scenario (" + (j + 1) + ")";
        if (strategy && i.type !== "BorderDyke" && i.type !== "Flood") {
            i.applications = i.applications.map((a) => {
                if (a.averageFrostTempC) return a;
                else
                    return {
                        schedule: !strategies.includes(a.schedule) ? "Probes" : a.schedule,
                        soilMoistureUsage: strategy,
                        systemDefinition: i.type === "Travelling" ? (a.systemDefinition && a.systemDefinition !== "Default" && a.systemDefinition !== "UserDefined" ? a.systemDefinition : "DefaultSingleShift") : "Default",
                        id: a.id,
                        months: a.months,
                        blockIds: a.blockIds,
                    };
            });
        }
        return i;
    });

    const messages = [];
    if (irrigator) messages.push(`All irrigators replaced with ${utils.valueToText(refData.irrigatorTypes, irrigator)}`);
    if (strategy) messages.push(`All applications use ${utils.valueToText(refData.irrigationSoilMoistureUsage, strategy)}`);

    return { name: "Change irrigation systems", warnings: [], messages: messages };
};

export const adjustFertiliser = (budget, enterpriseME, refData, fertiliserAdjustmentPercentage, responseRate, adjustmentType, months) => {
    const { fertiliser = [], feedSupplements = [], currentResults = {}, enterprises = [] } = budget;
    var nFertiliserAdjustmentProportion = fertiliserAdjustmentPercentage / 100;

    const scenarioResults = { name: "Change N fertiliser", enterprises: [], warnings: [], errors: [], messages: [] };

    const fertResponse = responseRate || 7;

    // returned in name value results
    let totalPastureChange = 0;
    let harvestChangeDM = 0;
    let totalHarvestedDM = 0;
    let totalNApplied = 0;
    let totalNToBeAdjusted = 0;

    // the proprtion of pasture lost due to change in fertiliser. initialise to 0.
    const totalBlockInfo = getBlockInfo(budget);
    const blockInfo = totalBlockInfo.blocks;
    const totalDMGrown = totalBlockInfo.totalDMGrown;

    var enterpriseMap = enterprises.reduce((m, e) => {
        const me = enterpriseME.find((eme) => eme.id === e.id);
        m[e.id] = { totalME: me.totalME, pastureME: me.pastureME, harvestME: me.harvestME, changeInRSU: 0, harvestFed: 0, harvestChange: 0 };
        return m;
    }, {});

    const cropFertilisers = [];

    // get change in pasture for each block that has N fertiliser. Also adjust fertiliser amount at same time. Not doing composts as it doesnt allow kg/ha
    fertiliser
        .filter((f) => !f.isLastPreviousLime && (!f.organicType || f.organicType !== "CompostMulches"))
        .forEach((f) => {
            // we wont adjust crop applications for now, so might need to separate them out from pasture applications
            const cropApplications = [];
            let hasPastureBlocks = false;

            const { applications = [] } = f;
            const dmFraction = f.dmContent > 0 ? f.dmContent / 100 : 1;

            const factoryEffluent = f.dairyFactoryEffluent && refData.factoryEffluent.find((r) => r.value === f.dairyFactoryEffluent && r.value !== "Userdefined");
            const { nutrients = {} } = factoryEffluent || f;

            const nutrientKeys = Object.keys(nutrients);
            const skipFertiliser = nutrientKeys.length > 0 && (!nutrients["N"] || (adjustmentType === "nOnlyFertilisers" && nutrientKeys.filter((k) => k !== "N" && nutrients[k] > 0).length > 0));

            if (!skipFertiliser) {
                applications.forEach((a) => {
                    var applicationMonths = months ? a.months.filter((m) => months[m.month]) : a.months;

                    // start by getting the amount of N applied
                    let nAppliedPerHaPerMonth = 0;
                    if (a.amount > 0) {
                        const amountAppliedPerHa = 1000 * fertiliserUtils.calcTonnesPerHaMonth(a.amount, a.unit, budget.blocks, a.blockIds);
                        nAppliedPerHaPerMonth = nutrients["N"] ? (dmFraction * nutrients["N"] * amountAppliedPerHa) / 100 : 0;

                        if (nAppliedPerHaPerMonth && applicationMonths.length > 0) {
                            // convert to kgha because may be splitting crop blocks out later
                            a.unit = "kgha";
                            a.amount = utils.round(amountAppliedPerHa, 0);
                        }
                    } else {
                        // soluble or organic so amount is in nutrients
                        const appNutrientKeys = Object.keys(a.nutrients);
                        const skipApplication = appNutrientKeys.length > 0 && (!a.nutrients["N"] || (adjustmentType === "nOnlyFertilisers" && appNutrientKeys.filter((k) => k !== "N" && a.nutrients[k] > 0).length > 0));

                        if (!skipApplication) {
                            nAppliedPerHaPerMonth = a.nutrients["N"] ? fertiliserUtils.calcTonnesPerHaMonth(a.nutrients["N"], a.unit, budget.blocks, a.blockIds) * 1000 : 0;
                            if (nAppliedPerHaPerMonth && applicationMonths.length > 0) {
                                Object.keys(a.nutrients).forEach((n) => {
                                    a.nutrients[n] = a.nutrients[n] ? utils.round(fertiliserUtils.calcTonnesPerHaMonth(a.nutrients[n], a.unit, budget.blocks, a.blockIds) * 1000, 1) : 0;
                                });
                                a.unit = "kgha";
                            }
                        }
                    }

                    if (nAppliedPerHaPerMonth && applicationMonths.length === 0) {
                        // no months in resulting application, so leave it alone, but need to add to total N
                        totalNApplied += a.blockIds.reduce((s, bId) => {
                            const blockDetails = blockInfo.find((b) => b.id === bId && b.type === domain.BlockType.ProductivePasture);
                            if (blockDetails) {
                                s += nAppliedPerHaPerMonth * blockDetails.area * a.months.length;
                            }
                            return s;
                        }, 0);
                    } else {
                        const originalNumMonths = a.months.length;
                        if (a.months.length !== applicationMonths.length) {
                            // we have to split this application up because some months remain untouched
                            const untouchedApplication = months && utils.clone(a);
                            if (untouchedApplication) {
                                untouchedApplication.months = untouchedApplication.months.filter((m) => !months[m.month]);
                                cropApplications.push(untouchedApplication);
                            }
                        } else a.months = applicationMonths;

                        if (nAppliedPerHaPerMonth) {
                            // nitrogen was applied
                            let appHasPastureBlocks = false;
                            let cropBlockIds = [];

                            // for each block applied to
                            a.blockIds.forEach((blockId) => {
                                const blockDetails = blockInfo.find((b) => b.id === blockId && b.type === domain.BlockType.ProductivePasture);
                                if (blockDetails) {
                                    if (a.months.length > 0) {
                                        // is a pasture block and is on the months selected by the user to adjust
                                        blockDetails.nAppliedPerHa += nAppliedPerHaPerMonth * a.months.length;
                                        totalNToBeAdjusted += nAppliedPerHaPerMonth * blockDetails.area * a.months.length;
                                        appHasPastureBlocks = true;
                                        hasPastureBlocks = true;
                                    }
                                    // want to total to include those months not selected by the user
                                    totalNApplied += nAppliedPerHaPerMonth * blockDetails.area * originalNumMonths;
                                } else cropBlockIds.push(blockId);
                            });

                            if (appHasPastureBlocks) {
                                if (cropBlockIds.length > 0) {
                                    // there are crop blocks so create a new applications
                                    a.blockIds = a.blockIds.filter((i) => !cropBlockIds.includes(i));
                                    const cropsApplication = utils.clone(a);
                                    cropsApplication.id = uuidv4();
                                    cropsApplication.blockIds = cropBlockIds;
                                    cropApplications.push(cropsApplication);
                                }

                                if (a.amount > 0) {
                                    if (!adjustmentType || adjustmentType === "applications" || adjustmentType === "nOnlyFertilisers") {
                                        a.amount += a.amount * nFertiliserAdjustmentProportion;
                                        a.amount = utils.round(a.amount, 0);
                                    }
                                } else {
                                    a.nutrients["N"] += a.nutrients["N"] * nFertiliserAdjustmentProportion;
                                    a.nutrients["N"] = utils.round(a.nutrients["N"], 0);
                                }
                            }
                        }
                    }
                });
                if (hasPastureBlocks) {
                    if (adjustmentType === "nConcentration" && f.nutrients && f.nutrients["N"]) {
                        if (cropApplications && cropApplications.length > 0) {
                            const cropFert = utils.clone(f);
                            cropFert.id = uuidv4();
                            cropFert.applications = cropApplications.filter((a) => a.months.length > 0 && (a.amount > 0 || (a.nutrients && Object.keys(a.nutrients).reduce((sum, n) => (sum += a.nutrients[n]), 0) > 0)));
                            cropFertilisers.push(cropFert);
                        }
                        f.nutrients["N"] += f.nutrients["N"] * nFertiliserAdjustmentProportion;
                        f.nutrients["N"] = utils.round(f.nutrients["N"], 1);
                        if (f.nutrients["N"] > 50) scenarioResults.warnings.push(`Nitrogen concentration on fertiliser ${f.productName} is greater than 50%`);

                        f.productName = f.productName.endsWith("N adjusted") ? f.productName : f.productName + " - N adjusted";
                        f.productId = 0;
                    } else f.applications = f.applications.concat(cropApplications);

                    f.applications = f.applications.filter((a) => a.months.length > 0 && (a.amount > 0 || (a.nutrients && Object.keys(a.nutrients).reduce((sum, n) => (sum += a.nutrients[n]), 0) > 0)));
                }
            }
        });

    if (adjustmentType === "nConcentration") budget.fertiliser = budget.fertiliser.concat(cropFertilisers);

    // use N response rate to determine the kgs of pasture DM per hectare that is lost/gained due to the change in fertiliser
    const pastureDMAdjustment = nFertiliserAdjustmentProportion * fertResponse;

    blockInfo
        .filter((b) => b.type === domain.BlockType.ProductivePasture)
        .forEach((b) => {
            b.pastureChangePerHa = b.nAppliedPerHa * pastureDMAdjustment;
            if (Math.abs(b.pastureChangePerHa) > b.dmPerHa * 0.75) scenarioResults.errors.push(`Greater than 25% change in pasture for block ${b.name}. Please check the feasibility of your pasture growth.`);

            b.pastureProportion = b.pastureChangePerHa / b.dmPerHa;
            totalPastureChange += b.pastureChangePerHa * b.area;
        });

    // we have the amount of pasture lost for each pastoral block. Now adjust harvested supplements by proportion of pasture growth lost. Store the change by enterprise to be used later.
    feedSupplements
        .filter((s) => s.type === "Harvested" && s.amountType !== "Cuts")
        .forEach((fs) => {
            if (!fs.dryWeight) scenarioResults.warnings.push("There is a problem getting the dry weight of the harvest.");

            let totalChange = 0;
            let total = 0;
            totalHarvestedDM += fs.dryWeight;

            fs.sources
                .filter((s) => s.blockId != null)
                .forEach((s) => {
                    const blockDetails = blockInfo.find((b) => b.id === s.blockId && b.type === domain.BlockType.ProductivePasture);
                    if (blockDetails) {
                        total += s.amount;
                        var change = s.amount * blockDetails.pastureProportion;
                        totalChange += change;
                        s.amount = utils.round(s.amount + change, fs.amountType === "Weight" ? 1 : 0);
                    }
                });
            if (total > 0) {
                fs.destinations.forEach((d) => {
                    // get the proportion of the total that this destination is
                    const dryWeight = fs.dryWeight * (d.amount / total);

                    //get the amount to change this destination amount by. Is the current amount multiplied by the proportional change of all sources
                    const dryweightChange = (dryWeight * totalChange) / total;
                    harvestChangeDM += dryweightChange;

                    // proportion the amount out to each enterprise. Need to use dry weight so consistent across supplements
                    getEnterpriseIdsForSupplement(budget, d, blockInfo).forEach((e) => {
                        enterpriseMap[e.id].harvestFed += dryWeight * e.proportion;
                        enterpriseMap[e.id].harvestChange += dryweightChange * e.proportion;
                    });
                    d.amount = utils.round(d.amount + (d.amount * totalChange) / total, fs.amountType === "Weight" ? 1 : 0);
                });
                fs.dryWeight = utils.round(fs.dryWeight + fs.dryWeight * (totalChange / total), 1);

                //clean up rounding
                const harvested = fs.sources.reduce((sum, s) => sum + s.amount, 0);
                const dest = fs.destinations.reduce((sum, d) => sum + d.amount, 0);
                fs.destinations[0].amount = utils.round(fs.destinations[0].amount + harvested - dest, fs.amountType === "Weight" ? 1 : 0);
            }
        });

    if (!totalNApplied) {
        scenarioResults.errors.push(`There is no nitrogen fertiliser that can be adjusted.`);
        return scenarioResults;
    }

    const nAppliedChange = utils.round(totalNToBeAdjusted * nFertiliserAdjustmentProportion, 0);
    const pastureChange = utils.round(totalPastureChange / 1000, 2);
    const harvestChange = utils.round(harvestChangeDM, 2);

    scenarioResults.messages.push(`${utils.round(totalNApplied, 0).toLocaleString()} kg of N was applied as fertiliser. This has ${nAppliedChange > 0 ? "increased" : "decreased"} by ${Math.abs(nAppliedChange).toLocaleString()} kg.`);
    if (pastureChange) scenarioResults.messages.push(`${utils.round(totalDMGrown / 1000, 2)} tonnes of DM was grown as pasture. This has ${pastureChange > 0 ? "increased" : "decreased"} by ${Math.abs(pastureChange)} tonnes.`);
    if (harvestChange) scenarioResults.messages.push(`${utils.round(totalHarvestedDM, 2)} tonnes of DM was harvested. This has ${harvestChange > 0 ? "increased" : "decreased"} by ${Math.abs(harvestChange)} tonnes.`);

    // calculate the change in RSU for each enterprise

    blockInfo
        .filter((b) => b.enterprisesOnBlock && b.type === domain.BlockType.ProductivePasture)
        .forEach((b) => {
            // for each enterprise
            b.enterprisesOnBlock.forEach((e) => {
                // each block adjustment is applied to the total RSU per ha for that block and summed
                enterpriseMap[e.enterpriseId].changeInRSU += e.rsu * b.pastureProportion;
            });
        });

    scenarioResults.enterprises = enterprises.map((e) => {
        const changes = { id: e.id, animalME: 0, harvestME: 0, pastureME: 0, importedME: 0, warnings: [], messages: [] };

        // proportion of RSU change
        const proportionPasture = enterpriseMap[e.id].changeInRSU / currentResults.animalResults[e.id].pastureEaten;
        const proportionHarvest = enterpriseMap[e.id].harvestFed > 0 ? enterpriseMap[e.id].harvestChange / enterpriseMap[e.id].harvestFed : 0;
        const changeinMEPasture = proportionPasture * enterpriseMap[e.id].pastureME;
        const changeinMEHarvest = proportionHarvest * enterpriseMap[e.id].harvestME;

        changes.pastureME = utils.round(changeinMEPasture, 0);

        changes.harvestME = utils.round(changeinMEHarvest, 0);

        return changes;
    });

    return scenarioResults;
};

// create an imported supplement of the type selected for the me required.
export const createImportedSupplement = (budget, refData, enterprise, me, category, feed, structure, storageCondition, utilisationType) => {
    const enterpriseChanges = { id: enterprise.id, animalME: 0, harvestME: 0, pastureME: 0, importedME: 0, warnings: [], messages: [] };

    const scenarioResults = { name: "Import new supplements", enterprises: [enterpriseChanges], messages: [] };

    if (!category || !feed) {
        category = "Silage";
        feed = "Pasture good quality silage";
    }
    const supplementCategory = refData.supplementCategories.find((c) => c.value === category);
    const supplementType = supplementCategory && supplementCategory.children.find((c) => c.value === feed);

    if (supplementCategory && supplementType && me) {
        storageCondition = storageCondition && (storageCondition !== "Nostorage" || !structure) ? storageCondition : "Average";
        utilisationType = utilisationType || (structure ? "Poor" : "Average");

        const importedSupplement = {
            id: uuidv4(),
            type: "Purchased",
            category: supplementCategory && supplementCategory.value,
            name: supplementType && supplementType.value,
            amountType: "Weight",
            isDryWeight: true,
            sources: [{ amount: 0 }],
            destinations: [],
        };

        const utilisation = structure ? 1 - supplementType.structureUtilisation[utilisationType] / 100 : 1 - supplementType.paddockUtilisation[utilisationType] / 100;
        const loss = storageCondition === "Nostorage" ? 1 : 1 - supplementType.storageLoss[storageCondition] / 100;
        enterpriseChanges.importedME = me;
        enterpriseChanges.dm = utils.round(me / (supplementType.meContent * utilisation * loss) / 1000, 1);

        importedSupplement.destinations.push({
            id: uuidv4(),
            type: structure ? "Structure" : "Enterprise",
            enterpriseId: enterprise.id,
            structureId: structure ? structure.id : undefined,
            storageCondition: storageCondition,
            structureUtilisation: structure ? utilisationType : undefined,
            utilisation: !structure ? utilisationType : undefined,
            amount: enterpriseChanges.dm,
        });
        importedSupplement.sources[0].amount = enterpriseChanges.dm;
        importedSupplement.dryWeight = enterpriseChanges.dm;

        budget.feedSupplements.push(importedSupplement);

        enterpriseChanges.messages.push(`Imported ${enterpriseChanges.dm} tonnes dry matter of ${supplementType.text}.`);
    }

    return scenarioResults;
};

const calculateMEForStructureSupplements = (budget, supplements, refData, blockInfo) => {
    return supplements.reduce((map, s) => {
        const supplementType = getSupplementType(s, refData);
        const meContentForThisSupplementType = s.category === "Userdefined" ? s.me : supplementType.meContent;

        s.destinations
            .filter((d) => d.structureId)
            .forEach((d) => {
                // only one source for purchased so can just use source amount
                const supplementDetails = getSupplementME(supplementType, d, s, s.sources[0].amount, meContentForThisSupplementType);
                const me = supplementDetails.me;
                getEnterpriseIdsForSupplement(budget, d, blockInfo).forEach((esupp) => {
                    if (!map[esupp.id]) map[esupp.id] = 0;
                    map[esupp.id] += esupp.proportion * me;
                });
            });
        return map;
    }, {});
};

export const removeImportedSupplements = (budget, refData, option, enterprisesME) => {
    const scenarioResults = { name: "Remove imported supplements", enterprises: [], messages: ["Removed all existing imported supplements."] };
    if (option === "removePurchased") {
        scenarioResults.name = "Remove purchased supplements";
        scenarioResults.messages[0] = "Removed all existing purchased supplements (except those fed on structures).";
    } else if (option === "removePurchasedAndStorage") {
        scenarioResults.name = "Remove purchased supplements and supplements from storage";
        scenarioResults.messages[0] = "Removed all existing purchased supplements and supplements from storage (except those fed on structures).";
    } else if (option === "removeStorage") {
        scenarioResults.name = "Remove supplements from storage";
        scenarioResults.messages[0] = "Removed all existing supplements from storage (except those fed on structures).";
    }

    const totalBlockInfo = getBlockInfo(budget);
    const blockInfo = totalBlockInfo.blocks;

    budget.feedSupplements
        .filter((s) => ((option === "removePurchased" || option === "removePurchasedAndStorage") && s.type === "Purchased") || ((option === "removeStorage" || option === "removePurchasedAndStorage") && s.type === "FromStorage"))
        .forEach((s) => {
            // will remove all imported supps except for feed on structures
            s.destinations = s.destinations.filter((d) => d.structureId);
            const previousAmount = s.sources[0].amount;
            s.sources[0].amount = s.destinations.reduce((t, d) => {
                t += d.amount;
                return t;
            }, 0);
            s.dryWeight = utils.round((s.dryWeight * s.sources[0].amount) / previousAmount, 0);
        });
    budget.feedSupplements = budget.feedSupplements.filter((s) => s.destinations.length > 0);

    // calculate ME
    const importedME =
        (option === "removePurchased" || option === "removePurchasedAndStorage") &&
        calculateMEForStructureSupplements(
            budget,
            budget.feedSupplements.filter((s) => s.type === "Purchased"),
            refData,
            blockInfo
        );
    const harvestME =
        (option === "removeStorage" || option === "removePurchasedAndStorage") &&
        calculateMEForStructureSupplements(
            budget,
            budget.feedSupplements.filter((s) => s.type === "FromStorage"),
            refData,
            blockInfo
        );

    scenarioResults.enterprises = enterprisesME.map((e) => {
        const changeInImportedME = importedME === false ? 0 : importedME[e.id] ? e.importedME - importedME[e.id] : e.importedME;
        const changeInHarvestME = harvestME === false ? 0 : harvestME[e.id] ? e.harvestME - harvestME[e.id] : e.harvestME;
        return { id: e.id, importedME: -changeInImportedME, harvestME: -changeInHarvestME, messages: [] };
    });

    return scenarioResults;
};

export const adjustImportedSupplements = (budget, refData, enterprise, me) => {
    const scenarioResults = { name: "Reduce imported supplements", enterprises: [], messages: [] };
    const totalBlockInfo = getBlockInfo(budget);
    const blockInfo = totalBlockInfo.blocks;
    const structureME = calculateMEForStructureSupplements(
        budget,
        budget.feedSupplements.filter((s) => s.type === "Purchased"),
        refData,
        blockInfo
    );
    const eStructureME = enterprise.importedME - (structureME[enterprise.id] || 0);

    const meAdjustment = me < 0 && eStructureME < Math.abs(me) ? -eStructureME : me;

    const enterpriseResults = { id: enterprise.id, animalME: 0, harvestME: 0, pastureME: 0, importedME: meAdjustment, warnings: [], messages: [], dm: 0 };
    scenarioResults.enterprises.push(enterpriseResults);

    const proportion = meAdjustment / enterprise.importedME;

    budget.feedSupplements
        .filter((s) => s.type === "Purchased")
        .forEach((s) => {
            s.destinations
                .filter((d) => d.type !== "Structure")
                .forEach((d) => {
                    const esupp = getEnterpriseIdsForSupplement(budget, d, blockInfo).find((esupp) => esupp.id === enterprise.id);
                    if (esupp) {
                        d.amount = utils.round(d.amount + esupp.proportion * proportion * d.amount, 1);
                    }
                });
            s.destinations = s.destinations.filter((d) => d.amount > 0);
            s.sources[0].amount = utils.round(
                s.destinations.reduce((t, d) => {
                    t += d.amount;
                    return t;
                }, 0),
                1
            );
        });

    budget.feedSupplements = budget.feedSupplements.filter((s) => s.sources[0].amount > 0);

    return scenarioResults;
};

const getSupplementTotalAmount = (supplement) => {
    return supplement.sources.reduce((t, s) => {
        t += s.amount;
        return t;
    }, 0);
};

const getSupplementType = (supplement, refData) => {
    // PRD-6714 - use "Silage" as a proxy for "MaizeSilage"
    const category = supplement.category === "MaizeSilage" ? "Silage" : supplement.category;
    const supplementCategory = category === "Userdefined" ? (refData.supplementGeneralTypes || []).find((gt) => gt.value === supplement.categoryGeneralType) : (refData.supplementCategories || []).find((sc) => sc.value === category);
    return (supplementCategory.children || []).find((c) => c.value === supplement.name) || supplementCategory;
};

const getSupplementME = (supplementType, destination, supplement, totalAmount, meContent) => {
    const storageCondition = destination.storageCondition || "Average";
    const utilisation = 1 - (destination.utilisation ? supplementType.paddockUtilisation[destination.utilisation] / 100 : supplementType.structureUtilisation[destination.structureUtilisation] / 100);
    const loss = supplement.type === "FromStorage" || storageCondition === "Nostorage" ? 1 : supplementType.storageLoss ? 1 - supplementType.storageLoss[storageCondition] / 100 : 1;
    const proportionOfTotal = destination.amount / totalAmount;

    return { me: 1000 * supplement.dryWeight * proportionOfTotal * meContent * utilisation * loss, utilisation: utilisation, loss: loss };
};

export const getMEByEnterprise = (budget) => {
    const animalResults = budget.currentResults.animalResults;

    return Object.keys(animalResults).map((k) => {
        const totals = Object.keys(animalResults[k].sources).reduce((m, s) => {
            m[s] = animalResults[k].sources[s].produced.filter((m) => m.reportingYear).reduce((t, m) => (t += m.value), 0);
            return m;
        }, {});

        // get total dm consumed for enterprise
        const totalDM = Object.keys(animalResults[k].monthlyResults.DryMatterConsumed.results).reduce((dm, month) => {
            dm += animalResults[k].monthlyResults.DryMatterConsumed.results[month];
            return dm;
        }, 0);

        const me = { id: k, animalME: animalResults[k].totalME, cropsME: totals["Crops"], importedME: totals["FodderSupplements"] + totals["OtherSupplements"], harvestME: totals["FarmSupplements"], dm: totalDM };
        me.pastureME = me.animalME - me.importedME - me.harvestME - me.cropsME;
        return me;
    });
};

const getBlockInfo = (budget) => {
    let totalDMGrown = 0;
    const fodderBlockAreas = utils.getFodderBlockAreas(budget.blocks);
    const blockList = budget.blocks
        .filter((b) => b.type === domain.BlockType.ProductivePasture || (b.currentResults && b.currentResults.pastureResults))
        .map((b) => {
            const area = fodderBlockAreas.pastureBlockIds.has(b.id) ? b.areaInHectares - b.areaInHectares * fodderBlockAreas.ratio : b.areaInHectares || b.rotationArea;
            const blockResults = b.currentResults && b.currentResults.pastureResults;
            const pastureGrowthPerHa = blockResults ? blockResults.pastureGrowth : 0;
            const utilisation = blockResults ? blockResults.pastureUtilisation / 100 : 0;
            const result = { id: b.id, type: b.type, name: b.name, dmPerHa: pastureGrowthPerHa, area: area, utilisation: utilisation, nAppliedPerHa: 0, totalRSU: 0, enterprisesOnBlock: [] };

            if (blockResults && blockResults.pastureRsu) {
                // for each enterprise
                result.enterprisesOnBlock = Object.keys(blockResults.pastureRsu).map((enterpriseId) => {
                    // get total pasture RSU/ha for the year and enterprise
                    const rsupha = blockResults.pastureRsu[enterpriseId].reduce((s, r) => (s += r.value), 0);
                    result.totalRSU += rsupha * area;
                    result.totalDM += rsupha * area;
                    return { enterpriseId: enterpriseId, rsu: rsupha * area };
                });
            }
            totalDMGrown += result.dmPerHa * area;

            return result;
        });
    return { totalDMGrown: totalDMGrown, blocks: blockList };
};

const getEnterpriseIdsForSupplement = (budget, destination, blocks) => {
    let totalRsu = 0;
    const enterpriseArray = budget.enterprises.map((e) => {
        return { id: e.id, rsu: 0 };
    });
    if (destination.type === "OffFarm" || destination.type === "Storage") return [];

    if (destination.type === "Enterprise") return [{ id: destination.enterpriseId, proportion: 1 }];
    else if (destination.type === "Structure") {
        const structure = budget.structures.find((s) => s.id === destination.structureId);
        return [{ id: structure.enterpriseId, proportion: 1 }];
    } else if (destination.type === "AllBlocks") {
        blocks
            .filter((b) => b.enterprisesOnBlock)
            .forEach((b) => {
                b.enterprisesOnBlock.forEach((eb) => {
                    enterpriseArray.find((e) => e.id === eb.enterpriseId).rsu += eb.rsu;
                    totalRsu += eb.rsu;
                });
            });
    } else if (destination.type === "SpecifiedBlocks") {
        destination.applications.forEach((app) => {
            const b = blocks.find((b) => b.id === app.blockId);
            b.enterprisesOnBlock.forEach((eb) => {
                enterpriseArray.find((e) => e.id === eb.enterpriseId).rsu += eb.rsu;
                totalRsu += eb.rsu;
            });
        });
    }
    enterpriseArray.forEach((e) => (e.proportion = e.rsu / totalRsu));
    return enterpriseArray.filter((e) => e.proportion && e.proportion > 0);
};

export const adjustAnimalNumbers = (budget, enterpriseId, changeProportion, me, changeProduction, refData) => {
    const messages = [];
    const enterprise = budget.enterprises.find((enterprise) => enterprise.id === enterpriseId);
    const enterpriseName = utils.valueToText(refData.enterpriseTypes, enterprise.type);
    const percentageChange = Math.abs(utils.round(changeProportion * 100, 0)) || "less than 1";
    const msgChange = changeProportion > 0 ? "increased" : "reduced";

    if (enterprise.type === "Other") {
        enterprise.otherLivestocks.forEach((o) => {
            o.number += utils.round(o.number * changeProportion, 0);
        });

        messages.push(`${enterpriseName} animal numbers ${msgChange} by ${percentageChange}%.`);
    } else if (enterprise.specificationMethod === "MonthlyStockRec") {
        enterprise.mobs &&
            enterprise.mobs.forEach((mob) => {
                mob.events &&
                    mob.events.forEach((e) => {
                        e.numberAnimals += utils.round(e.numberAnimals * changeProportion, 0);
                    });
            });
        messages.push(`${enterpriseName} animal numbers ${msgChange} by ${percentageChange}%.`);
    } else if (enterprise.numberOfRSU > 0) {
        const change = utils.round(enterprise.numberOfRSU * changeProportion, 0);
        messages.push(`${enterpriseName} RSU ${msgChange} by ${percentageChange}%. From ${enterprise.numberOfRSU} to ${enterprise.numberOfRSU + change}.`);
        enterprise.numberOfRSU += change;
    } else if (enterprise.peakCowNumbers && enterprise.peakCowNumbers.amount > 0) {
        const change = utils.round(enterprise.peakCowNumbers.amount * changeProportion, 0);
        messages.push(`Peak cow numbers ${msgChange} by ${percentageChange}%. From ${enterprise.peakCowNumbers.amount} to ${enterprise.peakCowNumbers.amount + change}.`);
        enterprise.peakCowNumbers.amount += change;
    }

    if (changeProduction) {
        if (enterprise.annualMilkFatYield_kgyr && enterprise.annualMilkFatYield_kgyr > 0) {
            const change = utils.round(enterprise.annualMilkFatYield_kgyr * changeProportion, 0);
            messages.push(`Milk fat ${msgChange} by ${percentageChange}%. From ${enterprise.annualMilkFatYield_kgyr} to ${enterprise.annualMilkFatYield_kgyr + change} kgs.`);
            enterprise.annualMilkFatYield_kgyr += change;
        }

        if (enterprise.annualMilkSolidsYield_kgyr && enterprise.annualMilkSolidsYield_kgyr > 0) {
            const change = utils.round(enterprise.annualMilkSolidsYield_kgyr * changeProportion, 0);
            messages.push(`Milk soilds ${msgChange} by ${percentageChange}%. From ${enterprise.annualMilkSolidsYield_kgyr} to ${enterprise.annualMilkSolidsYield_kgyr + change} kgs.`);
            enterprise.annualMilkSolidsYield_kgyr += change;
        }

        if (enterprise.annualMilkVolumeYield_ltyr && enterprise.annualMilkVolumeYield_ltyr > 0) {
            const change = utils.round(enterprise.annualMilkVolumeYield_ltyr * changeProportion, 0);
            messages.push(`Milk volume ${msgChange} by ${percentageChange}%. From ${enterprise.annualMilkVolumeYield_ltyr} to ${enterprise.annualMilkVolumeYield_ltyr + change} litres.`);
            enterprise.annualMilkVolumeYield_ltyr += change;
        }

        if (enterprise.woolProduction_kg && enterprise.woolProduction_kg > 0) {
            const change = utils.round(enterprise.woolProduction_kg * changeProportion, 0);
            messages.push(`Wool production ${msgChange} by ${percentageChange}%. From ${enterprise.woolProduction_kg} to ${enterprise.woolProduction_kg + change} kgs.`);
            enterprise.woolProduction_kg += change;
        }

        if (enterprise.annualAntlerYield_kg && enterprise.annualAntlerYield_kg > 0) {
            const change = utils.round(enterprise.annualAntlerYield_kg * changeProportion, 0);
            messages.push(`Antler yield ${msgChange} by ${percentageChange}%. From ${enterprise.annualAntlerYield_kg} to ${enterprise.annualAntlerYield_kg + change} kgs.`);
            enterprise.annualAntlerYield_kg += change;
        }

        if (enterprise.annualVelvetYield_kg && enterprise.annualVelvetYield_kg > 0) {
            const change = utils.round(enterprise.annualVelvetYield_kg * changeProportion, 0);
            messages.push(`Velvet yield ${msgChange} by ${percentageChange}%. From ${enterprise.annualVelvetYield_kg} to ${enterprise.annualVelvetYield_kg + change} kgs.`);
            enterprise.annualVelvetYield_kg += change;
        }
    } else if (enterprise.type === "Dairy") messages.push(`Milk produced per animal has increased by ${percentageChange}%.`);

    let adjustedSupplementsMsg = undefined;
    const enterpriseChanges = { id: enterprise.id, animalME: me, messages: messages, importedME: 0, harvestME: 0 };

    // adjust supplements fed n structures for this enterprise as numbers will change
    /*budget.feedSupplements.forEach(fs => 
        {
            let changedAmount = 0
            fs.destinations.filter(d => d.structureId && budget.structures.find(s => s.id=== d.structureId && s.enterpriseId===enterpriseId)).forEach(d =>
            {
                const change = d.amount*changeProportion
                d.amount = utils.round(d.amount+change, 1)
                changedAmount += change
            })
            if (changedAmount !== 0)
            {
                const totalAmount = fs.sources.reduce((t, s) => t += s.amount, 0)
                fs.sources.forEach(s => {
                    s.amount = utils.round(s.amount*(1+changedAmount/totalAmount), 1)
                })

                adjustedSupplementsMsg = `Adjusted supplements fed on structure as number of animals on structure has changed.`
            }
        })*/

    if (adjustedSupplementsMsg) messages.push(adjustedSupplementsMsg);

    return { name: "Adjust animals", enterprises: [enterpriseChanges] };
};
