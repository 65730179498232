import { useState, useEffect } from "react";
import { v4 as uuidv4 } from "uuid";
import { Form, Field } from "react-final-form";
import { FORM_ERROR } from "final-form";
import * as utils from "common/utils";
import * as FormUtils from "common/FormUtils";
import Modal from "components/Modal/Modal";
import ModalBody from "components/Modal/ModalBody";
import ModalFooter from "components/Modal/ModalFooter";
import ModalFooterLeft from "components/Modal/ModalFooterLeft";
import ModalFooterRight from "components/Modal/ModalFooterRight";
import Button from "components/Button/Button";
import { Grid, GridCell } from "components/Grid";
import SelectField from "components/FormFields/SelectField";
import TextField from "components/FormFields/TextField";
import RadioGroupField from "components/FormFields/RadioGroupField";
import CheckboxField from "components/FormFields/CheckboxField";
import { Panel, PanelBody } from "components/Panel";
import FileUploadField, { isXml, isJson } from "components/FormFields/FileUploadField";
import { getAnalysisTypeOptions, getYearOptions, getFutureYearOptions, isYearEnd, isPredictive } from "../_utils";
import { getAnalysisOptions, getCopyOptionLabel, copyAnalysis, getFileTypeInfo, getFileTypeOptions } from "containers/FarmHome/_utils";
import ImportExternal from "./ImportExternal";
import { httpClient } from "common/httpClient";
import { useAuthContext, useNavigate, useRefData, useModal } from "common/hooks";
import { useCreateAnalysisAsync, useUpload3rdPartyJsonFileAsync, useUploadOverseerFarmFileAsync, useUploadXmlFileAsync } from "containers/hooks";

export default function CreateAnalysisModal({ farm, initialCreationType, analysisIdToCopy, close }) {
    const refData = useRefData();
    const isSystemAdmin = useAuthContext()?.isSystemAdmin;
    const createAnalysisAsync = useCreateAnalysisAsync();
    const uploadOverseerFarmFileAsync = useUploadOverseerFarmFileAsync();
    const uploadXmlFileAsync = useUploadXmlFileAsync();
    const upload3rdPartyJsonFileAsync = useUpload3rdPartyJsonFileAsync();
    const navigate = useNavigate();
    const [viewModel, setViewModel] = useState({ creationType: initialCreationType });

    // Get analysis to copy on load
    useEffect(() => {
        const fetchData = async () => {
            try {
                const analysis = await getAnalysis(analysisIdToCopy);
                setViewModel((prevState) => ({
                    ...prevState,
                    creationType: "Copy",
                    selectedAnalysisId: analysisIdToCopy,
                    copyOptions: getCopyOptions(analysis),
                    copyAll: true,
                    analysisToCopy: analysis,
                }));
            } catch (error) {
                setViewModel((prevState) => ({
                    ...prevState,
                    fetchError: error,
                }));
            }
        };
        if (analysisIdToCopy) {
            fetchData();
        }
    }, [analysisIdToCopy]);

    const getCreationTypeOptions = (farm) => {
        const options = [
            { value: "Upload", text: "Upload an analysis file" },
            { value: "Empty", text: "Create an empty analysis" },
        ];

        if (farm?.budgets?.length > 0) {
            options.unshift({ value: "Copy", text: "Copy an existing analysis" });
        }

        return options;
    };

    const handleTypeChanged = (form) => (newValue) => {
        if (newValue === "YearEnd") {
            form.change("name", null);
        } else {
            form.change("year", null);
        }
    };

    const handleYearChanged = (form, year) => (newValue) => {
        if (!isNaN(newValue) && !isNaN(year)) {
            const numYears = Number(newValue) - Number(year);
            const modYears = isNaN(numYears) ? 1 : numYears > 10 ? 10 : numYears < 1 ? 1 : numYears;
            form.change("copyOptions.ageTreesBy", modYears);
        } else {
            form.change("copyOptions.ageTreesBy", 1);
        }
    };

    const handleAnalysisChanged = (form) => async (selectedAnalysisId) => {
        try {
            const analysis = await getAnalysis(selectedAnalysisId);
            const copyOptions = getCopyOptions(analysis);
            form.batch(() => {
                form.change("copyAll", true);
                form.change("copyOptions", copyOptions);
                form.change("analysisToCopy", analysis);
            });
        } catch {}
    };

    const handleCopyAllChanged = (form, values, hasPeakCows) => (checked) => {
        for (var copyOption in values.copyOptions) {
            if (copyOption === "copyAnimals" && hasPeakCows) {
                continue;
            } else {
                form.change(`copyOptions.${copyOption}`, checked);
            }
        }
    };

    const handleCopyOptionChanged = (form, values) => (checked) => {
        if (!checked) {
            form.change("copyAll", false);
        } else {
            const allOptionsChecked = Object.values(values.copyOptions).filter((optionChecked) => !optionChecked).length === 1;
            form.change("copyAll", allOptionsChecked);
        }
    };

    const handleFileTypeChange = (form, fileTypeOptions) => (newType) => {
        const seletedFileTypeOption = fileTypeOptions.find((option) => option.value === newType);
        form.change("fileExtensions", seletedFileTypeOption.fileExtensions);
    };

    const validate = (viewModel) => {
        const validation = { copyOptions: {} };
        validation.type = FormUtils.validators.required(viewModel.type);

        if (isYearEnd(viewModel.type)) {
            validation.year = FormUtils.validators.required(viewModel.year);
        } else {
            validation.name = FormUtils.validators.required(viewModel.name);
            validation.name = validation.name || FormUtils.validators.maxLength(50)(viewModel.name);
        }

        if (viewModel.creationType === "Copy" && viewModel.copyOptions && viewModel.copyOptions.ageTrees) {
            validation.copyOptions.ageTreesBy = FormUtils.validators.required(viewModel.copyOptions.ageTreesBy);
            validation.copyOptions.ageTreesBy = validation.copyOptions.ageTreesBy || FormUtils.validators.range(0, 10)(viewModel.copyOptions.ageTreesBy);
        }

        if (viewModel.creationType === "Upload") {
            if (viewModel.fileType !== "ed") {
                validation.fileType = FormUtils.validators.required(viewModel.fileType);
                validation.file = FormUtils.validators.required(viewModel.file);
                validation.file = validation.file || (viewModel.file && viewModel.file.error);
            }
        }

        return validation;
    };

    const submit = async (viewModel) => {
        if (viewModel.creationType === "Empty") {
            const newAnalysis = {
                id: uuidv4(),
                farmId: farm.id,
                year: viewModel.year,
                name: viewModel.name,
                type: viewModel.type,
            };
            utils.setAnalysisType(farm, newAnalysis);
            const submitResult = await createAnalysisAsync(newAnalysis)
                .then(() => {
                    close();
                    navigate(`/app/farm/${newAnalysis.farmId}/analysis/${newAnalysis.id}`);
                })
                .catch((ex) => ({ [FORM_ERROR]: ex.message }));
            return submitResult;
        }

        if (viewModel.creationType === "Copy") {
            viewModel.analysisToCopy.year = viewModel.year;
            viewModel.analysisToCopy.type = viewModel.type;
            viewModel.analysisToCopy.name = viewModel.name;
            viewModel.analysisToCopy.copyOptions = viewModel.copyOptions;
            viewModel.analysisToCopy.copyType = viewModel.copyType;

            const copiedAnalysis = copyAnalysis(farm, viewModel.analysisToCopy);
            copiedAnalysis.source = viewModel.copyType === "Exact" ? "CopyToPreviousYear" : "CopyExact";
            copiedAnalysis.sourceAnalysisId = viewModel.analysisToCopy.id;
            const submitResult = await createAnalysisAsync(copiedAnalysis)
                .then(() => {
                    close();
                    navigate(`/app/farm/${copiedAnalysis.farmId}/analysis/${copiedAnalysis.id}`);
                })
                .catch((ex) => ({ [FORM_ERROR]: ex.message }));
            return submitResult;
        }

        if (viewModel.creationType === "Upload") {
            const { file } = viewModel;
            const analysis = { ...viewModel };
            utils.setAnalysisType(farm, analysis);

            if (viewModel.fileType === "ed") {
                const edAnalysis = { ...viewModel.edBudget, ...analysis };
                if (edAnalysis && edAnalysis.blocks) {
                    edAnalysis.source = "ImportedEd";
                    edAnalysis.id = uuidv4();
                    edAnalysis.farmId = farm.id;
                    edAnalysis.type = analysis.type;
                    edAnalysis.year = isYearEnd(edAnalysis.type) ? edAnalysis.year : undefined;
                    edAnalysis.name = analysis.name;
                    edAnalysis.version = 0;
                    const submitResult = await createAnalysisAsync(edAnalysis)
                        .then(() => {
                            close();
                            navigate(`/app/farm/${edAnalysis.farmId}/analysis/${edAnalysis.id}`);
                        })
                        .catch((ex) => ({ [FORM_ERROR]: ex.message }));
                    return submitResult;
                }
            } else if (isSystemAdmin && isJson(file.type) && viewModel.fileType === "fm") {
                const downloadPackage = file.json ? file.json : JSON.parse(file.content);

                let farmId = farm.id;
                if (downloadPackage.farm && !viewModel.downloadFarm) {
                    if (downloadPackage.budget && downloadPackage.budget.useFarmSoils) {
                        // need to get the soils
                        downloadPackage.budget.soils = downloadPackage.farm.soils.filter((s) => downloadPackage.budget.farmSoilBlocks.find((fsb) => fsb.soilId === s.id));
                        downloadPackage.budget.useFarmSoils = false;
                    }
                    delete downloadPackage.farm;
                    delete downloadPackage.farmFeatures;
                }

                if (downloadPackage.farm) {
                    farmId = uuidv4();
                    downloadPackage.farm.id = farmId;
                }

                if (downloadPackage.budget) {
                    downloadPackage.budget.id = uuidv4();
                    downloadPackage.budget.farmId = farmId;
                    downloadPackage.budget.source = "ImportedJSON";
                    downloadPackage.budget.type = analysis.type;
                    downloadPackage.budget.year = isYearEnd(analysis.type) || isPredictive(analysis.type) ? analysis.year : undefined;
                    downloadPackage.budget.name = analysis.name;
                    downloadPackage.budget.version = 0;

                    const submitResult = await uploadOverseerFarmFileAsync(downloadPackage)
                        .then(() => {
                            close();
                            navigate(`/app/farm/${downloadPackage.budget.farmId}/analysis/${downloadPackage.budget.id}`);
                        })
                        .catch((ex) => ({ [FORM_ERROR]: ex.message }));

                    return submitResult;
                }
            } else {
                if (isXml(file.type)) {
                    const submitResult = await uploadXmlFileAsync(file.content, farm.id, analysis.type, analysis.name || "", analysis.year || 0)
                        .then(() => {
                            close();
                        })
                        .catch((ex) => {
                            if (ex.errors) {
                                const errorList = (
                                    <>
                                        <p className="u-mt-0 u-mb-sm">There are some problems with the file. Please review or contact the provider of the file to correct the following issues:</p>
                                        <ul className="disc">
                                            {ex.errors.map((error) => (
                                                <li key={error.errorMessage}>{error.errorMessage}</li>
                                            ))}
                                        </ul>
                                    </>
                                );
                                return { [FORM_ERROR]: errorList };
                            } else {
                                return { [FORM_ERROR]: ex.message };
                            }
                        });
                    return submitResult;
                } else {
                    const submitResult = await upload3rdPartyJsonFileAsync(file.content, farm.id, analysis.type, analysis.name || "", analysis.year || 0)
                        .then(() => {
                            close();
                        })
                        .catch((ex) => {
                            if (ex.errors) {
                                const errorList = (
                                    <>
                                        <p className="u-mt-0 u-mb-sm">There are some problems with the file. Please review or contact the provider of the file to correct the following issues:</p>
                                        <ul className="disc">
                                            {ex.errors.map((error) => (
                                                <li key={error.errorMessage}>{error.errorMessage}</li>
                                            ))}
                                        </ul>
                                    </>
                                );
                                return { [FORM_ERROR]: errorList };
                            } else {
                                return { [FORM_ERROR]: ex.message };
                            }
                        });
                    return submitResult;
                }
            }
        }
    };

    const canCreatePlans = utils.canUpdateFarm(farm);
    const analysisTypeOptions = getAnalysisTypeOptions(refData);
    const yearOptions = getYearOptions(farm, refData);
    const futureYearOptions = getFutureYearOptions(refData);
    const copyTypeOptions = [
        { value: "Exact", text: "Exact copy" },
        { value: "Previous", text: "Move to previous year" },
    ];
    const analysisOptions = getAnalysisOptions(farm, [], true);
    const hasPeakCows = viewModel.analysisToCopy && (viewModel.analysisToCopy.enterprises || []).some((ent) => ent.specificationMethod === "DairyPeakCowNumbers");

    return (
        <Form initialValues={viewModel} validate={validate} onSubmit={submit}>
            {({ form, values, handleSubmit, submitting, submitError, dirtySinceLastSubmit }) => {
                const error = !dirtySinceLastSubmit && submitError;
                const copyOptionKeys = values && values.copyOptions && Object.keys(values.copyOptions).filter((k) => k);
                const copyTypeInfo = values && values.copyType === "Previous" ? "Crops, fertiliser and irrigation will be moved from reporting year to previous year for crop blocks. All other block types will copy data from reporting year to reporting year" : "An exact copy will be made of the selected analysis";
                const fileTypeOptions = getFileTypeOptions({ isSystemAdmin });
                const fileTypeInfo = getFileTypeInfo(fileTypeOptions, values.fileType);
                const isInitialLoadingAnalysisToCopy = values.creationType === "Copy" && !values.type && !values.analysisToCopy;
                const isSubsequentLoadingAnalysisToCopy = values.creationType === "Copy" && values.type && (values.analysisToCopy || {}).id !== values.selectedAnalysisId;
                const loadingAnalysisToCopy = isInitialLoadingAnalysisToCopy || isSubsequentLoadingAnalysisToCopy;
                const peakCowsWarning = hasPeakCows ? "The analysis you are copying uses the peak cow numbers method of entering dairy stock numbers. Peak cow numbers is no longer supported so you can not copy the animals from this analysis until you have converted to using stock reconciliation." : null;
                const hasCrops = values.analysisToCopy?.blocks?.some((b) => b.type === "ProductiveCrop");

                return (
                    <form onSubmit={handleSubmit}>
                        <Modal title="Create analysis" allowOffline={true} close={close} waiting={loadingAnalysisToCopy || submitting} fluid>
                            <ModalBody error={error}>
                                <Grid>
                                    <GridCell>
                                        <Field name="type" label="Analysis type" placeholder="Select a type" options={analysisTypeOptions} required component={SelectField} onChange={handleTypeChanged(form)} />
                                    </GridCell>
                                    {values.type && (
                                        <GridCell>
                                            {isYearEnd(values.type) && <Field name="year" label="Year" placeholder="Select a year" options={yearOptions} required component={SelectField} onChange={handleYearChanged(form, values.analysisToCopy && values.analysisToCopy.year)} />}
                                            {canCreatePlans && isPredictive(values.type) && <Field name="year" label="Year" placeholder="Select a year" options={futureYearOptions} component={SelectField} />}
                                            {!isYearEnd(values.type) && <Field name="name" label="Analysis name" placeholder="Enter the name of the analysis" required component={TextField} />}
                                        </GridCell>
                                    )}
                                </Grid>
                                {values.type && (
                                    <Grid title="Choose how you want to create this analysis">
                                        <GridCell>
                                            <Field name="creationType" options={getCreationTypeOptions(farm)} inline type="radio" component={RadioGroupField} />
                                        </GridCell>
                                    </Grid>
                                )}

                                {values.type && values.creationType === "Copy" && (
                                    <>
                                        <Grid>
                                            <GridCell>
                                                <Field name="selectedAnalysisId" label="Select analysis to copy" placeholder="Select an analysis to copy" options={analysisOptions} onChange={handleAnalysisChanged(form)} component={SelectField} />
                                            </GridCell>
                                        </Grid>
                                        <Grid>
                                            {hasCrops && (
                                                <GridCell>
                                                    <Field name="copyType" label="Crop block options" options={copyTypeOptions} info={copyTypeInfo} component={SelectField} />
                                                </GridCell>
                                            )}
                                        </Grid>
                                        {copyOptionKeys && copyOptionKeys.length > 0 && values.analysisToCopy && (
                                            <Panel title="What would you like to copy?" className="u-mt-md" warning={peakCowsWarning} green actions={<Field name="copyAll" label="Copy everything" onChange={handleCopyAllChanged(form, values, hasPeakCows)} vertical type="checkbox" component={CheckboxField} />}>
                                                <PanelBody>
                                                    <Grid className="u-mt-md">
                                                        {copyOptionKeys
                                                            .filter((k) => k !== "ageTreesBy")
                                                            .map((key) => {
                                                                let label = getCopyOptionLabel(key);
                                                                if (hasPeakCows && key === "copyAnimals") {
                                                                    label += " - CAN NOT BE COPIED DUE TO PEAK COW NUMBERS";
                                                                }
                                                                return (
                                                                    <GridCell key={key} className="u-mt-sm u-width1of2">
                                                                        <Field name={`copyOptions.${key}`} label={label} onChange={handleCopyOptionChanged(form, values)} disabled={key === "copyAnimals" && hasPeakCows} vertical type="checkbox" component={CheckboxField} />
                                                                        {key === "ageTrees" && values.copyOptions.ageTrees && <Field name="copyOptions.ageTreesBy" label="Number of years to age trees by" formatOnBlur format={FormUtils.formatters.formatInt} component={TextField} />}
                                                                    </GridCell>
                                                                );
                                                            })}
                                                    </Grid>
                                                </PanelBody>
                                            </Panel>
                                        )}
                                    </>
                                )}
                                {values.type && values.creationType === "Upload" && (
                                    <>
                                        <GridCell>
                                            <Field name="fileType" label="Import type" placeholder="Select import type" info={fileTypeInfo} options={fileTypeOptions} onChange={handleFileTypeChange(form, fileTypeOptions)} required component={SelectField} />
                                        </GridCell>
                                        {values.fileExtensions && <GridCell>{values.fileType === "ed" ? <ImportExternal setBudget={(b) => form.change("edBudget", b)} budget={values.edBudget} /> : <Field name="file" fileExtensions={values.fileExtensions} required component={FileUploadField} />}</GridCell>}
                                        {values.file && values.file.json && values.file.json.farm && (
                                            <GridCell>
                                                <Field name="downloadFarm" label="Download as a new farm?" component={CheckboxField} />
                                            </GridCell>
                                        )}
                                    </>
                                )}
                            </ModalBody>
                            <ModalFooter>
                                <ModalFooterLeft>
                                    <Button id="cancel" onClick={close} secondary disabled={submitting}>
                                        Cancel
                                    </Button>
                                </ModalFooterLeft>
                                <ModalFooterRight>
                                    <Button id="submit" submit primary waiting={submitting} disabled={submitting || !values.creationType || loadingAnalysisToCopy}>
                                        Create
                                    </Button>
                                </ModalFooterRight>
                            </ModalFooter>
                        </Modal>
                    </form>
                );
            }}
        </Form>
    );
}

function getCopyOptions(analysis) {
    const copyOptions = {};

    const hasSnowfall = (analysis.snowfalls || []).length > 0;
    if (hasSnowfall) copyOptions.copySnowfall = true;

    const hasSoilTests = (analysis.soilTests || []).some((st) => !st.defaultTest);
    if (hasSoilTests) copyOptions.copySoilTests = true;

    const hasDrainage = (analysis.drainageSystems || []).length > 0 || (analysis.wetlands || []).length > 0;
    if (hasDrainage) copyOptions.copyDrainage = true;

    const hasPastureFruit = (analysis.blocks || []).some((b) => (b.pasture && b.pasture.pastureCategory) || b.fruit);
    if (hasPastureFruit) copyOptions.copyPastureFruit = true;

    const hasCrops = (analysis.blocks || []).some((b) => b.type === "ProductiveCrop" && b.crops && b.crops.length > 0);
    if (hasCrops) copyOptions.copyCrops = true;

    const hasFodderCrops = (analysis.blocks || []).some((b) => b.type === "FodderCrop");
    if (hasFodderCrops) copyOptions.copyFodderCrops = true;

    const hasAnimals = (analysis.enterprises || []).length > 0;
    if (hasAnimals) {
        // Do not copy animals if peak cow numbers is used
        const hasPeakCows = analysis.enterprises.some((ent) => ent.specificationMethod === "DairyPeakCowNumbers");
        //if (!hasPeakCows) {
        copyOptions.copyAnimals = !hasPeakCows;
        //}
    }

    const hasSupplements = (analysis.feedSupplements || []).length > 0;
    if (hasSupplements) copyOptions.copySupplements = true;

    const hasFertiliser = (analysis.fertiliser || []).length > 0;
    if (hasFertiliser) copyOptions.copyFertilisers = true;

    const hasIrrigators = (analysis.irrigators || []).length > 0;
    if (hasIrrigators) copyOptions.copyIrrigators = true;

    const hasEffluent = Object.keys(analysis.effluentSystem || {}).length > 0 || Object.keys(analysis.outdoorPigEffluentSystem || {}).length > 0 || (analysis.structures || []).some((s) => Object.keys(s.effluentSystem || {}).length > 0);
    if (hasEffluent) copyOptions.copyEffluentApplications = true;

    const hasGHG = analysis.ghg && Object.keys(analysis.ghg).length > 0;
    if (hasGHG) copyOptions.copyGHG = true;

    const hasComments = (analysis.comments || []).length > 0;
    if (hasComments) copyOptions.copyComments = false;

    const hasTrees =
        (analysis.blocks || []).some((b) => b.type === "ProductiveFruit" && b.fruit) || //&& b.fruit.ageOfTrees_yrs
        (analysis.blocks || []).some((b) => b.type === "NonProductiveTreesAndScrub" && b.forests && b.forests.length > 0);
    if (hasTrees) {
        copyOptions.ageTrees = false;
        copyOptions.ageTreesBy = 1;
    }

    return copyOptions;
}

async function getAnalysis(analysisId) {
    try {
        return await httpClient.get(`v1/analysis-details/${analysisId}`);
    } catch (error) {
        if (error.status === 401 || error.status === 403) {
            throw new Error("You are not authorised to make this change.");
        } else {
            throw new Error(error.message);
        }
    }
}

export function useCreateAnalysisModal(farm) {
    const [modal, openModal] = useModal(CreateAnalysisModal);

    const openCreateAnalysisModal = () => {
        const modalProps = {
            farm,
        };
        openModal(modalProps);
    };

    return [modal, openCreateAnalysisModal];
}

export function useUploadAnalysisModal(farm) {
    const [modal, openModal] = useModal(CreateAnalysisModal);

    const openCreateAnalysisModal = (initialCreationType, analysisIdToCopy) => {
        const modalProps = {
            farm,
            initialCreationType: "Upload",
        };
        openModal(modalProps);
    };

    return [modal, openCreateAnalysisModal];
}

export function useCopyAnalysisModal(farm) {
    const [modal, openModal] = useModal(CreateAnalysisModal);

    const openCreateAnalysisModal = (analysisIdToCopy) => {
        const modalProps = {
            farm,
            initialCreationType: "Copy",
            analysisIdToCopy,
        };
        openModal(modalProps);
    };

    return [modal, openCreateAnalysisModal];
}
