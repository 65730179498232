import React, { useRef, useState, useEffect } from "react";
import FilterRow from "./FilterRow";
import { FieldArray } from "react-final-form-arrays";
import { Form } from "react-final-form";
import arrayMutators from "final-form-arrays";
import Button from "components/Button/Button";
import ActionLink from "components/ActionLink";
import { Field } from "react-final-form";

export default function FilterModal({ viewModel, applyFilters, defaultRule, modalRef, exclusions }) {
    const contentRef = useRef(null);
    const init_height = 150;
    const [height, setHeight] = useState(init_height + "px");

    useEffect(() => {
        const resizeObserver = new ResizeObserver((entries) => {
            for (let entry of entries) {
                if (entry.target === contentRef.current) {
                    setHeight(`${entry.contentRect.height + init_height}px`);
                }
            }
        });
        const currentContentRef = contentRef.current;
        if (currentContentRef) {
            resizeObserver.observe(currentContentRef);
        }
        return () => {
            if (currentContentRef) {
                resizeObserver.unobserve(currentContentRef);
            }
        };
    }, []);

    const addGroup = (fields) => {
        fields.push({ rules: [{ ...defaultRule }], operator: "Or" });
    };

    const addRule = (fields) => {
        fields.push({ ...defaultRule });
    };

    const removeFilter = (fields, index) => {
        fields.remove(index);
    };

    const resetFilters = (form) => {
        form.change("filterGroups", [{ rules: [{ ...defaultRule }], operator: "Or" }]);
        form.submit();
    };

    return (
        <Form initialValues={viewModel} onSubmit={applyFilters} mutators={{ ...arrayMutators }}>
            {({ handleSubmit, submitting, submitError, form, values }) => {
                return (
                    <form onSubmit={handleSubmit}>
                        <div className="FilterModal" style={{ height: height }} ref={modalRef}>
                            <FieldArray name="filterGroups">
                                {({ fields: filterGroups }) => (
                                    <>
                                        <div className="FilterBody" ref={contentRef}>
                                            {filterGroups.map((groupName, groupIndex) => {
                                                return (
                                                    <div key={groupIndex}>
                                                        <div className="FilterGroup">
                                                            <FieldArray name={`filterGroups[${groupIndex}]rules`}>
                                                                {({ fields: rules }) => (
                                                                    <div>
                                                                        {rules.map((ruleName, ruleIndex) => (
                                                                            <FilterRow exclusions={exclusions} form={form} values={values} allowRemove={rules.length > 1} groupIndex={groupIndex} index={ruleIndex} name={ruleName} key={ruleName} isFirst={ruleIndex === 0} deleteRow={() => removeFilter(rules, ruleIndex)} />
                                                                        ))}
                                                                        <div className="u-flex">
                                                                            {rules.length < 5 && (
                                                                                <div className="u-mr-md">
                                                                                    <ActionLink id={`group_${groupIndex}-addFilter`} className="IconLink--arrow-plus" onClick={() => addRule(rules)} secondary>
                                                                                        Add filter
                                                                                    </ActionLink>
                                                                                </div>
                                                                            )}
                                                                            <div>
                                                                                {filterGroups.length > 1 && (
                                                                                    <ActionLink id={`group_${groupIndex}-removeGroup`} className="IconLink--trash" onClick={() => filterGroups.remove(groupIndex)} secondary>
                                                                                        Remove group
                                                                                    </ActionLink>
                                                                                )}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                )}
                                                            </FieldArray>
                                                        </div>
                                                        {filterGroups.length > 1 && (
                                                            <div className="u-mb-sm">
                                                                {groupIndex === 0 ? (
                                                                    <Field
                                                                        name={`operator`}
                                                                        id="group_operator"
                                                                        render={({ input, meta }) => (
                                                                            <select {...input} className="FilterRow-and" disabled={groupIndex > 0}>
                                                                                <option value="And">And</option>
                                                                                <option value="Or">Or</option>
                                                                            </select>
                                                                        )}
                                                                    />
                                                                ) : (
                                                                    <>{groupIndex < filterGroups.length - 1 && <div className="u-pl-sm">{values?.operator === "And" ? "And:" : "Or:"}</div>}</>
                                                                )}
                                                            </div>
                                                        )}
                                                    </div>
                                                );
                                            })}
                                        </div>
                                        <div className="FilterFooter">
                                            <div className="u-mb-md">
                                                {filterGroups.length < 5 && (
                                                    <ActionLink id="add-group" className="IconLink--arrow-plus" onClick={() => addGroup(filterGroups)} secondary>
                                                        Add group
                                                    </ActionLink>
                                                )}
                                            </div>
                                            <div className="u-flex u-flexJustifyBetween">
                                                <Button type="button" onClick={() => resetFilters(form)} secondary id="remove-filters">
                                                    Remove filters
                                                </Button>
                                                <button type="submit" disabled={submitting} className="Button" id="apply-filters">
                                                    Apply filters
                                                </button>
                                            </div>
                                        </div>
                                    </>
                                )}
                            </FieldArray>
                        </div>
                    </form>
                );
            }}
        </Form>
    );
}
