import React from "react";
import { connect } from "react-redux";
import Alert from "components/Alert";

const BoolSelectPack = (props) => {
    const {
        id,
        label,
        placeholder,
        placeholderEnabled,
        required,
        noLabel,
        dataWidth,
        disabled,
        autoFocus,
        input,
        info,
        tip,
        showQuickTips,
        meta: { touched, error },
        className,
    } = props;
    const hasError = touched && error;

    const _onChange = (e) => {
        input.onChange(e.target.value === "true");

        if (props.onChange) props.onChange(e.target.value === "true");
    };

    const invalid = props?.meta?.error;

    return (
        <div className={`Field ${hasError ? "has-error" : invalid ? "is-invalid" : ""} ${className ? className : ""}`} data-width={dataWidth}>
            <label htmlFor={id || input.name} className="Select Select--block">
                {noLabel !== true && (
                    <span className={`Field-label${disabled ? " is-disabled" : ""}`}>
                        {label}
                        {required === true && <sup className="required">*</sup>}
                    </span>
                )}
                <select {...input} id={id || input.name} disabled={disabled} autoFocus={autoFocus === true} onChange={_onChange}>
                    {placeholder && (
                        <option value="" disabled={!placeholderEnabled || placeholderEnabled !== true}>
                            {placeholder}
                        </option>
                    )}
                    <option value="false">No</option>
                    <option value="true">Yes</option>
                </select>
            </label>
            {hasError && (
                <small className="Field-error" id={`${id || input.name}-error`}>
                    {error}
                </small>
            )}
            {info && <Alert type="info" text={info} />}
            {showQuickTips && tip && <Alert type="help" text={tip} />}
        </div>
    );
};

export default connect((state) => {
    return {
        showQuickTips: state.app.showQuickTips,
    };
}, null)(BoolSelectPack);
