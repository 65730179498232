import * as utils from "common/utils";
import Watermark from "components/Watermark";
import { SummaryRowForV2API } from "components/PlanningReport";
import { useAnalysisSummary, useAnalysisResults } from "containers/BudgetHome";

export default function PhysicalCharacteristics({ before, after }) {
    const { data: beforeInputs } = useAnalysisSummary(before?.details);
    const { data: afterInputs } = useAnalysisSummary(after?.details);

    const { data: beforeResults } = useAnalysisResults(before?.details);
    const { data: afterResults } = useAnalysisResults(after?.details);

    if (!beforeInputs && !afterInputs && !beforeResults?.climate && !afterResults?.climate) {
        return null;
    }

    const isComparing = !!before && !!after;

    return (
        <div className="ReportSection">
            <h1>Physical characteristics</h1>
            <div className="Table Table-Compressed">
                <Watermark />
                <table>
                    <thead>
                        <tr>
                            <th data-width="25"></th>
                            <th></th>
                            {isComparing && (
                                <th className="numeric u-before" data-width="12.5">
                                    {utils.truncateText(before.displayName, 35)}
                                </th>
                            )}
                            <th className={`numeric ${isComparing ? "u-after" : ""}`} data-width="12.5">
                                {utils.truncateText(after.displayName, 35)}
                            </th>
                            {isComparing && (
                                <th className="numeric" data-width="16.7">
                                    Change
                                </th>
                            )}
                        </tr>
                    </thead>
                    <tbody>
                        <SummaryRowForV2API before={beforeInputs?.totalFarmArea} after={afterInputs?.totalFarmArea} isComparing={isComparing} category="Land area" label="Total farm area" dp={1} />
                        <SummaryRowForV2API before={beforeInputs?.productiveBlockArea} after={afterInputs?.productiveBlockArea} isComparing={isComparing} category=" " label="Productive block area" dp={1} />
                        <SummaryRowForV2API before={beforeInputs?.totalGrazingArea} after={afterInputs?.totalGrazingArea} isComparing={isComparing} category=" " label="Total grazing area" dp={1} />
                        <SummaryRowForV2API before={beforeResults?.animals?.production?.dairy?.grazingArea} after={afterResults?.animals?.production?.dairy?.grazingArea} isComparing={isComparing} category=" " label="Dairy grazing area" dp={1} />
                        <SummaryRowForV2API before={beforeResults?.climate?.averageTemperature} after={afterResults?.climate?.averageTemperature} isComparing={isComparing} category="Climate" label="Average temperature" dp={1} />
                        <SummaryRowForV2API before={beforeResults?.climate?.annualRainfall} after={afterResults?.climate?.annualRainfall} isComparing={isComparing} category=" " label="Average rainfall" />
                        <SummaryRowForV2API before={beforeResults?.climate?.annualPotentialEvapotranspiration} after={afterResults?.climate?.annualPotentialEvapotranspiration} isComparing={isComparing} category=" " label="Average PET" />
                        <SummaryRowForV2API before={beforeResults?.soils?.averageProfileAvailableWaterAt60cm} after={afterResults?.soils?.averageProfileAvailableWaterAt60cm} isComparing={isComparing} category="Soil" label="Average PAW at 60cm" />
                    </tbody>
                </table>
            </div>
        </div>
    )
}
