import { Bar } from "react-chartjs-2";
import * as domain from "common/domain";
import * as utils from "common/utils";
import { farmResultsViewModel } from "common/viewModels";
import Alert from "components/Alert";
import Watermark from "components/Watermark";
import { FileSummary, SummaryRow } from "components/PlanningReport";
import { planningReportUtils } from "components/PlanningReport";
import { useAnalysisResults } from "containers/BudgetHome";
import { SummaryRowForV2API } from "components/PlanningReport";

export default function Drainage({ before, after, showFileComparison }) {
    const drainageChartData = getDrainageChartData(before, after);
    const afterDetails = getWetlandTotals(after.details);
    const beforeDetails = before && getWetlandTotals(before.details);
    const hasWetlandData = getHasWetlandData(afterDetails) || getHasWetlandData(beforeDetails);
    const uniqueFarmWetlandNames = Array.from(new Set([...afterDetails.farmWetlandResults.map((r) => r.name), ...(beforeDetails ? beforeDetails.farmWetlandResults.map((r) => r.name) : [])]));
    const hasDrainageData = after?.details?.averageAnnualDrainage || before?.details?.averageAnnualDrainage;

    const { data: beforeResults } = useAnalysisResults(before?.details);
    const { data: afterResults } = useAnalysisResults(after?.details);

    const isComparing = !!before && !!after;

    const useV2ApiDrainageValue = false;

    return (
        <div className="ReportPage">
            {showFileComparison && before && <FileSummary before={before} after={after} mini />}

            {!hasDrainageData && (
                <div className="u-pt-lg">
                    <Alert type="info" text="There is no drainage information for this analysis" />
                </div>
            )}

            {hasDrainageData && (
                <div className="ReportSection u-page-break-avoid">
                    <h1>Drainage</h1>
                    <Alert
                        type="info"
                        html="<p>Drainage indicates the amount of water draining below the root zome of typical crops or pastures (60cm). Drainage occurs when the amount of water (from rainfall and irrigation) exceeds the water holding capacity of the soil. When water drains it can take any excess nitrogen below this root zome and so risks leaching from the farm into the water table below.</p>
                        <p>The model uses a 30 year average climate for each block's location. The following graph shows the percentage of annual drainage that occurs each month using this average climate. This provides an indication of when the highest leaching risk is for the farm when under average conditions.</p>"
                    />

                    <div className="Table Table-Compressed">
                        <Watermark />
                        <table>
                            <thead>
                                <tr>
                                    <th data-width="25"></th>
                                    <th></th>
                                    {isComparing && (
                                        <th className="numeric u-before" data-width="12.5">
                                            {utils.truncateText(before.displayName, 35)}
                                        </th>
                                    )}
                                    <th className={`numeric ${isComparing ? "u-after" : ""}`} data-width="12.5">
                                        {utils.truncateText(after.displayName, 35)}
                                    </th>
                                    {isComparing && (
                                        <th className="numeric" data-width="16.7">
                                            Change
                                        </th>
                                    )}
                                </tr>
                            </thead>
                            <tbody>
                                {useV2ApiDrainageValue
                                    ? <SummaryRowForV2API before={beforeResults?.drainage?.averageDrainageAt60cm} after={afterResults?.drainage?.averageDrainageAt60cm} isComparing={isComparing} category="Drainage" label="Average drainage at 60cm" />
                                    : <SummaryRow beforeValue={before?.details?.averageAnnualDrainage} afterValue={after?.details?.averageAnnualDrainage} isComparing={isComparing} category="Drainage" label="Average drainage at 60cm" unit="mm" />
                                }
                                <SummaryRow beforeValue={before?.details?.averageAnnualNInDrainage} afterValue={after?.details?.averageAnnualNInDrainage} isComparing={isComparing} category=" " label="Nitrogen concentration in water drained" prefix="Drainage" unit="ppm" dp={1} />
                            </tbody>
                        </table>
                    </div>
                </div>
            )}

            {drainageChartData && (
                <div className="ReportSection">
                    <h2>When drainage at 60cm occurs</h2>
                    <div className="printable-chart-container">
                        <Bar data={drainageChartData} className="printable-chart" options={planningReportUtils.getChartOptions("Drainage (% of year)", "%")} />
                    </div>
                </div>
            )}

            {!hasWetlandData && (
                <div className={hasDrainageData ? "u-pt-lg" : undefined}>
                    <Alert type="info" text="There is no wetland information for this analysis" />
                </div>
            )}

            {hasWetlandData && (
                <div className="ReportSection u-page-break-avoid">
                    <h1>Wetlands &amp; Artificial drainage systems</h1>
                    <div className="Table Table-Compressed">
                        <Watermark />
                        <table>
                            <thead>
                                <tr>
                                    <th data-width="25"></th>
                                    <th></th>
                                    {isComparing && (
                                        <th className="numeric u-before" data-width="12.5">
                                            {utils.truncateText(before.displayName, 35)}
                                        </th>
                                    )}
                                    <th className={`numeric ${isComparing ? "u-after" : ""}`} data-width="12.5">
                                        {utils.truncateText(after.displayName, 35)}
                                    </th>
                                    {isComparing && (
                                        <th className="numeric" data-width="16.7">
                                            Change
                                        </th>
                                    )}
                                </tr>
                            </thead>
                            <tbody>
                                {uniqueFarmWetlandNames.map((n, i) => {
                                    const removeChars = n.replace("Wetlands:", "").trim();

                                    const modifiedLabel = removeChars.charAt(0).toUpperCase() + removeChars.slice(1);
                                    const actualValue = afterDetails?.farmWetlandResults.find((r) => r.name === n);
                                    const baseValue = beforeDetails?.farmWetlandResults.find((r) => r.name === n);

                                    return <SummaryRow key={n} beforeValue={baseValue?.value} afterValue={actualValue?.value} isComparing={isComparing} category={i === 0 ? "Farm wetland details" : " "} label={modifiedLabel} prefix="FarmWetlandDetails" />;
                                })}
                                <SummaryRow beforeValue={beforeDetails?.wetland} afterValue={afterDetails?.wetland} isComparing={isComparing} category="Wetlands" label="Wetland area" prefix="Wetlands" unit="ha" dp={1} />
                                <SummaryRow beforeValue={beforeDetails?.catchment} afterValue={afterDetails?.catchment} isComparing={isComparing} category=" " label="Catchment area" prefix="Wetlands" unit="ha" dp={1} />
                                <SummaryRow beforeValue={beforeDetails?.wetlandBlockArea} afterValue={afterDetails?.wetlandBlockArea} isComparing={isComparing} category=" " label="Block catchment area" prefix="Wetlands" unit="ha" dp={1} />
                                <SummaryRow beforeValue={beforeDetails?.wetlandOffFarmArea} afterValue={afterDetails?.wetlandOffFarmArea} isComparing={isComparing} category=" " label="Off farm catchment area" prefix="Wetlands" unit="ha" dp={1} />

                                <SummaryRow beforeValue={beforeDetails?.riparianCatchment} afterValue={afterDetails?.riparianCatchment} isComparing={isComparing} category="Riparian strips" label="Catchment area" prefix="RiparianStrips" unit="ha" dp={1} />
                                <SummaryRow beforeValue={beforeDetails?.riparianArea / 10000} afterValue={afterDetails?.riparianArea / 10000} isComparing={isComparing} category=" " label="Strip area" prefix="RiparianStrips" unit="ha" dp={2} />

                                <SummaryRow beforeValue={beforeDetails?.moleDrainage} afterValue={afterDetails?.moleDrainage} isComparing={isComparing} category="Mole tile drainage" label="Area" prefix="MoleTileDrainage" unit="ha" dp={1} />
                                <SummaryRow beforeValue={beforeDetails?.moleWetland} afterValue={afterDetails?.moleWetland} isComparing={isComparing} category=" " label="Artificial wetland area" prefix="MoleTileDrainage" unit="ha" dp={1} />

                                <SummaryRow beforeValue={beforeDetails?.otherDrainage} afterValue={afterDetails?.otherDrainage} isComparing={isComparing} category="Other drainage" label="Area" prefix="OtherDrainage" unit="ha" dp={1} />
                                <SummaryRow beforeValue={beforeDetails?.otherWetland} afterValue={afterDetails?.otherWetland} isComparing={isComparing} category=" " label="Artificial wetland area" prefix="OtherDrainage" unit="ha" dp={1} />
                            </tbody>
                        </table>
                    </div>
                </div>
            )}
        </div>
    )
}

function getDrainageChartData(vmBefore, vmAfter) {
    if (!vmBefore?.summary?.drainageMonths?.length > 0 && !vmAfter?.summary?.drainageMonths?.length > 0) {
        return null;
    } 

    const getDrainageDataset = (vm, label, before) => {
        const { drainageMonths = [] } = vm;
        const totalDrainage = drainageMonths.reduce((t, dm) => (t += dm.value), 0);

        const getData = () => {
            return domain.farmYear.map((m) => {
                const drainageMonth = drainageMonths.find((dm) => dm.month === m);
                return (drainageMonth && utils.round((100 * drainageMonth.value) / totalDrainage, 0)) || 0;
            });
        };

        return {
            label,
            fill: false,
            lineTension: 0.1,
            backgroundColor: before ? "#E3EEF8" : "#c6f3c6",
            borderColor: before ? "#509BC2" : "#52af6e",
            borderWidth: 1,
            data: getData(),
        };
    };
    const datasets = [];

    if (vmBefore) {
        datasets.push(getDrainageDataset(vmBefore.summary, vmBefore.name, true));
    }
    if (vmAfter) {
        datasets.push(getDrainageDataset(vmAfter.summary, vmAfter.name, false));
    }

    return {
        datasets,
        labels: domain.farmYear,
    };
}

function getHasWetlandData(details) {
    return details?.moleDrainage || details?.otherDrainage || details?.wetland || details?.catchment || details?.riparianCatchment || details?.farmWetlandResults?.length > 0;
}

function getWetlandTotals(budget) {
    const blocks = budget.blocks.filter((b) => b.type !== domain.BlockType.FodderCrop);
    let wetlandBlocks = blocks.filter((block) => block.type === domain.BlockType.NonProductiveWetland).map((b) => ({ block: b, wetland: b.wetland, unfenced: false }));

    if (budget.wetlands) {
        let unfencedBlocks = budget.wetlands.map((b) => ({ block: {}, wetland: b, unfenced: true }));
        wetlandBlocks = [...wetlandBlocks, ...unfencedBlocks];
    }

    const riparianStrips = blocks.filter((b) => b.riparianStrip).map((r) => r.riparianStrip);
    const moleDrainage = (budget.drainageSystems || []).find((d) => d.drainageMethod.toLowerCase() === "moletilesystem") || { drainage: [] };
    const otherDrainage = (budget.drainageSystems || []).find((d) => d.drainageMethod.toLowerCase() === "other") || { drainage: [] };
    const calcBlockArea = (cp, wetland) => (isNaN(cp.percentage) || isNaN(wetland.catchmentArea) ? 0 : wetland.catchmentArea * (cp.percentage / 100));

    let offfarmArea = 0;
    let blockTotalArea = 0;
    wetlandBlocks.forEach((block) => {
        const { wetland } = block;
        if (wetland && wetland.catchmentPercentages) {
            /*eslint-disable no-unused-vars*/
            let wetlandBlockArea = 0;
            for (const cp of wetland.catchmentPercentages) {
                if (!isNaN(cp.percentage)) {
                    cp.area = utils.truncateDecimal(calcBlockArea(cp, wetland), 1);
                    wetlandBlockArea += parseFloat(cp.area);
                }
            }
            blockTotalArea += wetlandBlockArea;
            offfarmArea += wetland.catchmentArea >= wetlandBlockArea ? parseFloat(utils.truncateDecimal(wetland.catchmentArea - wetlandBlockArea, 1)) : 0;
        }
    });

    const farmWetlandResults = farmResultsViewModel(budget).filter((r) => r.name.startsWith("Wetlands:")) || [];

    return {
        wetland: wetlandBlocks.reduce((t, b) => (t += b.wetland.effectiveArea), 0),
        wetlandOffFarmArea: offfarmArea,
        wetlandBlockArea: blockTotalArea,
        catchment: wetlandBlocks.reduce((t, b) => (t += b.wetland.catchmentArea), 0),
        riparianCatchment: riparianStrips.reduce((t, b) => (t += b.catchmentArea), 0),
        riparianArea: riparianStrips.reduce((t, r) => {
            const m2 = r.stripLength * r.pathLength;
            return t + m2;
        }, 0),
        moleWetland: moleDrainage.drainage.reduce((dt, d) => (dt += d.artificialWetlandArea || 0), 0),
        moleDrainage: utils.round(
            moleDrainage.drainage.reduce((drainageTotal, drainage) => {
                const block = blocks.find((b) => b.drainageDetailsId === drainage.id);
                drainageTotal += (block.areaInHectares * (drainage.propDrained || 0)) / 100;
                return drainageTotal;
            }, 0),
            1
        ),
        otherWetland: otherDrainage.drainage.reduce((dt, d) => (dt += d.artificialWetlandArea || 0), 0),
        otherDrainage: utils.round(
            otherDrainage.drainage.reduce((drainageTotal, drainage) => {
                const block = blocks.find((b) => b.drainageDetailsId === drainage.id);
                drainageTotal += (block.areaInHectares * (drainage.propDrained || 0)) / 100;
                return drainageTotal;
            }, 0),
            1
        ),
        farmWetlandResults,
    };
};