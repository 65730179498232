import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { FORM_ERROR } from "final-form";
import { Form, Field } from "react-final-form";
import { FieldArray } from "react-final-form-arrays";
import arrayMutators from "final-form-arrays";
import { useIsPaidVersionOfOverseer, useIsOverseerEd, useIsOverseerSci, useRefData } from "common/hooks";
import { httpClient } from "common/httpClient";
import * as FormUtils from "common/FormUtils";
import Modal from "components/Modal/Modal";
import ModalBody from "components/Modal/ModalBody";
import ModalFooter from "components/Modal/ModalFooter";
import ModalFooterLeft from "components/Modal/ModalFooterLeft";
import ModalFooterRight from "components/Modal/ModalFooterRight";
import Button from "components/Button/Button";
import ActionLink from "components/ActionLink";
import { Grid, GridCell } from "components/Grid";
import { CheckboxField, DateOnlyField, HiddenField, SelectField, TextField, WholeNumberField } from "components/FormFields";
import { orgUtils } from "containers/Orgs";
import { useAllOrgs } from "containers/Admin/Orgs";
import { useRefreshOrgSearchResultsIfAny } from "containers/Admin/Search";

export default function OrgModal({ org, close }) {
    const { isFetching, isLoading, data: orgData } = useOrg(org?.id);
    const saveOrgAsync = useSaveOrgAsync();
    const orgTypeOptions = useOrgTypeOptions();
    const educationProviderOptions = useEducationProviderOptions();

    const isPaidVersionOfOverseer = useIsPaidVersionOfOverseer();
    const isOverseerSci = useIsOverseerSci();
    const isOverseerEd = useIsOverseerEd();
    const { scienceFunctions, councils } = useRefData();

    const toggleAllowedScienceFunction = (form, values, toggledFunction) => () => {
        const selectedFunctions = values?.scienceSettings?.allowedFunctions || [];

        if (selectedFunctions.some((selectedFunction) => selectedFunction === toggledFunction)) {
            form.change(
                "scienceSettings.allowedFunctions",
                selectedFunctions.filter((fn) => fn !== toggledFunction)
            );
        } else {
            form.change("scienceSettings.allowedFunctions", [...selectedFunctions, toggledFunction]);
        }
    };

    const validateAsync = async (formValues) => {
        const validation = {};

        validation.type = FormUtils.validators.required(formValues.type);

        validation.name = FormUtils.validators.required(formValues.name);
        validation.name = validation.name || FormUtils.validators.minLength(1)(formValues.name);
        validation.name = validation.name || FormUtils.validators.maxLength(50)(formValues.name);

        const isNew = !formValues.id;
        if (isNew) {
            validation.user = {};

            validation.user.fullName = FormUtils.validators.required(formValues.user?.fullName);
            validation.user.fullName = validation.user.fullName || FormUtils.validators.minLength(1)(formValues.user?.fullName);
            validation.user.fullName = validation.user.fullName || FormUtils.validators.maxLength(50)(formValues.user?.fullName);

            validation.user.email = FormUtils.validators.required(formValues.user?.email);
            validation.user.email = validation.user.email || FormUtils.validators.email(formValues.user?.email);
            validation.user.email = validation.user.email || FormUtils.validators.maxLength(255)(formValues.user?.email);

            validation.user.password = FormUtils.validators.required(formValues.user?.password);
            validation.user.password = validation.user.password || FormUtils.validators.minLength(8)(formValues.user?.password);
            validation.user.password = validation.user.password || FormUtils.validators.maxLength(50)(formValues.user?.password);
        }

        if (isPaidVersionOfOverseer) {
            validation.creditLimit = FormUtils.validators.required(formValues.creditLimit);
            validation.creditLimit = FormUtils.validators.range(0, 300000)(formValues.creditLimit);
        }

        if (orgUtils.isScienceOrg(formValues)) {
            validation.scienceSettings = {};
            validation.scienceSettings.maxDatasets = FormUtils.validators.required(formValues.scienceSettings?.maxDatasets);
            validation.scienceSettings.maxModelRuns = FormUtils.validators.required(formValues.scienceSettings?.maxModelRuns);
            validation.scienceSettings.maxAnalyses = FormUtils.validators.required(formValues.scienceSettings?.maxAnalyses);
            validation.scienceSettings.maxUsers = FormUtils.validators.required(formValues.scienceSettings?.maxUsers);
            validation.scienceSettings.maxDatasets = validation.scienceSettings.maxDatasets || FormUtils.validators.range(1, 100)(formValues.scienceSettings?.maxDatasets);
            validation.scienceSettings.maxModelRuns = validation.scienceSettings.maxModelRuns || FormUtils.validators.range(1, 20)(formValues.scienceSettings?.maxModelRuns);
            validation.scienceSettings.maxAnalyses = validation.scienceSettings.maxAnalyses || FormUtils.validators.range(1, 1000)(formValues.scienceSettings?.maxAnalyses);
            validation.scienceSettings.maxUsers = validation.scienceSettings.maxUsers || FormUtils.validators.range(1, 50)(formValues.scienceSettings?.maxUsers);
        }

        if (orgUtils.isStudentOrg(formValues)) {
            validation.parentAccountId = FormUtils.validators.required(formValues.parentAccountId);
            validation.parentAccountRef = FormUtils.validators.required(formValues.parentAccountRef);
            validation.parentAccountRef = validation.parentAccountRef || FormUtils.validators.minLength(1)(formValues.parentAccountRef);
            validation.parentAccountRef = validation.parentAccountRef || FormUtils.validators.maxLength(50)(formValues.parentAccountRef);
        }

        return validation;
    };

    const submitAsync = async (formValues) => {
        try {
            if (formValues.type !== "Council" || formValues.council === "none") {
                delete formValues.council;
            }
            await saveOrgAsync(formValues);
            close();
        } catch (ex) {
            return {
                [FORM_ERROR]: ex.message,
            };
        }
    };

    return (
        <Form initialValues={orgData} mutators={{ ...arrayMutators }} validate={validateAsync} onSubmit={submitAsync}>
            {({ form, values, handleSubmit, submitting, submitError }) => {
                const isNew = !values.id;

                return (
                    <form onSubmit={handleSubmit}>
                        <Modal title="Org" close={close} waiting={isFetching} submitting={submitting} skinny fluid>
                            <ModalBody loading={isLoading && !isNew} error={submitError}>
                                <Field name="id" component={HiddenField} />
                                <Grid>
                                    <GridCell className="u-width1of2">
                                        <Field name="type" label="Org type" placeholder="Select the org's type" options={orgTypeOptions} required component={SelectField} />
                                    </GridCell>
                                    <GridCell className="u-width1of2">{values.type === "Council" && <Field name="council" label="Reporting council" options={[{ value: "none", text: "None" }, ...councils]} component={SelectField} />}</GridCell>
                                    <GridCell>
                                        <Field name="name" label="Org name" placeholder="Enter the org's name" required component={TextField} />
                                        {isPaidVersionOfOverseer && <Field name="creditLimit" label="Postpaid spending limit" component={WholeNumberField} />}
                                    </GridCell>
                                </Grid>
                                {isNew && (
                                    <Grid title="Org's admin user settings">
                                        <GridCell className="u-width1of2">
                                            <Field name="user.fullName" label="Admin user's full name" placeholder="Enter the full name of the org's admin user" required component={TextField} />
                                            <Field name="user.password" label="Admin user's password" placeholder="Enter the password for the org's admin user" required type="password" component={TextField} />
                                        </GridCell>
                                        <GridCell className="u-width1of2">
                                            <Field name="user.email" label="Admin user's email" placeholder="Enter the email of the org's admin user" required component={TextField} />
                                        </GridCell>
                                    </Grid>
                                )}
                                {orgUtils.isNotEducationOrg(values) && (
                                    <>
                                        <Grid title="Org settings">
                                            <GridCell className="u-width1of2">
                                                <Field name="canBePublishedTo" label="Can be published to" component={CheckboxField} />
                                                <Field name="isDairyCompany" label="Is dairy company" component={CheckboxField} />
                                                <Field name="canCreateFertiliserRecommendations" label="Can create fertiliser recommendations" component={CheckboxField} />
                                                <Field name="recordSessions" label="Record user sessions for all the org's users" component={CheckboxField} />
                                            </GridCell>
                                            <GridCell className="u-width1of2">
                                                <Field name="isBillingAgent" label="Is billing agent" component={CheckboxField} />
                                                <Field name="canPublishToMPIWholeFarmDataProject" label="Can publish to MPI Whole Farm Data Project" component={CheckboxField} />
                                                <Field name="accessToEngineAPI" label="Can execute XML engine API" component={CheckboxField} />
                                            </GridCell>
                                        </Grid>
                                    </>
                                )}
                                {isOverseerSci && orgUtils.isScienceOrg(values) && (
                                    <>
                                        <Grid title="Science org settings">
                                            <GridCell className="u-width1of2">
                                                <Field name="scienceSettings.maxDatasets" label="Max datasets" component={WholeNumberField} />
                                                <Field name="scienceSettings.maxAnalyses" label="Max analyses" component={WholeNumberField} />
                                            </GridCell>
                                            <GridCell className="u-width1of2">
                                                <Field name="scienceSettings.maxModelRuns" label="Max model runs" component={WholeNumberField} />
                                                <Field name="scienceSettings.maxUsers" label="Max users" component={WholeNumberField} />
                                            </GridCell>
                                        </Grid>

                                        {scienceFunctions && (
                                            <Grid title="Science functions">
                                                <GridCell>
                                                    <ul className="BlockList u-mt-sm">
                                                        <FieldArray name="scienceSettings.allowedFunctions">
                                                            {({ fields }) => {
                                                                return scienceFunctions.map((scienceFunction) => {
                                                                    const isSelected = fields.value && fields.value.includes(scienceFunction.value);
                                                                    const label = scienceFunction.text;
                                                                    return (
                                                                        <li key={scienceFunction.value} id={scienceFunction.value} className={`BlockList-item ${isSelected ? "is-active" : ""}`} onClick={toggleAllowedScienceFunction(form, values, scienceFunction.value)}>
                                                                            <span id={`${scienceFunction.value.id}-name`}>{label}</span>
                                                                            {isSelected && (
                                                                                <ActionLink className="BlockList-item-close">
                                                                                    <i className="icon icon-cross" />
                                                                                </ActionLink>
                                                                            )}
                                                                        </li>
                                                                    );
                                                                });
                                                            }}
                                                        </FieldArray>
                                                    </ul>
                                                </GridCell>
                                            </Grid>
                                        )}
                                    </>
                                )}
                                {isOverseerEd && orgUtils.isStudentOrg(values) && (
                                    <>
                                        <Grid title="Student settings">
                                            <GridCell className="u-width1of2">
                                                <Field name="parentAccountId" label="Parent account" placeholder="Student's education provider" options={educationProviderOptions} component={SelectField} />
                                                <Field name="expires" label="Expiry date" component={DateOnlyField} />
                                            </GridCell>
                                            <GridCell className="u-width1of2">
                                                <Field name="parentAccountRef" label="Student group" placeholder="Student group" component={TextField} />
                                            </GridCell>
                                        </Grid>
                                    </>
                                )}
                            </ModalBody>
                            <ModalFooter>
                                <ModalFooterLeft>
                                    <Button id="cancel" onClick={close} secondary disabled={submitting}>
                                        Cancel
                                    </Button>
                                </ModalFooterLeft>
                                <ModalFooterRight>
                                    <Button id="submit" submit primary waiting={submitting}>
                                        Save
                                    </Button>
                                </ModalFooterRight>
                            </ModalFooter>
                        </Modal>
                    </form>
                );
            }}
        </Form>
    );
}

function useOrg(accountId) {
    const isExistingOrg = !!accountId;

    const query = useQuery({
        queryKey: ["orgs", accountId],
        queryFn: async () => httpClient.get(`admin/accounts/${accountId}`),
        retry: false,
        enabled: isExistingOrg,
    });

    var org = isExistingOrg ? query.data : { user: {} };

    return {
        isFetching: query.isFetching,
        isLoading: query.isLoading,
        data: org,
    };
}

function useOrgTypeOptions() {
    const isOverseerSci = useIsOverseerSci();
    const isOverseerEd = useIsOverseerEd();

    const options = [
        { value: "Standard", text: "Standard", groupLabel: "Fm", groupIndex: 0 },
        { value: "Council", text: "Council", groupLabel: "Fm", groupIndex: 0 },
        { value: "ReceivesAnonymisedDataOnly", text: "Receives anonymised data only", groupLabel: "Fm", groupIndex: 0 },
    ];

    if (isOverseerSci) {
        options.push({ value: "Science", text: "Science", groupLabel: "Sci", groupIndex: 1 });
    }

    if (isOverseerEd) {
        options.push({ value: "EducationProvider", text: "Education Provider", groupLabel: "Ed", groupIndex: 2 });
        options.push({ value: "Student", text: "Student", groupLabel: "Ed", groupIndex: 2 });
    }

    return options;
}

function useEducationProviderOptions() {
    const { orgs } = useAllOrgs();
    const isOverseerEd = useIsOverseerEd();

    if (isOverseerEd && orgs?.length > 0) {
        return orgs.filter((org) => orgUtils.isEducationProvider(org)).map((org) => ({ value: org.id, text: org.name }));
    } else {
        return [];
    }
}

function useSaveOrgAsync() {
    const queryClient = useQueryClient();
    const refreshOrgSearchResultsIfAny = useRefreshOrgSearchResultsIfAny();

    const mutation = useMutation({
        mutationFn: async (org) => {
            const isNew = !org.id;

            try {
                if (isNew) {
                    await httpClient.post("admin/accounts", org);
                } else {
                    await httpClient.put("admin/accounts", org);
                }
            } catch (error) {
                if (error.status === 401 || error.status === 403) {
                    throw new Error("You are not authorised to make this change.");
                } else if (error.status === 409) {
                    throw new Error("The organisation or the user already exists.");
                } else {
                    throw new Error(error.message);
                }
            }
        },
        // These callbacks ares called BEFORE their mutateAsync versions, even if the component is unmounted
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ["orgs"] });
            queryClient.invalidateQueries({ queryKey: ["my"] });
        },
        onSettled: () => refreshOrgSearchResultsIfAny(),
    });

    return (org) => mutation.mutateAsync(org);
}
