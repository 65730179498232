import { useCallback, useState } from "react";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { httpClient } from "common/httpClient";
import { useModal } from "common/hooks";
import { Form, Field } from "react-final-form";
import { FORM_ERROR } from "final-form";
import { Modal, ModalBody, ModalFooter, ModalFooterLeft, ModalFooterRight } from "components/Modal";
import { Button } from "components/Button";
import { Panel, PanelBody } from "components/Panel";
import { Grid, GridCell } from "components/Grid";
import { CheckboxField, RadioField, SearchField, TextField } from "components/FormFields";
import SortableTable from "components/new/SortableTable";
import SortableTableHeader from "components/new/SortableTableHeader";
import { useRefreshOrgSearchResultsIfAny } from "containers/Admin/Search";

export default function MoveUserModal({ user, close }) {
    const { isFetching: orgFetching, error: orgFetchingError, data: org } = useOrg(user.accountId);
    const [searchResults, setSearchResults] = useState([]);
    const moveUserAsync = useMoveUserAsync();

    const searchAsync = useCallback(async (searchText) => {
        try {
            const encodedSearchText = encodeURIComponent(searchText);
            const searchResults = await httpClient.get(`admin/search/accounts?searchText=${encodedSearchText}`);
            setSearchResults(searchResults);
        } catch (error) {
            if (error.status === 401 || error.status === 403) {
                throw new Error("You are not authorised to make this request.");
            } else {
                throw new Error(error.message);
            }
        }
    }, []);

    const submitAsync = async (values) => {
        try {
            const request = {
                userId: values.id,
                fromAccountId: values.accountId,
                toAccountId: values.toAccountId,
                isAdmin: values.isAdmin ? true : false,
                includeFarms: values.includeFarms ? true : false,
            };
            await moveUserAsync(request);
            close();
        } catch (error) {
            return {
                [FORM_ERROR]: error.message,
            };
        }
    }

    const viewModel = {
        ...user,
        orgName: org?.name
    };

    const onlyShowIncludeFarmsIfUserIsOnlyUser = org?.userCount === 1;

    return (
        <Form initialValues={viewModel} onSubmit={submitAsync}>
            {({ values, handleSubmit, submitting, submitError }) => {
                return (
                    <form onSubmit={handleSubmit}>
                        <Modal title="Move user to another org" close={close} waiting={orgFetching} submitting={submitting}>
                            <ModalBody loading={orgFetching} error={orgFetchingError || submitError}>
                                <Panel title="Source org" skyBlue className="u-mt-lg" notCollapsible>
                                    <PanelBody>
                                        <Grid>
                                            <GridCell className="u-lg-width1of2">
                                                <Field name="orgName" label="Account name" readonly component={TextField} />
                                            </GridCell>
                                            <GridCell className="u-lg-width1of2">
                                                <Field name="fullName" label="User to move" readonly component={TextField} />
                                            </GridCell>
                                            <GridCell className="u-lg-width1of2">
                                                <Field name="email" label="Email" readonly component={TextField} />
                                            </GridCell>
                                        </Grid>
                                        <Grid>
                                            <GridCell className="u-lg-width1of2">
                                                <Field name="isAdmin" fieldLabel="Org access rights" label="Make the user an admin of the destination org" component={CheckboxField} />
                                            </GridCell>
                                            {onlyShowIncludeFarmsIfUserIsOnlyUser && (
                                                <GridCell className="u-lg-width1of2">
                                                    <Field name="includeFarms" fieldLabel="Farm access rights" label="Transfer the user's existing farm access rights to the destination org" component={CheckboxField} />
                                                </GridCell>
                                            )}
                                        </Grid>
                                    </PanelBody>
                                </Panel>
                                <Panel title="Destination org" green notCollapsible>
                                    <PanelBody>
                                        <Grid>
                                            <GridCell className="u-lg-width2of3">
                                                <Field name="searchText" label="Account search" placeholder="Search for the destination account" onSearch={searchAsync} component={SearchField} />
                                            </GridCell>
                                        </Grid>
                                        {values.searchText && (
                                            <Grid className="u-mt-md">
                                                <GridCell>
                                                    <span className="h3">{searchResults.length}</span> account(s) found
                                                </GridCell>
                                            </Grid>
                                        )}
                                        {searchResults.length > 0 && (
                                            <Grid className="u-mt-md">
                                                <GridCell>
                                                    <SortableTable data={searchResults} defaultSortCriteria={{ fieldName: "name", fieldType: "string" }}>
                                                        {({ data, sortCriteria, handleSort }) => {
                                                            return (
                                                                <table>
                                                                    <thead>
                                                                        <tr>
                                                                            <SortableTableHeader label="Name" fieldName="name" sortCriteria={sortCriteria} handleSort={handleSort} />
                                                                            <SortableTableHeader label="Type" fieldName="type" sortCriteria={sortCriteria} handleSort={handleSort} />
                                                                            <SortableTableHeader label="Users" fieldName="members" sortCriteria={sortCriteria} handleSort={handleSort} />
                                                                            <th className="th--shrink"></th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        {data.map((acc) => {
                                                                            const isCurrent = acc.id === user.accountId;
                                                                            return (
                                                                                <tr key={acc.id} className="hover">
                                                                                    <td>
                                                                                        {acc.name} {isCurrent && <span> - (current)</span>}
                                                                                    </td>
                                                                                    <td>{acc.type}</td>
                                                                                    <td>{(acc.users || []).length}</td>
                                                                                    <td className="u-textCenter">
                                                                                        {!isCurrent &&
                                                                                            <Field name="toAccountId" value={acc.id} type="radio" component={RadioField} />
                                                                                        }
                                                                                    </td>
                                                                                </tr>
                                                                            )
                                                                        })}
                                                                    </tbody>
                                                                </table>
                                                            )
                                                        }}
                                                    </SortableTable>
                                                </GridCell>
                                            </Grid>
                                        )}
                                    </PanelBody>
                                </Panel>
                            </ModalBody>
                            <ModalFooter>
                                <ModalFooterLeft>
                                    <Button id="cancel" onClick={close} secondary disabled={submitting}>
                                        Cancel
                                    </Button>
                                </ModalFooterLeft>
                                <ModalFooterRight>
                                    <Button id="submit" submit primary waiting={submitting} disabled={!values.toAccountId}>
                                        Move
                                    </Button>
                                </ModalFooterRight>
                            </ModalFooter>
                        </Modal>
                    </form>
                )
            }}
        </Form>
    )
}

export function useMoveUserModal(user) {
    const [modal, openModal] = useModal(MoveUserModal);

    const openMoveUserModal = () => {
        const modalProps = {
            user
        };
        openModal(modalProps);
    };

    return [modal, openMoveUserModal];
}

function useOrg(orgId) {
    const query = useQuery({
        queryKey: ["orgs", orgId],
        queryFn: async () => httpClient.get(`admin/accounts/${orgId}`),
        retry: false
    });

    return {
        isFetching: query.isFetching,
        error: query.error?.message,
        data: query.data
    };
}

function useMoveUserAsync() {
    const queryClient = useQueryClient();
    const refreshOrgSearchResultsIfAny = useRefreshOrgSearchResultsIfAny();
    const timeout = 1000 * 60 * 2; // 2 minutes

    const mutation = useMutation({
        mutationFn: async (request) => httpClient.put(`admin/accounts/${request.fromAccountId}/users/move`, request, timeout),
        // These callbacks ares called BEFORE their mutateAsync versions, even if the component is unmounted
        onSuccess: () => queryClient.invalidateQueries({ queryKey: ["orgs"] }),
        onSettled: () => refreshOrgSearchResultsIfAny(),
    });

    return (request) =>
        mutation.mutateAsync(request, {
            // These callbacks are called AFTER their useMutation versions but ONLY if the component is still mounted
            onSuccess: () => console.log("success and still mounted"),
            onSettled: () => console.log("settled and still mounted"),
        });
}
