import ReactGA from "react-ga4";
import * as api from "api";

export const getBenchmarkStatistics = (criteria) => (dispatch) => {
    return new Promise((resolve, reject) => {
        ReactGA.event({
            category: "Reporting",
            action: "Get Benchmark Statistics",
        });
        return dispatch(
            api.post({
                path: `reporting/benchmarks/statistics`,
                content: criteria,
                onSuccess: (response) => {
                    resolve(response);
                    return {};
                },
                onFailure: (error) => {
                    error.handled = true;
                    reject(error);
                    return {};
                },
            })
        );
    });
};
