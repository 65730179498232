import { useContext, useRef, useState, useEffect, createElement } from "react";
import { createPortal } from "react-dom";
import { useSelector, useDispatch } from "react-redux";
import { useLocation, useHistory } from "react-router-dom";
import { change as reduxFormChange } from "redux-form";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import LogRocket from "logrocket";
import { httpClient } from "common/httpClient";
import { ConfirmContext } from "components/Confirm";
import { getCompositions } from "containers/Custom/_actions";
import { setFormState, toggleQuickTips, modalInlineOpen, modalInlineClose, modal as openReduxModal, closeModal as closeReduxModal } from "containers/App/_actions";

export function useModal(component, baseProps) {
    const isMounted = useRef(true);
    const [modal, setModal] = useState();

    useEffect(() => {
        isMounted.current = true;
        return () => (isMounted.current = false);
    }, [modal]);

    const closeModal = (onClose) => () => {
        if (!isMounted.current) {
            return;
        }

        setModal(null);

        if (onClose && typeof onClose === "function") {
            onClose();
        }
    };

    const openModal = (instanceProps, onClose) => {
        if (!isMounted.current) {
            return;
        }

        const modalElement = createElement(component, { ...baseProps, ...instanceProps, close: closeModal(onClose) });
        const modalWrapper = createElement("div", {}, modalElement);
        const modalPortal = createPortal(modalWrapper, document.getElementById("modal"));
        setModal(modalPortal);
    };

    return [modal, openModal];
}

export function useOnEscapeKeyPress(onEscapeKeyPressed) {
    useEffect(() => {
        function onEscape(e) {
            if (e.keyCode === 27 && onEscapeKeyPressed) {
                e.preventDefault();
                onEscapeKeyPressed();
            }
        }
        document.addEventListener("keydown", onEscape);
        return () => document.removeEventListener("keydown", onEscape);
    });
}

function loadScript(src) {
    return new Promise((resolve, reject) => {
        if (document.querySelector(`script[src="${src}"]`)) return resolve();
        const script = document.createElement('script');
        script.src = src;
        script.onload = () => resolve();
        script.onerror = (err) => reject(err);
        document.body.appendChild(script);
    });
}

export function useLoadScript({ src, onLoad, onError }) {
    useEffect(() => {
        loadScript(src)
            .then(onLoad)
            .catch(onError);

        return () => {
            const scriptElement = document.querySelector(`script[src="${src}"]`)
            if (scriptElement) document.body.removeChild(scriptElement)
        }
    }, [src, onLoad, onError]);
}

export function useOnline() {
    const isOnline = useSelector(state => state.app.online);
    return isOnline;
}

export function useRefData() {
    const refData = useSelector(state => state.app.refData);
    return refData;
}

export function useAuthContext() {
    const authContext = useSelector(state => {
        const result = {
            isAuthenticated: state.auth.isAuthenticated,
            isActivated: state.auth.isActivated,
            isSystemAdmin: state.auth.isSystemAdmin,
            creditLimit: state.auth.creditLimit,
            isAdmin: state.auth.isAdmin,
        };
        if (result.isAuthenticated) {
            result.accountId = state.auth.accountId;
            result.accountName = state.auth.accountName;
            result.accountType = state.auth.accountType;
            result.userId = state.auth.userId;
            result.fullName = state.auth.fullName;
            result.email = state.auth.email;
            result.canChangePassword = state.auth.canChangePassword;
            result.canBePublishedTo = state.auth.canBePublishedTo;
            result.canPublishToMPIWholeFarmDataProject = canPublishToMPIWholeFarmDataProject(state.auth);
            result.canPublishWithoutSubscription = canPublishWithoutSubscription(state.auth);
            result.canCreateFertiliserRecommendations = canCreateFertiliserRecommendations(state.auth);
            result.orgApiAccess = state.auth.orgApiAccess;
            result.isStudent = state.auth.accountType === "Student";
            result.parentAccountId = state.auth.parentAccountId;
            result.council = state.auth.council;
        }
        return result;
    });
    return authContext;
}

export function useUserDefinedCrops() {
    const userDefinedCrops = useSelector(state => state.customNutrients.crops);
    return userDefinedCrops;
}

export function useGetCustomNutrientCompositions() {
    const dispatch = useDispatch();
    return () => dispatch(getCompositions());
}

export function useCustomNutrientCompositions() {
    const customNutrientCompositions = useSelector(state => state.customNutrients?.nutrientCompositions || []);
    return customNutrientCompositions;
}

export function useShowQuickTips() {
    const showQuickTips = useSelector(state => state.app.showQuickTips);
    return showQuickTips;
}

export function useToggleQuickTips() {
    const dispatch = useDispatch();
    return (e) => dispatch(toggleQuickTips(e));
}

export function useFormState() {
    const formState = useSelector(state => state.app.formState);
    return formState;
}

export function useSetFormState() {
    const dispatch = useDispatch();
    return (name, value) => dispatch(setFormState(name, value));
}

export function useReduxFormChange() {
    const dispatch = useDispatch();

    return (form, field, value, touch, persistentSubmitErrors) => dispatch(reduxFormChange(form, field, value, touch, persistentSubmitErrors));
}

export function useNavigate() {
    const history = useHistory();
    return (path, state) => {
        history.push(path, state);
    }
}

export function useConfirm() {
    return useContext(ConfirmContext);
}

export function useIsPaidVersionOfOverseer() {
    const { deploymentType } = useRefData();
    return !deploymentType || deploymentType === "Commercial";
}

export function useIsOverseerEd() {
    const { deploymentType } = useRefData();
    return deploymentType === "Education";
}

export function useIsOverseerSci() {
    const { deploymentType } = useRefData();
    return deploymentType === "Science";
}

export function useIsStudentUser() {
    const authContext = useAuthContext();
    const isOverseerEd = useIsOverseerEd();

    return isOverseerEd && authContext.accountType === "Student";
}

export function useIsEducationProviderUser() {
    const authContext = useAuthContext();
    const isOverseerEd = useIsOverseerEd();

    return isOverseerEd && authContext.accountType === "EducationProvider";
}

export function useIsCouncilUser() {
    const authContext = useAuthContext();
    return authContext.accountType === "Council";
}

export function useReferrer() {
    const location = useLocation();
    return location.state?.referrer || "/";
}

export function useOnLocationChange(onLocationChange) {
    const location = useLocation();

    useEffect(() => {
        if (onLocationChange) {
            onLocationChange(location);
        }
    }, [location, onLocationChange]);
}

export const useDebounce = (value, delay = 500) => {
    const [debouncedValue, setDebouncedValue] = useState(value);

    useEffect(() => {
        const debounce = setTimeout(() => {
            setDebouncedValue(value);
        }, delay);
        return () => clearTimeout(debounce);
    }, [value, delay]);

    return debouncedValue;
}

export const useModalInline = () => {
    const dispatch = useDispatch();

    return {
        modalInlineOpen: () => dispatch(modalInlineOpen()),
        modalInlineClose: () => dispatch(modalInlineClose())
    };
}

export const useReduxModal = () => {
    const dispatch = useDispatch();

    return {
        open: (modalType, onConfirm, props) => dispatch(openReduxModal(modalType, onConfirm, props)),
        close: () => dispatch(closeReduxModal())
    }
}

export const useOpenReduxModal = () => {
    const dispatch = useDispatch();

    return (modalType, onConfirm, props) => dispatch(openReduxModal(modalType, onConfirm, props));
}

export const useCloseReduxModal = () => {
    const dispatch = useDispatch();

    return () => dispatch(closeReduxModal());
}

/**
 * Invalidate all query keys when the API goes offline so that they'll auto refetch when it comes back online
 */
export function useInvalidateAllQueryKeysOnDisconnect() {
    const isApiOnline = useOnline();
    const queryClient = useQueryClient();

    useEffect(() => {
        if (!isApiOnline && queryClient) {
            queryClient.invalidateQueries();
        }
    }, [isApiOnline, queryClient]);
}

export function useSessionRecording() {
    const { data } = useMyClaims();
    const authContext = useAuthContext();

    useEffect(() => {
        if (data?.recordSessions) {
            if (process.env.REACT_APP_LOG_ROCKET_APP_ID?.length > 0) {
                LogRocket.init(process.env.REACT_APP_LOG_ROCKET_APP_ID);

                if (authContext?.userId) {
                    LogRocket.identify(authContext.userId, {
                        email: authContext.email,
                        name: authContext.fullName,
                        orgId: authContext.accountId,
                        orgName: authContext.accountName,
                    });
                }
            }
        }
    }, [data?.recordSessions, authContext?.userId, authContext?.email, authContext?.fullName, authContext?.accountId, authContext?.accountName]);
}

export function useMyClaims() {
    const query = useQuery({
        queryKey: ["my", "claims"],
        queryFn: async () => httpClient.get(`auth/claims`),
        retry: false,
        refetchOnWindowFocus: true,
        staleTime: 1000 * 60 * 5, // 5 minutes
    });

    return {
        isFetching: query.isFetching,
        isLoading: query.isLoading,
        data: query.data,
        error: query.error,
    };
}

function canPublishToMPIWholeFarmDataProject(authState) {
    return authState.canPublishToMPIWholeFarmDataProject || authState.isSystemAdmin;
}

function canPublishWithoutSubscription(authState) {
    return authState.canPublishToMPIWholeFarmDataProject || authState.isSystemAdmin;
}

function canCreateFertiliserRecommendations(authState) {
    return authState.canCreateFertiliserRecommendations;
}