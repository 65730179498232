import { useState, useEffect } from "react";
import { Field } from "react-final-form";
import { useDebounce } from "common/hooks";
import TextField from "./TextField";

export default function SearchField({ input, onSearch, ...rest }) {
    const [searchText, setSearchText] = useState();

    const debouncedSearchText = useDebounce(searchText);

    useEffect(() => {
        async function handleSearchAsync(query) {
            await onSearch(query);
        }

        if (onSearch && debouncedSearchText?.length > 2) {
            handleSearchAsync(debouncedSearchText);
        }
    }, [onSearch, debouncedSearchText]);

    return (
        <Field {...input} {...rest} onChange={setSearchText} component={TextField} />
    )
}
