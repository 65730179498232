import { httpClient } from "common/httpClient";

export default function OtherTools() {

    return (
        <>
            <input type="button" onClick={() => clearCache()} value="Clear Cache" className="Button Button--primary" />
            <input type="button" onClick={() => refreshFarmTags()} value="Refresh farm tags" className="Button Button--primary u-ml-md" />
        </>
    );
}

function clearCache() {
    httpClient.put("admin/clearCache")
        .then(() => {
            alert("Cache cleared");
        })
        .catch(() => {
            alert("Failed to clear cache");
        });
}

function refreshFarmTags() {
    httpClient.put("admin/refreshFarmTags")
        .then(() => {
            alert("Farm tags refreshed");
        })
        .catch(() => {
            alert("Failed to refresh farm tags");
        });
}
