import * as api from "api";
import ReactGA from "react-ga4";

const actionTypes = {
    ADMIN_GET_STATS: "ADMIN_GET_STATS",
    ADMIN_GET_STATS_SUCCESS: "ADMIN_GET_STATS_SUCCESS",
    ADMIN_GET_STATS_FAILURE: "ADMIN_GET_STATS_FAILURE",

    ADMIN_FIND_ACCOUNTS: "ADMIN_FIND_ACCOUNTS",
    ADMIN_FIND_ACCOUNTS_SUCCESS: "ADMIN_FIND_ACCOUNTS_SUCCESS",
    ADMIN_FIND_ACCOUNTS_FAILURE: "ADMIN_FIND_ACCOUNTS_FAILURE",
    ADMIN_FIND_ACCOUNTS_CANCEL: "ADMIN_FIND_ACCOUNTS_CANCEL",

    ADMIN_FIND_FARMS: "ADMIN_FIND_FARMS",
    ADMIN_FIND_FARMS_SUCCESS: "ADMIN_FIND_FARMS_SUCCESS",
    ADMIN_FIND_FARMS_FAILURE: "ADMIN_FIND_FARMS_FAILURE",
    ADMIN_FIND_FARMS_CANCEL: "ADMIN_FIND_FARMS_CANCEL",

    ADMIN_GET_PAYMENTS: "ADMIN_GET_PAYMENTS",
    ADMIN_GET_PAYMENTS_SUCCESS: "ADMIN_GET_PAYMENTS_SUCCESS",
    ADMIN_GET_PAYMENTS_FAILURE: "ADMIN_GET_PAYMENTS_FAILURE",
    ADMIN_GET_PAYMENTS_CLEAR: "ADMIN_GET_PAYMENTS_CLEAR",

    ADMIN_GET_BALANCESUMMARY: "ADMIN_GET_BALANCESUMMARY",
    ADMIN_GET_BALANCESUMMARY_SUCCESS: "ADMIN_GET_BALANCESUMMARY_SUCCESS",
    ADMIN_GET_BALANCESUMMARY_FAILURE: "ADMIN_GET_BALANCESUMMARY_FAILURE",
    ADMIN_GET_BALANCESUMMARY_CLEAR: "ADMIN_GET_BALANCESUMMARY_CLEAR",

    ADMIN_GET_BALANCE_CLEAR: "ADMIN_GET_BALANCE_CLEAR",
    ADMIN_GET_BALANCE: "ADMIN_GET_BALANCE",
    ADMIN_GET_BALANCE_SUCCESS: "ADMIN_GET_BALANCE_SUCCESS",
    ADMIN_GET_BALANCE_FAILURE: "ADMIN_GET_BALANCE_FAILURE",

    ADMIN_GET_ACCOUNT_CLEAR: "ADMIN_GET_ACCOUNT_CLEAR",
    ADMIN_GET_ACCOUNT: "ADMIN_GET_ACCOUNT",
    ADMIN_GET_ACCOUNT_SUCCESS: "ADMIN_GET_ACCOUNT_SUCCESS",
    ADMIN_GET_ACCOUNT_FAILURE: "ADMIN_GET_ACCOUNT_FAILURE",

    ADMIN_CREATE_PAYMENT_CANCEL: "ADMIN_CREATE_PAYMENT_CANCEL",

    ADMIN_UPGRADE: "ADMIN_UPGRADE",
    ADMIN_UPGRADE_SUCCESS: "ADMIN_UPGRADE_SUCCESS",
    ADMIN_UPGRADE_FAILURE: "ADMIN_UPGRADE_FAILURE",

    ADMIN_CHECK_UPGRADE: "ADMIN_CHECK_UPGRADE",
    ADMIN_CHECK_UPGRADE_SUCCESS: "ADMIN_CHECK_UPGRADE_SUCCESS",
    ADMIN_CHECK_UPGRADE_FAILURE: "ADMIN_CHECK_UPGRADE_FAILURE",

    ADMIN_GET_FARMS_UPGRADE: "ADMIN_GET_FARMS_UPGRADE",
    ADMIN_GET_FARMS_UPGRADE_SUCCESS: "ADMIN_GET_FARMS_UPGRADE_SUCCESS",
    ADMIN_GET_FARMS_UPGRADE_FAILURE: "ADMIN_GET_FARMS_UPGRADE_FAILURE",
};
export default actionTypes;

export function getStats() {
    ReactGA.event({
        category: "Admin",
        action: "Get Stats",
    });
    return api.get({
        path: `admin/stats`,
        onBeforeSend: () => {
            return { type: actionTypes.ADMIN_GET_STATS };
        },
        onSuccess: (response) => {
            return { type: actionTypes.ADMIN_GET_STATS_SUCCESS, payload: response };
        },
        onFailure: (error) => {
            return { type: actionTypes.ADMIN_GET_STATS_FAILURE };
        },
    });
}

export function findAccounts(searchFor, searchText) {
    ReactGA.event({
        category: "Admin",
        action: "Find Accounts",
    });
    if (searchText && searchText.length > 2) {
        const encodedSearchText = encodeURIComponent(searchText);
        return api.get({
            path: `admin/search/accounts?searchText=${encodedSearchText}`,
            onBeforeSend: () => {
                return { type: actionTypes.ADMIN_FIND_ACCOUNTS, payload: { searchFor, searchText } };
            },
            onSuccess: (response) => {
                return { type: actionTypes.ADMIN_FIND_ACCOUNTS_SUCCESS, payload: response };
            },
            onFailure: (error) => {
                return { type: actionTypes.ADMIN_FIND_ACCOUNTS_FAILURE };
            },
        });
    } else {
        return { type: actionTypes.ADMIN_FIND_ACCOUNTS_CANCEL, payload: { searchText } };
    }
}

export const findAccountsAsync = (searchText) => (dispatch) => {
    return new Promise((resolve, reject) => {
        ReactGA.event({
            category: "Admin",
            action: "Find accounts",
        });
        const encodedSearchText = encodeURIComponent(searchText);
        return dispatch(
            api.get({
                path: `admin/search/accounts?searchText=${encodedSearchText}`,
                onSuccess: (response) => {
                    resolve(response);
                    return [];
                },
                onFailure: (error) => {
                    error.handled = true;
                    reject(error);
                    return [];
                },
            })
        );
    });
};

export const getFarmStats = () => (dispatch) => {
    return new Promise((resolve, reject) => {
        return dispatch(
            api.get({
                path: `admin/farm/statistics`,
                onSuccess: (response) => {
                    resolve(response);
                    return [];
                },
                onFailure: (error) => {
                    error.handled = true;
                    reject(error);
                    return [];
                },
            })
        );
    });
};

export const getAnalysisDiagnostics = (analysisId) => (dispatch) => {
    return new Promise((resolve, reject) => {
        ReactGA.event({
            category: "Admin",
            action: "Analysis diagnostics",
        });
        return dispatch(
            api.get({
                path: `admin/analysis/${analysisId}/diagnostics`,
                onSuccess: (response) => {
                    resolve(response);
                    return [];
                },
                onFailure: (error) => {
                    error.handled = true;
                    reject(error);
                    return [];
                },
            })
        );
    });
};

export function findFarms(searchFor, searchText) {
    ReactGA.event({
        category: "Admin",
        action: "Find Farms",
    });
    if (searchText && searchText.length > 2) {
        const encodedSearchText = encodeURIComponent(searchText);
        return api.get({
            path: `admin/search/farms?searchText=${encodedSearchText}`,
            onBeforeSend: () => {
                return {
                    type: actionTypes.ADMIN_FIND_FARMS,
                    payload: { searchFor, searchText },
                };
            },
            onSuccess: (response) => {
                return { type: actionTypes.ADMIN_FIND_FARMS_SUCCESS, payload: response };
            },
            onFailure: (error) => {
                return { type: actionTypes.ADMIN_FIND_FARMS_FAILURE };
            },
        });
    } else {
        return { type: actionTypes.ADMIN_FIND_FARMS_CANCEL, payload: { searchText } };
    }
}

export const searchFarms = (searchText) => (dispatch) => {
    return new Promise((resolve, reject) => {
        if (searchText && searchText.length > 2) {
            ReactGA.event({
                category: "Admin",
                action: "Find Farms",
            });
            const encodedSearchText = encodeURIComponent(searchText);
            dispatch(
                api.get({
                    path: `admin/search/farms?searchText=${encodedSearchText}`,
                    onSuccess: (response) => {
                        resolve(response);
                        return [];
                    },
                    onFailure: (error) => {
                        error.handled = true;
                        reject("An unexpected error has occured. Please try again or call support if the problem persists.");
                        return [];
                    },
                })
            );
        } else {
            resolve();
        }
    });
};

export function getPaymentsByMonthYear(yearMonth, accountId) {
    ReactGA.event({
        category: "Admin",
        action: "Get Payments",
    });
    return api.get({
        path: accountId ? `accounts/${accountId}/payments/${yearMonth}` : `admin/payments/${yearMonth}`,
        onBeforeSend: () => {
            return { type: actionTypes.ADMIN_GET_PAYMENTS };
        },
        onSuccess: (response) => {
            return { type: actionTypes.ADMIN_GET_PAYMENTS_SUCCESS, payload: response };
        },
        onFailure: (error) => {
            return { type: actionTypes.ADMIN_GET_PAYMENTS_FAILURE };
        },
    });
}

export function getRecentPayments(lastCount, accountId) {
    ReactGA.event({
        category: "Admin",
        action: "Get Payments",
    });
    return api.get({
        path: accountId ? `accounts/${accountId}/payments/last/${lastCount}` : `admin/payments/last/${lastCount}`,
        onBeforeSend: () => {
            return { type: actionTypes.ADMIN_GET_PAYMENTS };
        },
        onSuccess: (response) => {
            return { type: actionTypes.ADMIN_GET_PAYMENTS_SUCCESS, payload: response };
        },
        onFailure: (error) => {
            return { type: actionTypes.ADMIN_GET_PAYMENTS_FAILURE };
        },
    });
}

export function clearBalanceSummary() {
    return { type: actionTypes.ADMIN_GET_BALANCESUMMARY_CLEAR };
}

export function getBalanceSummary(yearMonth) {
    ReactGA.event({
        category: "Admin",
        action: "Get Payments",
    });
    return api.get({
        path: `admin/balancesummary/${yearMonth}`,
        onBeforeSend: () => {
            return { type: actionTypes.ADMIN_GET_BALANCESUMMARY };
        },
        onSuccess: (response) => {
            return { type: actionTypes.ADMIN_GET_BALANCESUMMARY_SUCCESS, payload: response };
        },
        onFailure: (error) => {
            return { type: actionTypes.ADMIN_GET_BALANCESUMMARY_FAILURE };
        },
    });
}

export function clearBalance() {
    return { type: actionTypes.ADMIN_GET_BALANCE_CLEAR };
}

export function getBalance(accountId, yearMonth) {
    ReactGA.event({
        category: "Admin",
        action: "Get Payments",
    });
    return api.get({
        path: yearMonth ? `admin/accounts/${accountId}/balance/${yearMonth}` : `admin/accounts/${accountId}/balance`,
        onBeforeSend: () => {
            return { type: actionTypes.ADMIN_GET_BALANCE };
        },
        onSuccess: (response) => {
            return { type: actionTypes.ADMIN_GET_BALANCE_SUCCESS, payload: response };
        },
        onFailure: (error) => {
            return { type: actionTypes.ADMIN_GET_BALANCE_FAILURE };
        },
    });
}

export function getAccount(accountId) {
    ReactGA.event({
        category: "Admin",
        action: "Get Account",
    });
    return api.get({
        path: `admin/accounts/${accountId}`,
        onBeforeSend: () => {
            return { type: actionTypes.ADMIN_GET_ACCOUNT };
        },
        onSuccess: (response) => {
            return { type: actionTypes.ADMIN_GET_ACCOUNT_SUCCESS, payload: response };
        },
        onFailure: (error) => {
            return { type: actionTypes.ADMIN_GET_ACCOUNT_FAILURE };
        },
    });
}

export const mergeFarms = (sourceFarmId, destinationFarmId) => (dispatch, getState) => {
    const cancelActionType = `mergeFarms`;
    return new Promise((resolve, reject) => {
        ReactGA.event({
            category: "Admin",
            action: "Merge Farms",
        });
        dispatch({ type: cancelActionType });
        return dispatch(
            api.put({
                path: "admin/mergeFarms",
                timeout: 300000,
                content: {
                    sourceFarmId,
                    destinationFarmId,
                },
                onSuccess: (response) => {
                    resolve(response);
                    return (dispatch, getState) => {
                        const { admin } = getState();
                        dispatch(findFarms(admin.searchFor, admin.searchText));
                    };
                },
                onFailure: (error) => {
                    error.handled = true;
                    reject("An unexpected error has occured. Please try again or call support if the problem persists.");
                    return [];
                },
                cancelActionType,
            })
        );
    });
};

export const transferSubscriptions = (sourceFarmId, destinationFarmId) => (dispatch, getState) => {
    const cancelActionType = `transferSubscriptions`;
    return new Promise((resolve, reject) => {
        ReactGA.event({
            category: "Admin",
            action: "Transfer Subscriptions",
        });
        dispatch({ type: cancelActionType });
        return dispatch(
            api.put({
                path: "admin/transferSubscriptions",
                content: {
                    sourceFarmId,
                    destinationFarmId,
                },
                onSuccess: (response) => {
                    resolve(response);
                    return (dispatch, getState) => {
                        const { admin } = getState();
                        dispatch(findFarms(admin.searchFor, admin.searchText));
                    };
                },
                onFailure: (error) => {
                    error.handled = true;
                    reject("An unexpected error has occured. Please try again or call support if the problem persists.");
                    return [];
                },
                cancelActionType,
            })
        );
    });
};

export const upgradeFarm = (farmId, upgrade, checkSoils, analysisData, budgetsOnly, upgradeRunId) => (dispatch) => {
    return new Promise((resolve, reject) => {
        ReactGA.event({
            category: "Admin",
            action: "UpgradeFarm",
        });
        return dispatch(
            api.put({
                path: `admin/upgrade`,
                content: { farmId: farmId, checkSoils: checkSoils, runModel: upgrade, updateAnalysisData: analysisData, budgetsOnly: budgetsOnly, upgradeRunId: upgradeRunId },
                silentMode: true,
                timeout: 120000,
                onSuccess: () => {
                    resolve("response text");
                    return [];
                },
                onFailure: (error) => {
                    error.handled = true;
                    reject(error);
                    return [];
                },
            })
        );
    });
};

export const getFarmIdsForUpgrade = () => (dispatch) => {
    return new Promise((resolve, reject) => {
        ReactGA.event({
            category: "Admin",
            action: "Get farm IDs for upgrade",
        });
        return dispatch(
            api.get({
                path: `admin/upgrade/farms`,
                onSuccess: (response) => {
                    resolve(response);
                    return [];
                },
                onFailure: (error) => {
                    error.handled = true;
                    reject(error);
                    return [];
                },
            })
        );
    });
};

export const getRenewalStats = (year, month) => (dispatch) => {
    const cancelActionType = `getRenewalStats`;
    return new Promise((resolve, reject) => {
        ReactGA.event({
            category: "Admin",
            action: "Get Renewal Stats",
        });
        dispatch({ type: cancelActionType });
        return dispatch(
            api.get({
                path: `admin/renewalStats/${year}/${month}`,
                timeout: 120000,
                onSuccess: (response) => {
                    resolve(response);
                    return [];
                },
                onFailure: (error) => {
                    error.handled = true;
                    reject(error);
                    return [];
                },
                cancelActionType,
            })
        );
    });
};

export const getAccessRequestedStats = () => (dispatch) => {
    const cancelActionType = `getAccessRequestedStats`;
    return new Promise((resolve, reject) => {
        ReactGA.event({
            category: "Admin",
            action: "Get Access Requested Stats",
        });
        dispatch({ type: cancelActionType });
        return dispatch(
            api.get({
                path: `admin/accessRequestedStats`,
                onSuccess: (response) => {
                    resolve(response);
                    return [];
                },
                onFailure: (error) => {
                    error.handled = true;
                    reject(error);
                    return [];
                },
                cancelActionType,
            })
        );
    });
};

export const getAccessGivenStats = () => (dispatch) => {
    const cancelActionType = `getAccessGivenStats`;
    return new Promise((resolve, reject) => {
        ReactGA.event({
            category: "Admin",
            action: "Get Access Given Stats",
        });
        dispatch({ type: cancelActionType });
        return dispatch(
            api.get({
                path: `admin/accessGivenStats`,
                onSuccess: (response) => {
                    resolve(response);
                    return [];
                },
                onFailure: (error) => {
                    error.handled = true;
                    reject(error);
                    return [];
                },
                cancelActionType,
            })
        );
    });
};

export const getAllFarmGroups = () => (dispatch) => {
    return new Promise((resolve, reject) => {
        ReactGA.event({
            category: "Admin",
            action: "Get All Farm Groups",
        });
        return dispatch(
            api.get({
                path: `admin/farmGroups`,
                onSuccess: (response) => {
                    resolve(response);
                    return [];
                },
                onFailure: (error) => {
                    error.handled = true;
                    reject("An unexpected error has occured. Please try again or call support if the problem persists.");
                    return [];
                },
            })
        );
    });
};

export const createAnalysisUpgradeRun = (run) => (dispatch) => {
    return new Promise((resolve, reject) => {
        ReactGA.event({
            category: "Admin",
            action: "Create Analysis Upgrade Run",
        });
        return dispatch(
            api.post({
                path: `admin/upgradeRuns`,
                timeout: 300000,
                content: { ...run },
                onSuccess: (response) => {
                    resolve(response);
                    return [];
                },
                onFailure: (error) => {
                    error.handled = true;
                    reject(error);
                    return [];
                },
            })
        );
    });
};

export const getAnalysisUpgradeRuns = () => (dispatch) => {
    return new Promise((resolve, reject) => {
        ReactGA.event({
            category: "Admin",
            action: "Get Analysis Upgrade Runs",
        });
        return dispatch(
            api.get({
                path: `admin/upgradeRuns`,
                onSuccess: (response) => {
                    resolve(response);
                    return [];
                },
                onFailure: (error) => {
                    error.handled = true;
                    reject(error);
                    return [];
                },
            })
        );
    });
};

export const upgradeAnalysis = (runId, budgetBaseId) => (dispatch) => {
    return new Promise((resolve, reject) => {
        ReactGA.event({
            category: "Admin",
            action: "Upgrade Analysis",
        });
        return dispatch(
            api.put({
                path: `admin/upgradeRuns/${runId}/budgetBaseIds/${budgetBaseId}`,
                timeout: 300000,
                onSuccess: (response) => {
                    resolve(response);
                    return [];
                },
                onFailure: (error) => {
                    error.handled = true;
                    reject(error);
                    return [];
                },
            })
        );
    });
};

export const upgradeFarmDetails = (runId, farmId) => (dispatch) => {
    return new Promise((resolve, reject) => {
        ReactGA.event({
            category: "Admin",
            action: "Upgrade Farm details",
        });
        return dispatch(
            api.put({
                path: `admin/upgradeRuns/${runId}/farmIds/${farmId}`,
                timeout: 300000,
                onSuccess: (response) => {
                    resolve(response);
                    return [];
                },
                onFailure: (error) => {
                    error.handled = true;
                    reject(error);
                    return [];
                },
            })
        );
    });
};

export const deleteAnalysisUpgradeRun = (runId) => (dispatch) => {
    return new Promise((resolve, reject) => {
        ReactGA.event({
            category: "Admin",
            action: "Delete Analysis Upgrade Run",
        });
        return dispatch(
            api.del({
                path: `admin/upgradeRuns/${runId}`,
                onSuccess: (response) => {
                    resolve(response);
                    return [];
                },
                onFailure: (error) => {
                    error.handled = true;
                    reject(error);
                    return [];
                },
            })
        );
    });
};

export const downloadAnalysisUpgradeResults = (runId, type) => (dispatch) => {
    return new Promise((resolve, reject) => {
        ReactGA.event({
            category: "Admin",
            action: "Download Analysis Upgrade Results",
        });
        return dispatch(
            api.get({
                path: type === "raw" ? `admin/upgradeRuns/${runId}/rawresults.csv` : type === "count" ? `admin/upgradeRuns/${runId}/count.csv` : `admin/upgradeRuns/${runId}/results.csv`,
                contentType: "text/csv",
                timeout: 300000,
                onSuccess: (response) => {
                    resolve(response);
                    return [];
                },
                onFailure: (error) => {
                    error.handled = true;
                    reject(error);
                    return [];
                },
            })
        );
    });
};

export const downloadAnalysisBlocksUpgradeResults = (runId, type) => (dispatch) => {
    return new Promise((resolve, reject) => {
        ReactGA.event({
            category: "Admin",
            action: "Download Analysis Blocks Upgrade Results",
        });
        return dispatch(
            api.get({
                path: type === "raw" ? `admin/upgradeRuns/${runId}/rawblockresults.csv` : type === "count" ? `admin/upgradeRuns/${runId}/count.csv` : `admin/upgradeRuns/${runId}/rawblockresults.csv`,
                contentType: "text/csv",
                onSuccess: (response) => {
                    resolve(response);
                    return [];
                },
                onFailure: (error) => {
                    error.handled = true;
                    reject(error);
                    return [];
                },
            })
        );
    });
};

export const getUpgradeRunRawResultsSummary = (runId) => (dispatch) => {
    return new Promise((resolve, reject) => {
        ReactGA.event({
            category: "Admin",
            action: "Get Analysis Summary Upgrade Run Results",
        });
        return dispatch(
            api.get({
                path: `admin/upgradeRuns/${runId}/rawresults`,
                timeout: 180000,
                onSuccess: (response) => {
                    resolve(response);
                    return [];
                },
                onFailure: (error) => {
                    error.handled = true;
                    reject(error);
                    return [];
                },
            })
        );
    });
};

export const getAutoRenewals = () => (dispatch) => {
    return new Promise((resolve, reject) => {
        ReactGA.event({
            category: "Admin",
            action: "Get List of Upcoming Auto Renewals",
        });
        return dispatch(
            api.get({
                path: `admin/payments/renewals`,
                timeout: 180000,
                onSuccess: (response) => {
                    resolve(response);
                    return [];
                },
                onFailure: (error) => {
                    error.handled = true;
                    reject(error);
                    return [];
                },
            })
        );
    });
};

export const deleteAutoRenewal = (farmId) => (dispatch) => {
    return new Promise((resolve, reject) => {
        ReactGA.event({
            category: "Admin",
            action: "Delete Auto Renewal",
        });
        return dispatch(
            api.del({
                path: `admin/farms/${farmId}/renewals`,
                content: { farmId },
                onSuccess: (response) => {
                    resolve(response);
                    return [];
                },
                onFailure: (error) => {
                    error.handled = true;
                    reject(error);
                    return [];
                },
            })
        );
    });
};

export const getFailedPayments = () => (dispatch) => {
    return new Promise((resolve, reject) => {
        ReactGA.event({
            category: "Admin",
            action: "Get List of Failed Payments with no Subscription",
        });
        return dispatch(
            api.get({
                path: `admin/payments/failed`,
                timeout: 180000,
                onSuccess: (response) => {
                    resolve(response);
                    return [];
                },
                onFailure: (error) => {
                    error.handled = true;
                    reject(error);
                    return [];
                },
            })
        );
    });
};
