import { compose, withState, withAppContext } from "containers/HOC";
import { Switch, Route } from "react-router-dom";
import Page from "components/Page";
import PageBody from "components/PageBody";
import { Panel, PanelBody, PanelTabs } from "components/Panel";
import Stats from "./Stats/Stats";
import DevTeamOnly from "./DevTeamOnly/Index";
import Reports from "./Reports/Reports";
import NationalReporting from "./NationalReporting/NationalReporting";
import Emails from "./Email/Emails";
import Analytics from "./Analytics/Analytics";
import FarmGroups from "./FarmGroups/FarmGroups";
import { AllOrgs, Org } from "containers/Admin/Orgs";
import { Payments } from "containers/Admin/Payments";
import { Search } from "containers/Admin/Search";
import FarmSummary from "./FarmSummary/FarmSummary";
import Notifications from "./Notifications/Notifications";
import { SessionRecording } from "containers/Admin/SessionRecording";

const Admin = ({ accountId, farmId, authContext }) => {
    const baseUrl = "/app/admin";

    const tabs = {
        stats: { title: "Stats", path: `${baseUrl}`, exact: true },
        orgs: { title: "All orgs", path: `${baseUrl}/orgs`, exact: true },
        farmGroups: { title: "Farm groups", path: `${baseUrl}/farmGroups` },
        payments: { title: "Payment transactions", path: `${baseUrl}/payments`, exact: true },
        reports: { title: "Reports", path: `${baseUrl}/reports` },
        national: { title: "National reporting", path: `${baseUrl}/national` },
        email: { title: "Emails", path: `${baseUrl}/emails` },
        analytics: { title: "Analytics", path: `${baseUrl}/analytics` },
        devTeamOnly: { title: "Dev team only", path: `${baseUrl}/devTeamOnly`, exact: true },
        search: { title: "Search", path: `${baseUrl}/search`, exact: true },
        sessionRecording: { title: "Session recording", path: `${baseUrl}/sessionRecording`, exact: true },
        notifications: { title: "Notifications", path: `${baseUrl}/notifications`, exact: true },
    };

    return (
        <Page>
            <PageBody>
                <Panel>
                    <PanelTabs tabs={tabs} orange />
                    <PanelBody>
                        {authContext.isSystemAdmin && (
                            <Switch>
                                <Route exact path={tabs.stats.path} render={() => <Stats />} />
                                <Route exact path={tabs.orgs.path} render={() => <AllOrgs />} />
                                <Route path={tabs.farmGroups.path} render={() => <FarmGroups />} />
                                <Route path={tabs.payments.path} render={() => <Payments />} />
                                <Route path={tabs.reports.path} render={() => <Reports />} />
                                <Route path={tabs.national.path} render={() => <NationalReporting />} />
                                <Route path={tabs.devTeamOnly.path} render={() => <DevTeamOnly />} />
                                <Route exact path={tabs.search.path} render={() => <Search />} />
                                <Route exact path={tabs.email.path} render={() => <Emails />} />
                                <Route path={tabs.analytics.path} render={() => <Analytics />} />
                                <Route exact path={tabs.sessionRecording.path} render={() => <SessionRecording />} />
                                <Route exact path={tabs.notifications.path} render={() => <Notifications />} />
                                <Route path="/app/admin/orgs/:accountId" render={() => <Org orgId={accountId} />} />
                                <Route path="/app/admin/farms/:farmId" render={() => <FarmSummary farmId={farmId} />} />
                            </Switch>
                        )}
                        {!authContext.isSystemAdmin && (
                            <div className="Tile-body-message">
                                <i className="icon icon--md icon-alert u-textError" />
                                <h3 className="u-textError">Access denied</h3>
                            </div>
                        )}
                    </PanelBody>
                </Panel>
            </PageBody>
        </Page>
    );
};

export default compose(
    withAppContext,
    withState((_, ownProps) => {
        const accountId = ownProps.match.params.accountId;
        const farmId = ownProps.match.params.farmId;
        return {
            accountId,
            farmId,
        };
    })
)(Admin);
