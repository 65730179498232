import { useQuery } from "@tanstack/react-query";
import { httpClient } from "common/httpClient";

export function useCouncilReportingData(filter, criteria) {
    const query = useQuery({
        queryKey: ["reporting", "national", "content", JSON.stringify(filter), JSON.stringify(criteria)],
        queryFn: async () => httpClient.post(`reporting/national/content`, { filterSet: filter, ...criteria }),
        retry: false,
    });

    return {
        loading: query.isLoading,
        error: query.error?.message,
        data: query.data,
    };
}

export const useCouncilHistory = (filter, criteria, hasParentData) => {
    const query = useQuery({
        queryKey: ["reporting", "national", "history", JSON.stringify(filter), JSON.stringify(criteria)],
        queryFn: async () => httpClient.post(`reporting/national/trends`, { filterSet: filter, ...criteria }, 90000),
        retry: false,
    });

    return {
        loading: query.isLoading,
        error: query.error?.message,
        data: query.data,
        enabled: hasParentData,
    };
};
