/**
 * @typedef {Object} Geometry
 * @property {string} type
 * @property {Array} coordinates
 *
 * @typedef {Object} Feature
 * @property {string|number} id
 * @property {string} type
 * @property {Geometry} geometry
 * @property {{}} properties
 */

import { v4 as uuidv4 } from "uuid";
import turfArea from "@turf/area";
import turfCenter from "@turf/center";
import turfKinks from "@turf/kinks";
import turfIntersect from "@turf/intersect";
import turfFlatten from "@turf/flatten";
import turfEquals from "@turf/boolean-equal";
import turfBBox from "@turf/bbox";
import turfCircle from "@turf/circle";
import turfUnion from "@turf/union";
import * as utils from "./utils";

/**
 * The available map layers
 */
export const LAYER = {
    Block: "Block",
    BlockLabels: "BlockLabels",
    UnassignedLand: "UnassignedLand",
    Irrigator: "Irrigator",
    LegalParcel: "LegalParcel",
}

/**
 * Gets the colors used for the given layer
 * @param {string} layerId
 * @returns {string}
 */
export function getMapLayerColor(layerId) {
    if (layerId === LAYER.Block) return "#00ff00";
    if (layerId === LAYER.BlockLabels) return "#00ff00";
    if (layerId === LAYER.UnassignedLand) return "#ff00ff";
    if (layerId === LAYER.Irrigator) return "#00ffff";
    if (layerId === LAYER.LegalParcel) return "orange";
}

/**
 * Gets the display name text for the given layer
 * @param {string} layerId
 * @returns {string}
 */
export function getMapLayerDisplayText(layerId) {
    if (layerId === LAYER.Block) return "blocks";
    if (layerId === LAYER.BlockLabels) return "block labels";
    if (layerId === LAYER.UnassignedLand) return "unassigned land";
    if (layerId === LAYER.Irrigator) return "drawn irrigators";
    if (layerId === LAYER.LegalParcel) return "LINZ parcels";
}

/**
 * Gets the title text for the given layer
 * @param {string} layerId
 * @returns {string}
 */
export function getMapLayerTitleText(layerId) {
    if (layerId === LAYER.Block) return "Blocks";
    if (layerId === LAYER.BlockLabels) return "Block labels";
    if (layerId === LAYER.UnassignedLand) return "Unassigned land";
    if (layerId === LAYER.Irrigator) return "Drawn irrigators";
    if (layerId === LAYER.LegalParcel) return "LINZ parcels";
}

/**
 * Gets the icon CSS class for the given layer
 * @param {string} layerId
 * @returns {string}
 */
export function getMapLayerIconCssClass(layerId) {
    if (layerId === LAYER.Block) return "icon-blocks";
    if (layerId === LAYER.BlockLabels) return "icon-tag";
    if (layerId === LAYER.UnassignedLand) return "icon-blocks";
    if (layerId === LAYER.Irrigator) return "icon-irrigation";
    if (layerId === LAYER.LegalParcel) return "icon-polygon";
}

/**
 * Gets the area of the feature in hectares.
 * @param {Feature} feature
 * @returns {number}
 */
export function area(feature) {
    return utils.round(turfArea(feature) / 10000, 1);
}

/**
 * @typedef {Object} LatLng
 * @property {number} lat
 * @property {number} lng
 *
 * Gets the lat and lng of the center of the feature.
 * @param {Feature} feature
 * @returns {LatLng}
 */
export function center(feature) {
    const centerFeature = turfCenter(feature);
    return { lat: centerFeature.geometry.coordinates[1], lng: centerFeature.geometry.coordinates[0] };
}

/**
 * Returns true if the feature intersects itself.
 * @param {Feature} feature
 * @returns {boolean}
 */
export function selfIntersects(feature) {
    const kinks = turfKinks(feature);
    return kinks && kinks.features.length > 0;
}

/**
 * Returns true if the first feature intersects the second feature.
 * @param {Feature} feature1
 * @param {Feature} feature2
 * @returns {boolean}
 */
export function intersects(feature1, feature2) {
    const intersection = turfIntersect(feature1, feature2);
    if (intersection !== null) return true;
}

/**
 * @typedef {Object} Intersection
 * @property {number} area
 * @property {number} percentage
 *
 * Returns the intersected area of both features and the intersected percentage as a percentage of the second feature.
 * @param {Feature} feature1
 * @param {Feature} feature2
 * @returns {?Intersection}
 */
export function intersection(feature1, feature2) {
    try {
        const intersection = turfIntersect(feature1, feature2);
        if (intersection) {
            const intersectedArea = area(intersection);
            const withThisFeatureArea = area(feature2);
            return {
                area: intersectedArea,
                percentage: utils.round((intersectedArea / withThisFeatureArea) * 100, 1),
            };
        }
    } catch {
        return undefined;
    }
}

/**
 * Flattens a feature with a MultiPolygon or GeometryCollection geometry into multiple Polygon features.
 * @param {Feature} feature
 * @param {string[]} hiddenLayers - list of layers to default visible property to false (optional)
 * @returns {Feature[]}
 */
export function flatten(feature, hiddenLayers = []) {
    const flattened = turfFlatten(feature);
    return flattened.features.map((f) => {
        // Remove duplicate points.
        f.geometry.coordinates[0] = f.geometry.coordinates[0].reduce((points, nextPoint, i) => {
            if (i === 0) {
                points.push(nextPoint);
            } else {
                const previousPoint = points[points.length - 1];
                const isDuplicatePoint = previousPoint[0] === nextPoint[0] && previousPoint[1] === nextPoint[1];
                if (!isDuplicatePoint) {
                    points.push(nextPoint);
                }
            }
            return points;
        }, []);

        return {
            ...f,
            id: uuidv4(),
            properties: {
                ...f.properties,
                drawnArea: area(f),
                visible: hiddenLayers.includes(f.properties.layer) ? false : true,
            },
        };
    });
}

/**
 * Returns true if the 2 features are spatially identical
 * @param {Feature} feature1
 * @param {Feature} feature2
 * @returns {boolean}
 */
export function equals(feature1, feature2) {
    return turfEquals(feature1.geometry, feature2.geometry);
}

/**
 * Union an array of GeoJSON features into a single MultiPolygon feature
 * @param {Feature[]} features
 * @returns {Feature}
 */
export function union(features) {
    let unioned;
    features.forEach((f) => {
        if (!unioned) {
            unioned = f;
        } else {
            unioned = turfUnion(unioned, f);
        }
    });
    return unioned;
}

/**
 * Creates a GeoJson feature from a lat/lng point and radius in metres
 * @param {number} lat
 * @param {number} lng
 * @param {number} radiusInMetres
 * @returns {Feature}
 */
export function circle(lat, lng, radiusInMetres) {
    const center = [lng, lat];
    const radiusInKilometres = radiusInMetres / 1000;
    const options = {
        steps: 64,
        units: "kilometers",
    };
    return turfCircle(center, radiusInKilometres, options);
}

/**
 * Returns true if the feature's geometry is a closed linear ring.
 * @param {Feature} feature
 */
export function isClosedLinearRing(feature) {
    return ["Polygon", "MultiPolygon", "GeometryCollection"].includes(feature.geometry.type);
}

/**
 * Strips out the properties we do not wish to store
 * @param {{}} properties
 * @returns {{}} - The sanitized properties
 */
export function sanitizeProperties(properties) {
    const PROPERTIES_WHITELIST = ["layer", "drawnArea", "blockId", "blockName", "irrigatorId", "irrigtorName"];
    const sanitizedProperties = {};
    Object.keys(properties).forEach((key) => {
        if (PROPERTIES_WHITELIST.includes(key)) {
            sanitizedProperties[key] = properties[key];
        }
    });
    return sanitizedProperties;
}

/**
 * Zoom the map to the bounds of the features found by the filter function.
 * @param {google.maps.Data} googleMapData
 * @param {function(Feature):Feature[]} filterFunction
 * @param {number} padding
 * @returns {void}
 */
export function zoomTo(googleMapData, filterFunction, padding = 0) {
    googleMapData.toGeoJson((geoJson) => {
        const filteredFeatures = filterFunction ? geoJson.features.filter(filterFunction) : [];
        const features = filteredFeatures.length > 0 ? filteredFeatures : geoJson.features;
        if (features.length > 0) {
            const bbox = turfBBox({ ...geoJson, features }); // returns [minX, minY, maxX, maxY] or [west, south, east, north]
            const latLngLiteral = { west: bbox[0], south: bbox[1], east: bbox[2], north: bbox[3] };
            googleMapData.getMap().fitBounds(latLngLiteral, padding);
        }
    });
}

/**
 * Returns true if the 2 features are similar based on their intersection
 * @param {Feature} feature1
 * @param {Feature} feature2
 * @param {number} similarityPercentage - the % that determines similarity (optional - default 75%)
 * @returns {boolean}
 */
export function similar(feature1, feature2, similarityPercentage = 75) {
    const intersectionResult = intersection(feature1, feature2);
    if (intersectionResult && intersectionResult.percentage > similarityPercentage) {
        return true;
    }
    return false;
}
