import { useState, useEffect } from "react";
import { Switch, Route } from "react-router-dom";
import moment from "moment";
import Alert from "components/Alert";
import { Panel, PanelBody, PanelTabs } from "components/Panel";
import { useFeatureTracker, FEATURES } from "components/FeatureTracker/FeatureTracker";
import Overview from "containers/Reporting/Overview";
import DistributionGraphs from "containers/Reporting/DistributionGraphs";
import AggregatedReporting from "containers/Reporting/AggregatedReporting/AggregatedReporting";
import ReportingHeatmap from "containers/Reporting/ReportingHeatmap";
import ReportingFarmsMap from "containers/Reporting/ReportingFarmsMap";
import { getAnalysisOptions } from "containers/FarmHome/_utils";
import { useNationalBenchmarkData } from "containers/Reporting/_hooks";
import Filters from "containers/Reporting/Filters";
import { useAuthContext, useRefData } from "common/hooks";

export default function BenchmarkReporting({ farm, isBenchmarksOnly }) {
    const refData = useRefData();
    const isSystemAdmin = useAuthContext()?.isSystemAdmin;
    const budgetOptions = farm && getAnalysisOptions(farm);
    const latestBudget = budgetOptions && budgetOptions.length > 0 && budgetOptions[0];
    const year = latestBudget && latestBudget.year ? latestBudget.year : moment().year() + 1;
    const [criteria, setCriteria] = useState({ rollup: "true", year, benchmarkFarmId: farm && farm.id, benchmarkAnalysisId: latestBudget && latestBudget.value });
    const defaultRule = { type: "Year", condition: "IsSelectedYearOrLatest", values: [String(year)] };
    const [filter, setFilter] = useState({ filterGroups: [{ rules: [{ ...defaultRule }], operator: "And" }], operator: "Or" });
    const { loading: nationalReportingDataLoading, data: nationalReportingData } = useNationalBenchmarkData({ ...criteria, filterSet: { ...filter } });

    const featureTracker = useFeatureTracker(FEATURES.FARM_BENCHMARKS, isBenchmarksOnly ? "Open" : undefined, farm);
    useEffect(() => {
        if (nationalReportingData && featureTracker && isBenchmarksOnly) {
            featureTracker.track("Viewed");
        }
    }, [nationalReportingData, isBenchmarksOnly, featureTracker, farm]);

    const error = nationalReportingData && nationalReportingData.error;
    const hasData = nationalReportingData && nationalReportingData.benchmarkData && nationalReportingData.benchmarkData.length > 0;
    const locatedFarms = nationalReportingData && nationalReportingData.farms ? nationalReportingData.farms.filter((f) => f.longitude && f.latitude) : [];
    const locatedBlocks =
        nationalReportingData && nationalReportingData.farms
            ? nationalReportingData.farms.reduce((results, farm) => {
                  const blocks = farm.blocks.filter((b) => b.longitude && b.latitude);
                  return [...results, ...blocks];
              }, [])
            : [];
    const budgetGroups =
        budgetOptions &&
        budgetOptions.reduce((a, o) => {
            if (!a.find((i) => i.index === o.groupIndex)) a.push({ index: o.groupIndex, label: o.groupLabel });
            return a;
        }, []);

    const handleBudgetChange = (e) => {
        setCriteria({ ...criteria, benchmarkAnalysisId: e.target.value });
    };

    const handleIncludeOutliersChange = (e) => {
        setCriteria({ ...criteria, includeOutliers: e.target.checked ? "true" : "false" });
    };

    const handleDownloadsChange = (e) => {
        setCriteria({ ...criteria, includeFarms: e.target.checked ? "true" : "false" });
    };

    const budgetSelector = budgetOptions && budgetGroups && (
        <div className="u-flex flex-center">
            <div className="Panel-action-label">Analysis:</div>
            <div className="Select Select--slim">
                <select id="benchmarkAnalysisId" onChange={handleBudgetChange} value={criteria.benchmarkAnalysisId}>
                    {budgetGroups.map((group) => (
                        <optgroup key={group.index} label={group.label}>
                            {budgetOptions
                                .filter((option) => option.groupIndex === group.index)
                                .map((option) => (
                                    <option key={option.value} value={option.value}>
                                        {option.text}
                                    </option>
                                ))}
                        </optgroup>
                    ))}
                </select>
            </div>
        </div>
    );

    const includeOutliersCheckbox = (
        <div className="u-flex flex-center">
            <label className="Checkbox Checkbox--slim u-ml-sm" htmlFor="includeOutliers">
                <input id="includeOutliers" className="Checkbox-input" type="checkbox" onChange={handleIncludeOutliersChange} />
                <span className="Checkbox-label">Show outliers</span>
            </label>
        </div>
    );

    const includeDownloadCheckbox = (
        <div className="u-flex flex-center">
            <label className="Checkbox Checkbox--slim u-ml-sm" htmlFor="downloads">
                <input id="downloads" className="Checkbox-input" type="checkbox" onChange={handleDownloadsChange} />
                <span className="Checkbox-label">Enable downloads</span>
            </label>
        </div>
    );

    const baseUrl = "/app/admin/national";
    const tabs = !isBenchmarksOnly && {
        aggregated: { title: "Aggregated", path: `${baseUrl}`, exact: true, show: isSystemAdmin },
        distribution: { title: "Distribution", path: `${baseUrl}/distribution`, exact: true },
        map: { title: "Map view", path: `${baseUrl}/map`, exact: true, show: false },
    };

    const actions = (
        <>
            {isBenchmarksOnly && budgetSelector}
            {!isBenchmarksOnly && isSystemAdmin && includeOutliersCheckbox}
            {!isBenchmarksOnly && isSystemAdmin && includeDownloadCheckbox}
        </>
    );

    const benchmarkInfo = (
        <>
            <p>The benchmark graphs show your farm's results against other farms in OverseerFM. Each graph shows the number of farms (y-axis) that have the result shown on the x-axis. The purple bar indicates your farm's position relative to the other farms.</p>
            <p>You can filter the results by enterprise, council and year using the drop-down menu above.</p>
            <p>
                <b>Note:</b> This benchmarking report provides snapshot information based on year-end analyses that are held in OverseerFM. The information in this report represents a point in time only.
            </p>
        </>
    );

    const aggregatedData = nationalReportingData && nationalReportingData.aggregatedData;

    const farmLookup =
        nationalReportingData && nationalReportingData.farms
            ? nationalReportingData.farms.reduce((d, f) => {
                  d[f.id] = f;
                  return d;
              }, {})
            : undefined;

    return (
        <Panel>
            <PanelTabs waiting={nationalReportingDataLoading} title={isBenchmarksOnly ? "Benchmarks" : undefined} iconClassName="IconLink--stats-bars" tabs={tabs} actions={actions} referrer={farm ? `/app/farm/${farm.id}` : undefined} />
            {!tabs && isBenchmarksOnly && <Alert className="u-mb-sm" type="info" text={benchmarkInfo} />}
            <PanelBody>
                <Filters defaultRule={defaultRule} onFilterChanged={setFilter} filter={filter} exclusions={["Status", "Tag"]} />
            </PanelBody>
            <PanelBody loading={nationalReportingDataLoading && !nationalReportingData} waiting={nationalReportingDataLoading}>
                {!error && hasData && (
                    <>
                        {isSystemAdmin && aggregatedData && !isBenchmarksOnly && <Overview aggregatedData={aggregatedData} farmsWithResultsCount={nationalReportingData.farmsWithResultsCount} waiting={nationalReportingDataLoading} includeLatestYearEnds={true} />}
                        {tabs && (
                            <Switch>
                                <Route
                                    exact
                                    path={tabs.aggregated.path}
                                    render={() => (
                                        <Panel title="Aggregated reporting" className="u-mt-md" loading={nationalReportingDataLoading} skyBlue>
                                            <PanelBody grey>
                                                {isSystemAdmin && aggregatedData && <AggregatedReporting aggregatedData={aggregatedData} farmLookup={farmLookup} farmsWithResultsCount={nationalReportingData.farmsWithResultsCount} refData={refData} />}
                                                {!isSystemAdmin && <p className="lead">There is no data available.</p>}
                                            </PanelBody>
                                        </Panel>
                                    )}
                                />
                                <Route
                                    exact
                                    path={tabs.distribution.path}
                                    render={() => (
                                        <Panel title="Distributions" className="u-mt-md" loading={nationalReportingDataLoading} skyBlue>
                                            <PanelBody grey>
                                                <DistributionGraphs benchmarkData={nationalReportingData.benchmarkData} farm={farm} farmLookup={farmLookup} />
                                            </PanelBody>
                                        </Panel>
                                    )}
                                />
                                <Route
                                    exact
                                    path={tabs.map.path}
                                    render={() => (
                                        <>
                                            <Panel title="Heat map" className="u-mt-md" loading={nationalReportingDataLoading} skyBlue>
                                                <PanelBody grey>
                                                    <ReportingHeatmap locations={locatedFarms} />
                                                </PanelBody>
                                            </Panel>
                                            <Panel title="Farms" className="u-mt-md" loading={nationalReportingDataLoading} skyBlue>
                                                <PanelBody grey>
                                                    <ReportingFarmsMap locations={locatedFarms} />
                                                </PanelBody>
                                            </Panel>
                                            <Panel title="Blocks" className="u-mt-md" loading={nationalReportingDataLoading} skyBlue>
                                                <PanelBody grey>
                                                    <ReportingFarmsMap locations={locatedBlocks} />
                                                </PanelBody>
                                            </Panel>
                                        </>
                                    )}
                                />
                            </Switch>
                        )}
                        {!tabs && isBenchmarksOnly && (
                            <PanelBody grey>
                                <DistributionGraphs benchmarkData={nationalReportingData.benchmarkData} farm={farm} />
                            </PanelBody>
                        )}
                    </>
                )}
                {!error && !hasData && <div className="Tile-body-message">{!isSystemAdmin && nationalReportingData && nationalReportingData.farmCount && nationalReportingData.farmCount < 20 ? <p className="lead">There are not enough farms to give valid results.</p> : <p className="lead">There is no data for the current filter. Please adjust your filter and try again.</p>}</div>}
                {error && (
                    <div className="Tile-body-message">
                        <i className="icon icon--md icon-alert u-textError" />
                        <h3 className="u-textError">{nationalReportingData.error}</h3>
                    </div>
                )}
            </PanelBody>
        </Panel>
    );
}
