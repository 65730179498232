import { Form, Field } from "react-final-form";
import { FORM_ERROR } from "final-form";
import * as FormUtils from "common/FormUtils";
import { useAuthContext, useModal, useRefData } from "common/hooks";
import Modal from "components/Modal/Modal";
import ModalBody from "components/Modal/ModalBody";
import ModalFooter from "components/Modal/ModalFooter";
import ModalFooterLeft from "components/Modal/ModalFooterLeft";
import ModalFooterRight from "components/Modal/ModalFooterRight";
import Button from "components/Button/Button";
import { Grid, GridCell } from "components/Grid";
import SelectField from "components/FormFields/SelectField";
import TextareaField from "components/FormFields/TextareaField";
import YesNoRadioGroupField from "components/FormFields/YesNoRadioGroupField";
import { useUpdateAnalysisAsync } from "containers/hooks";

export default function AnalysisCommentModal({ analysis, comment, close }) {
    const refData = useRefData();
    const updateAnalysisAsync = useUpdateAnalysisAsync(analysis, "comments");

    const handleCommentTypeChanged = (form, values) => (commentType) => {
        const includeInFarmImpactReport = commentType === "Actions" || values.includeInFarmImpactReportYesNo === "Yes";
        form.change("includeInFarmImpactReportYesNo", includeInFarmImpactReport ? "Yes" : "No");
        form.change("includeInFarmImpactReport", includeInFarmImpactReport);
    };

    const validate = async (values) => {
        const validation = {};

        validation.type = FormUtils.validators.required(values.type);
        validation.includeInFarmImpactReportYesNo = FormUtils.validators.required(values.includeInFarmImpactReportYesNo);
        validation.comment = FormUtils.validators.required(values.comment);
        validation.comment = validation.comment || FormUtils.validators.maxLength(2000)(values.comment);

        return validation;
    };

    const submit = async (values) => {
        values.includeInFarmImpactReport = values.includeInFarmImpactReportYesNo === "Yes";
        values.comment.trim();
        delete values.isNew;

        // Update if editing.
        const updatedAnalysis = {
            ...analysis,
            comments: analysis.comments.map((c) => {
                if (c.id !== values.id) return c;
                return values;
            }),
        };

        // Add if adding.
        if (!updatedAnalysis.comments.some((c) => c.id === values.id)) {
            updatedAnalysis.comments.push(values);
        }

        try {
            await updateAnalysisAsync(updatedAnalysis);
            close();
        } catch (error) {
            return { [FORM_ERROR]: error };
        }
    };

    return (
        <Form initialValues={comment} validate={validate} onSubmit={submit}>
            {({ form, values, handleSubmit, submitting, submitError }) => {
                return (
                    <form onSubmit={handleSubmit}>
                        <Modal title={`${values.isNew ? "Add" : "Edit"} comment`} close={close} submitting={submitting} wide allowOffline fluid>
                            <ModalBody error={submitError}>
                                <Grid>
                                    <GridCell className="u-lg-width1of3">
                                        <Field name="id" type="hidden" component="input" />
                                        <Field name="type" label="Type of comment" options={refData.budgetCommentTypes} onChange={handleCommentTypeChanged(form, values)} component={SelectField} />
                                    </GridCell>
                                    <GridCell className="u-lg-width1of3">
                                        <Field name="includeInFarmImpactReportYesNo" label="Include in farm impact report?" disabled={values.type === "Actions"} inline type="radio" component={YesNoRadioGroupField} />
                                    </GridCell>
                                    <GridCell>
                                        <Field name="comment" label="Comment" placeholder="Add comment..." rows="12" component={TextareaField} />
                                    </GridCell>
                                </Grid>
                            </ModalBody>
                            <ModalFooter>
                                <ModalFooterLeft>
                                    <Button id="cancel" onClick={close} secondary disabled={submitting}>
                                        Cancel
                                    </Button>
                                </ModalFooterLeft>
                                <ModalFooterRight>
                                    <Button id="submit" submit primary waiting={submitting} disabled={submitting}>
                                        Save
                                    </Button>
                                </ModalFooterRight>
                            </ModalFooter>
                        </Modal>
                    </form>
                );
            }}
        </Form>
    );
}

export function useAnalysisCommentModal(analysis) {
    const authContext = useAuthContext();
    const [modal, openModal] = useModal(AnalysisCommentModal);

    const openAnalysisCommentModal = (comment) => {
        if (!comment.createdBy) {
            comment.createdBy = {
                userId: authContext.userId,
                userFullName: authContext.fullName,
                accountId: authContext.accountId,
            };
        }

        comment.includeInFarmImpactReport = comment.includeInFarmImpactReport || false;
        comment.includeInFarmImpactReportYesNo = comment.includeInFarmImpactReport || comment.type === "Actions" ? "Yes" : "No";

        const modalProps = {
            analysis,
            comment,
        };
        openModal(modalProps);
    };

    return [modal, openAnalysisCommentModal];
}
