import React, { useState } from "react";
import { Link } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import moment from "moment";
import { compose, withActions, withAppContext } from "containers/HOC";
import * as utils from "common/utils";
import { useModal } from "common/effects";
import { Panel, PanelBody } from "components/Panel";
import { Grid, GridCell } from "components/Grid";
import ActionLink from "components/ActionLink";
import ProgressBar from "components/ProgressBar";
import UpgradeRunWorker from "./UpgradeRunWorker";
import Alert from "components/Alert";
import CreateUpgradeRunModal from "./CreateUpgradeRunModal";
import { Bar, Scatter } from "react-chartjs-2";
import { getAnalysisUpgradeRuns, deleteAnalysisUpgradeRun, downloadAnalysisUpgradeResults, downloadAnalysisBlocksUpgradeResults, getUpgradeRunRawResultsSummary } from "../_actions";

let pickedUp = [];
let sent = [];
let duplicates = [];

const UpgradeRuns = ({ getAnalysisUpgradeRuns, deleteAnalysisUpgradeRun, downloadAnalysisUpgradeResults, downloadAnalysisBlocksUpgradeResults, getUpgradeRunRawResultsSummary }) => {
    const { openModal, getModal } = useModal();
    const [upgradeRuns, setUpgradeRuns] = useState();
    const [waiting, setWaiting] = useState(false);
    const [rawSummaryResults, setRawSummaryResults] = useState(false);

    const getUpgradeRuns = React.useCallback(() => {
        setWaiting(true);
        getAnalysisUpgradeRuns()
            .then((response) => {
                const runs = response.map((r) => {
                    const exceptions = r.entities
                        .filter((a) => a.exception)
                        .map((a) => ({
                            farmId: a.farmId,
                            budgetBaseId: a.budgetBaseId,
                            details: a.exception,
                        }));
                    return { ...r, workers: [], completedSinceStart: 0, completedSinceLastMinute: r.completed || 0, previousMinuteCounter: 0, minuteCounter: 0, view: "workers", exceptions };
                });
                setUpgradeRuns(runs.sort((a, b) => (a.name < b.name ? 1 : -1)));
            })
            .catch((e) => {
                console.log(e);
                setUpgradeRuns([]);
            })
            .finally(() => setWaiting(false));
    }, [getAnalysisUpgradeRuns]);

    React.useEffect(() => {
        getUpgradeRuns();
    }, [getUpgradeRuns]);

    const setTimers = React.useCallback(() => {
        setUpgradeRuns((prevState) => {
            const updatedState = prevState.map((run) => {
                if (run.workers.length === 0) return run;

                const completedSinceStart = run.completed - run.startCount;
                const minuteCounter = run.completed - run.completedSinceLastMinute;

                return {
                    ...run,
                    completedSinceStart,
                    minuteCounter,
                    previousMinuteCounter: run.minuteCounter,
                    completedSinceLastMinute: run.completed,
                };
            });
            return updatedState;
        });
    }, []);

    React.useEffect(() => {
        const interval = setInterval(() => {
            setTimers();
        }, 60000);
        return () => clearInterval(interval);
    }, [setTimers]);

    //React.useEffect(() => {
    //    upgradeRuns && upgradeRuns.forEach(run => {
    //        if (run.remaining > 0 && run.workers.length === 0 && duplicates.length === 0) {
    //            pickedUp = [];
    //            sent = [];
    //        }
    //    });
    //}, [upgradeRuns])

    const createRun = () => {
        openModal("CreateUpgradeRunModal", {}, getUpgradeRuns);
    };
    const createUpgradeRunModal = getModal("CreateUpgradeRunModal");

    const deleteRun = (run) => () => {
        const confirmed = window.confirm(`Are you sure you wanna delete the ${run.name} run?`);
        if (confirmed) {
            setWaiting(true);
            deleteAnalysisUpgradeRun(run.id)
                .then(() => getUpgradeRuns())
                .catch((e) => console.log(e));
        }
    };

    const downloadCsv = (csvContent, fileName) => {
        var blob = new Blob([csvContent], { type: "text/csv" });
        var url = URL.createObjectURL(blob);
        var link = document.createElement("a");
        link.setAttribute("href", url);
        link.setAttribute("download", fileName);
        document.body.appendChild(link);
        link.click();
    };

    const downloadResults = (run) => () => {
        downloadAnalysisUpgradeResults(run.id)
            .then((response) => downloadCsv(response, run.name + "-results.csv"))
            .catch((e) => console.log(e));
    };

    const downloadRawResults = (run) => () => {
        downloadAnalysisUpgradeResults(run.id, "raw")
            .then((response) => downloadCsv(response, run.name + "-raw-results.csv"))
            .catch((e) => console.log(e));
    };

    const downloadRawBlockResults = (run) => () => {
        downloadAnalysisBlocksUpgradeResults(run.id, "raw")
            .then((response) => downloadCsv(response, run.name + "-raw-block-results.csv"))
            .catch((e) => console.log(e));
    };

    const downloadCount = (run) => () => {
        downloadAnalysisUpgradeResults(run.id, "count")
            .then((response) => downloadCsv(response, run.name + "-count.csv"))
            .catch((e) => console.log(e));
    };

    const rawResultsAnalysisSummary = (id, before, after, attribute, graphType) => () => {
        if (rawSummaryResults && rawSummaryResults.id === id) {
            setRawSummaryResults({ ...rawSummaryResults, before, after, attribute, graphType });
        } else {
            setRawSummaryResults(false);
            getUpgradeRunRawResultsSummary(id)
                .then((data) => {
                    //Test
                    //const filteredData = data.filter((d) => d.isDeletedYearEnd === false);
                    setRawSummaryResults({ data, id, before, after, attribute, graphType });
                })
                .catch((e) => console.log(e));
        }
    };

    const getNextId = (run) => {
        const nextAnalysis = run.entities.find((a) => !a.workerId && !a.completed && (run.farmsOnly ? !pickedUp.includes(a.farmId) : !pickedUp.includes(a.budgetBaseId)));
        if (nextAnalysis) {
            if (run.farmsOnly) {
                pickedUp.push(nextAnalysis.farmId);
                return nextAnalysis.farmId;
            } else {
                pickedUp.push(nextAnalysis.budgetBaseId);
                return nextAnalysis.budgetBaseId;
            }
        }
    };

    const addWorker = (runId) => () => {
        setUpgradeRuns((prevState) => {
            const updatedState = prevState.map((run) => {
                if (run.id !== runId) return run;

                const nextId = getNextId(run);
                if (!nextId) return run;

                const startMoment = run.workers.length === 0 ? moment() : run.startMoment;
                const startCount = run.workers.length === 0 ? run.completed : run.startCount;

                const nextWorkerNumber = run.workers.reduce((prev, current) => (prev.number > current.number ? prev.number : current.number), 0) + 1;
                const newWorker = { id: uuidv4(), farmsOnly: run.farmsOnly, entityId: nextId, counter: 0, number: nextWorkerNumber };

                return {
                    ...run,
                    workers: [...run.workers, newWorker],
                    entities: run.entities.map((e) => {
                        if (run.farmsOnly) {
                            if (e.farmId === nextId) return { ...e, workerId: newWorker.id };
                        } else {
                            if (e.budgetBaseId === nextId) return { ...e, workerId: newWorker.id };
                        }

                        return e;
                    }),
                    startMoment,
                    startCount,
                };
            });
            return updatedState;
        });
    };

    const removeWorker = (runId) => () => {
        setUpgradeRuns((prevState) => {
            const updatedState = prevState.map((run) => {
                if (run.id !== runId) return run;

                if (run.workers.length === 0) return run;

                const workerToRemove = run.workers[run.workers.length - 1];

                return {
                    ...run,
                    workers: run.workers.map((worker) => {
                        if (worker.entityId === workerToRemove.entityId) return { ...worker, shutdownWhenDone: true };

                        return worker;
                    }),
                };
            });
            return updatedState;
        });
    };

    const deleteWorker = (entityId) => {
        setUpgradeRuns((prevState) => {
            const updatedState = prevState.map((run) => {
                if (run.workers.length === 0) return run;

                return {
                    ...run,
                    workers: run.workers.map((worker) => {
                        if (worker.entityId === entityId) return { ...worker, shutdownWhenDone: true };

                        return worker;
                    }),
                };
            });
            return updatedState;
        });
    };

    const onWorkerSuccess = React.useCallback((runId, worker) => {
        setUpgradeRuns((prevState) => {
            const updatedState = prevState.map((run) => {
                if (run.id !== runId) return run;

                const updatedRun = {
                    ...run,
                    succeeded: worker.shutdownWhenDone ? run.succeeded : run.succeeded + 1,
                    completed: worker.shutdownWhenDone ? run.completed : run.completed + 1,
                    remaining: worker.shutdownWhenDone ? run.remaining : run.remaining - 1,
                    entities: run.entities.map((e) => {
                        if (run.farmsOnly) {
                            if (e.farmId === worker.entityId) return { ...e, completed: true };
                        } else {
                            if (e.budgetBaseId === worker.entityId) return { ...e, completed: true };
                        }

                        return e;
                    }),
                };

                if (updatedRun.remaining === 0 && !updatedRun.endMoment) {
                    updatedRun.endMoment = moment();
                    const duration = moment.duration(updatedRun.endMoment.diff(updatedRun.startMoment));
                    updatedRun.minutesSinceStart = utils.round(duration.asMinutes(), 0);
                    updatedRun.avgPerMinute = utils.round(updatedRun.completedSinceStart / duration.asMinutes(), 0);
                }

                const nextId = getNextId(run);

                if (!nextId || worker.shutdownWhenDone) {
                    updatedRun.workers = run.workers.filter((wrkr) => wrkr.id !== worker.id);
                } else {
                    updatedRun.workers = run.workers.map((wrkr) => {
                        if (wrkr.id !== worker.id) return wrkr;

                        return {
                            ...wrkr,
                            entityId: nextId,
                            counter: wrkr.counter + 1,
                        };
                    });
                    updatedRun.entities = updatedRun.entities.map((e) => {
                        if (updatedRun.farmsOnly) {
                            if (e.farmId === nextId) return { ...e, workerId: worker.id };
                        } else {
                            if (e.budgetBaseId === nextId) return { ...e, workerId: worker.id };
                        }
                        return e;
                    });
                }

                return updatedRun;
            });
            return updatedState;
        });
    }, []);

    const onWorkerException = React.useCallback((runId, entityId, exception) => {
        setUpgradeRuns((prevState) => {
            const updatedState = prevState.map((run) => {
                if (run.id !== runId) return run;

                const entities = run.entities.map((e) => {
                    if (run.farmsOnly) {
                        if (e.farmId !== entityId) return e;
                    } else {
                        if (e.budgetBaseId !== entityId) return e;
                    }

                    return {
                        ...e,
                        exception: exception.details,
                        failed: true,
                        completed: true,
                    };
                });

                return {
                    ...run,
                    exceptions: [...run.exceptions, exception],
                    workers: run.workers.filter((worker) => worker.entityId !== entityId),
                    remaining: run.remaining - 1,
                    failed: run.failed + 1,
                    entities,
                };
            });
            return updatedState;
        });
    }, []);

    const setActiveTab = (runId, view) => () => {
        const updatedState = upgradeRuns.map((run) => {
            if (run.id !== runId) return run;

            return {
                ...run,
                view,
            };
        });
        setUpgradeRuns(updatedState);
    };

    const options = {
        scales: {
            x: {
                title: {
                    display: true,
                    text: "After Run",
                },
            },
            y: {
                title: {
                    display: true,
                    text: "Before Run",
                },
            },
        },
    };

    const graphData =
        rawSummaryResults &&
        rawSummaryResults.graphType === "Scatter" &&
        rawSummaryResults.data
            .filter((d) => d.isDeletedYearEnd === false)
            .map((l) => {
                return {
                    x: l[rawSummaryResults.before],
                    y: l[rawSummaryResults.after],
                    label: "Placeholder",
                };
            });

    const scatterData = {
        datasets: [
            {
                label: "Before and after run results",
                data: graphData,
                //backgroundColor: "rgba(255, 99, 132, 1)",
                backgroundColor: rawSummaryResults.attribute === "nLossPercentage" ? "#44aa6c" : rawSummaryResults.attribute === "pLossPercentage" ? "#e39153" : "#4285f4",
            },
        ],
    };

    const getNPercentageBarValue = (start, finish) => rawSummaryResults && rawSummaryResults.data && rawSummaryResults.data.filter((l) => l.nLossPercentage > start && l.nLossPercentage <= finish && l.isDeletedYearEnd === false).length;
    const getPPercentageBarValue = (start, finish) => rawSummaryResults && rawSummaryResults.data && rawSummaryResults.data.filter((l) => l.pLossPercentage > start && l.pLossPercentage <= finish && l.isDeletedYearEnd === false).length;
    const getGhgPercentageBarValue = (start, finish) => rawSummaryResults && rawSummaryResults.data && rawSummaryResults.data.filter((l) => l.ghgPercentage > start && l.ghgPercentage <= finish && l.isDeletedYearEnd === false).length;

    const dataRanges = [
        [-1002, -998],
        [-998, -50],
        [-50, -40],
        [-40, -30],
        [-30, -20],
        [-20, -10],
        [-10, -5],
        [-5, -0.1],
        [-0.1, 0.1],
        [0.1, 5],
        [5, 10],
        [10, 20],
        [20, 30],
        [30, 40],
        [40, 50],
        [50, 998],
        [998, 1002],
    ];

    const barData = rawSummaryResults.graphType === "Bar" && {
        labels: ["A result to no result", "Less than -50", "-40 to -50", "-30 to -40", "-20 to -30", "-10 to -20", "-5 to -10", "0 to -5", "0", "0 to 5", "5 to 10", "10 to 20", "20 to 30", "30 to 40", "40 to 50", "Greater than 50", "No result to a result"],
        datasets: [
            {
                label: "% change in N",
                data: dataRanges.map((v) => getNPercentageBarValue(v[0], v[1])),
                backgroundColor: "#44aa6c",
            },
            {
                label: "% change in P",
                data: dataRanges.map((v) => getPPercentageBarValue(v[0], v[1])),
                backgroundColor: "#e39153",
            },
            {
                label: "% change in GHG",
                data: dataRanges.map((v) => getGhgPercentageBarValue(v[0], v[1])),
                backgroundColor: "#4285f4",
            },
        ],
    };

    const barChartOptions = {
        scales: {
            y: {
                title: {
                    display: true,
                    text: "Number of Analyses",
                    fontSize: 40,
                },
            },
            x: {
                ticks: {
                    display: false,
                },
            },
        },
    };

    const tableData =
        (rawSummaryResults.graphType === "IncreaseTable" || rawSummaryResults.graphType === "DecreaseTable") &&
        rawSummaryResults &&
        rawSummaryResults.data &&
        rawSummaryResults.data
            .filter((l) => l[rawSummaryResults.attribute] && l[rawSummaryResults.attribute] !== 1000 && l[rawSummaryResults.attribute] !== -1000 && l.isDeletedYearEnd === false)
            .sort((a, b) => (rawSummaryResults.graphType === "IncreaseTable" ? (Number(a[rawSummaryResults.attribute]) < Number(b[rawSummaryResults.attribute]) ? 1 : -1) : Number(a[rawSummaryResults.attribute]) > Number(b[rawSummaryResults.attribute]) ? 1 : -1))
            .slice(0, 50);

    const lossGainResultsData =
        (rawSummaryResults.graphType === "NoResult" || rawSummaryResults.graphType === "Result") &&
        rawSummaryResults &&
        rawSummaryResults.data &&
        rawSummaryResults.data
            .filter((l) => (rawSummaryResults.graphType === "NoResult" ? l[rawSummaryResults.attribute] === -1000 : l[rawSummaryResults.attribute] === 1000) && l.isDeletedYearEnd === false)
            //.sort((a, b) => (rawSummaryResults.graphType === "NoResult" && (Number(a[rawSummaryResults.attribute]) && ? (Number(a[rawSummaryResults.attribute]) && Number(b[rawSummaryResults.attribute]) ? 1 : -1) : Number(a[rawSummaryResults.attribute]) > Number(b[rawSummaryResults.attribute]) ? 1 : -1))
            .slice(0, 50);

    return (
        <>
            <Panel
                title="Upgrade runs"
                green
                loading={!upgradeRuns}
                waiting={waiting}
                actions={
                    <ActionLink id="create-run" className="IconLink--arrow-plus u-textWhite" onClick={createRun}>
                        Create upgrade run
                    </ActionLink>
                }
            >
                <PanelBody loading={!upgradeRuns}>
                    <button onClick={() => console.log(pickedUp.length)}>Picked up</button>
                    <button onClick={() => console.log(sent.length)}>Sent</button>
                    <button onClick={() => console.log(duplicates)}>Duplicates</button>
                    <button
                        onClick={() => {
                            sent = [];
                            duplicates = [];
                            pickedUp = [];
                        }}
                    >
                        Clear
                    </button>
                    {upgradeRuns && upgradeRuns.length === 0 && (
                        <div className="Tile-body-message">
                            <p className="lead">No data</p>
                        </div>
                    )}
                    {upgradeRuns &&
                        upgradeRuns.length > 0 &&
                        upgradeRuns.map((run) => {
                            let progress = utils.round((run.completed / run.total) * 100, 0);
                            if (progress === 100 && run.remaining > 0) progress = 99;

                            const minutesSinceStart = run.minutesSinceStart || moment().diff(run.startMoment, "minutes");
                            const avgPerMinute = run.avgPerMinute || (minutesSinceStart < 1 ? null : utils.round(run.completedSinceStart / minutesSinceStart, 0));
                            const endMoment = run.endMoment || (minutesSinceStart < 1 ? null : moment().add(utils.round(run.remaining / avgPerMinute, 0), "minutes"));

                            const datasetFilters = [];
                            if (run.activeSubscriptionsOnly) datasetFilters.push("Active subscriptions only");
                            if (run.excludePublications) datasetFilters.push("Exclude publications");
                            if (run.yearEndsOnly) datasetFilters.push("Year ends only");
                            if (run.farmsOnly) datasetFilters.push("Farm upgrade");
                            if (run.includeDeletedYearEnds) datasetFilters.push("Include deleted year ends");
                            if (run.cropsOnly) datasetFilters.push("Only analyses with crops/fodder crops");
                            if (run.nonCropsOnly) datasetFilters.push("Exclude analyses with crops/fodder crops");
                            if (run.exportedMaizeSilageOnly) datasetFilters.push("Only analyses with exported maize silage");
                            if (run.dairyOnly) datasetFilters.push("Only analyses with dairy");
                            if (run.applyEcoPond) datasetFilters.push("Apply EcoPond");

                            const runOptions = [];
                            if (run.runDataFixes) runOptions.push("Run data fixes");
                            if (run.runModel) runOptions.push("Run model");
                            if (run.refreshAnalysisSummary) runOptions.push("Refresh analysis summary");
                            if (run.updateBudgetBase) runOptions.push("Update budget/farm");
                            if (run.includeBlockResults) runOptions.push("Include Block Results");
                            else runOptions.push("Compare using CURRENT results");

                            return (
                                <div style={{ paddingTop: "20px" }}>
                                    <Panel title={run.name + " - Upgrade Run"} blue>
                                        <div key={run.id} id={run.id} className="FarmTable_wrapper u-mb-md">
                                            <div className="u-p-10" style={{ backgroundColor: "rgba(80, 155, 194, 0.1)" }}>
                                                <table style={{ width: "100%" }}>
                                                    <tbody>
                                                        <tr>
                                                            <td rowSpan="2" data-width="25">
                                                                <div className="h5 u-text-xl">{run.name}</div>
                                                            </td>
                                                            <td data-width="5" className="h5">
                                                                Total
                                                            </td>
                                                            <td data-width="5" className="h5">
                                                                Succeeded
                                                            </td>
                                                            <td data-width="5" className="h5">
                                                                Failed
                                                            </td>
                                                            <td data-width="5" className="h5">
                                                                Remaning
                                                            </td>
                                                            <td data-width="5" className="h5">
                                                                Start time
                                                            </td>
                                                            <td data-width="5" className="h5">
                                                                End time
                                                            </td>
                                                            <td data-width="5" className="h5">
                                                                Mins
                                                            </td>
                                                            <td data-width="5" className="h5">
                                                                Avg/min
                                                            </td>
                                                            <td data-width="5" className="h5">
                                                                Prev min
                                                            </td>
                                                            <td data-width="5" className="h5">
                                                                This min
                                                            </td>
                                                            <td data-width="15" className="h5">
                                                                Progress
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td className="u-textBold">{run.total.toLocaleString()}</td>
                                                            <td className={`u-textBold ${run.succeeded > 0 ? "u-textSuccess" : ""}`}>{run.succeeded ? run.succeeded.toLocaleString() : "-"}</td>
                                                            <td className={`u-textBold ${run.failed > 0 ? "u-textError" : ""}`}>{run.failed ? run.failed.toLocaleString() : "-"}</td>
                                                            <td className={`u-textBold ${run.remaining > 0 ? "u-textWarning" : ""}`}>{run.remaining ? run.remaining.toLocaleString() : "-"}</td>
                                                            <td>{run.startMoment ? run.startMoment.format("HH:mm a") : "-"}</td>
                                                            <td>{endMoment ? endMoment.format("HH:mm a") : "-"}</td>
                                                            <td>{minutesSinceStart ? minutesSinceStart.toLocaleString() : "-"}</td>
                                                            <td>{avgPerMinute ? avgPerMinute.toLocaleString() : "-"}</td>
                                                            <td>{run.previousMinuteCounter ? run.previousMinuteCounter.toLocaleString() : "-"}</td>
                                                            <td className={`u-textBold ${run.previousMinuteCounter < run.minuteCounter ? "u-textSuccess" : "u-textError"}`}>{run.minuteCounter ? run.minuteCounter.toLocaleString() : "-"}</td>
                                                            <td>
                                                                <ProgressBar colour="#36975c" progress={progress} endLabel={`${progress}%`} showZero noPadLeft />
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                            <div className="ActionsBar">
                                                <div className="ActionsBar-left"></div>
                                                <div className="ActionsBar-right">
                                                    {false && run.workers.length === 0 && (
                                                        <ul className="ActionsBar-links">
                                                            <li>
                                                                <ActionLink id={`delete-${run.id}`} onClick={deleteRun(run)} className="IconLink--trash">
                                                                    Delete run
                                                                </ActionLink>
                                                            </li>
                                                        </ul>
                                                    )}
                                                    <ul className="ActionsBar-links">
                                                        {run.workers.length === 0 && (
                                                            <li>
                                                                <ActionLink id={`delete-${run.id}`} onClick={deleteRun(run)} className="IconLink--trash">
                                                                    Delete run
                                                                </ActionLink>
                                                            </li>
                                                        )}
                                                        {run.succeeded > 0 && (
                                                            <li>
                                                                <ActionLink id={`download-${run.id}`} onClick={downloadCount(run)} className="IconLink--download">
                                                                    Download count
                                                                </ActionLink>
                                                            </li>
                                                        )}
                                                        {run.succeeded > 0 && (
                                                            <li>
                                                                <ActionLink id={`download-${run.id}`} onClick={downloadResults(run)} className="IconLink--download">
                                                                    Download results
                                                                </ActionLink>
                                                            </li>
                                                        )}
                                                        {run.succeeded > 0 && (
                                                            <li>
                                                                <ActionLink id={`download-raw-${run.id}`} onClick={downloadRawResults(run)} className="IconLink--download">
                                                                    Download raw results
                                                                </ActionLink>
                                                            </li>
                                                        )}
                                                        {run.succeeded > 0 && (
                                                            <li>
                                                                <ActionLink id={`download-raw-block-${run.id}`} onClick={downloadRawBlockResults(run)} className="IconLink--download">
                                                                    Download raw block results
                                                                </ActionLink>
                                                            </li>
                                                        )}
                                                        {run.remaining > 0 && (
                                                            <li>
                                                                <ActionLink id={`remove-worker-${run.id}`} onClick={removeWorker(run.id)} className="IconLink--minus">
                                                                    Remove worker
                                                                </ActionLink>
                                                            </li>
                                                        )}
                                                        {run.remaining > 0 && (
                                                            <li>
                                                                <ActionLink id={`add-worker-${run.id}`} onClick={addWorker(run.id)} className="IconLink--arrow-plus">
                                                                    Add worker
                                                                </ActionLink>
                                                            </li>
                                                        )}
                                                    </ul>
                                                    {false && run.remaining === 0 && <span className="IconLink--tick-circle u-textSuccess"></span>}
                                                </div>
                                            </div>
                                            {(run.workers.length >= 0 || run.exceptions.length >= 0) && (
                                                <div className="u-p-10 u-pt-0 u-pl-lg">
                                                    <ul className="SubMenu">
                                                        <li className={`SubMenu-item ${run.view === "workers" ? "is-active" : ""}`}>
                                                            <ActionLink onClick={setActiveTab(run.id, "workers")}>{`Workers (${run.workers.length})`}</ActionLink>
                                                        </li>
                                                        <li className={`SubMenu-item ${run.view === "exceptions" ? "is-active" : ""}`}>
                                                            <ActionLink onClick={setActiveTab(run.id, "exceptions")} className={run.exceptions.length > 0 ? "u-textError" : ""}>{`Exceptions (${run.exceptions.length})`}</ActionLink>
                                                        </li>
                                                        <li className={`SubMenu-item ${run.view === "options" ? "is-active" : ""}`}>
                                                            <ActionLink onClick={setActiveTab(run.id, "options")}>Run details</ActionLink>
                                                        </li>
                                                    </ul>
                                                    {run.view === "workers" && (
                                                        <div className="HeroPanel u-flex u-flexWrap u-mt-md">
                                                            {run.workers.length === 0 && <p>{run.remaining > 0 ? "No workers" : "No more work to do"}</p>}
                                                            {run.workers.length > 0 &&
                                                                run.workers.map((worker, workerIndex) => {
                                                                    return <UpgradeRunWorker duplicates={duplicates} sent={sent} key={`${run.id}-${worker.id}`} index={workerIndex + 1} runId={run.id} worker={worker} onSuccess={onWorkerSuccess} onException={onWorkerException} deleteWorker={deleteWorker} />;
                                                                })}
                                                        </div>
                                                    )}
                                                    {run.view === "exceptions" && (
                                                        <div className="HeroPanel u-mt-md">
                                                            {run.exceptions.length === 0 && <p>No exceptions</p>}
                                                            {run.exceptions.length > 0 && (
                                                                <div className="Table u-mt-sm">
                                                                    <table>
                                                                        <thead>
                                                                            <tr>
                                                                                <th data-width="25">Farm Id</th>
                                                                                <th data-width="25">Budget Base Id</th>
                                                                                <th data-width="25">Worker Id</th>
                                                                                <th data-width="10">Http status</th>
                                                                                <th>Exception</th>
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                            {run.exceptions.map((exception, i) => {
                                                                                const key = `${run.id}-exception-${i}`;
                                                                                return (
                                                                                    <tr key={key} id={key}>
                                                                                        <td valign="top">{exception.farmId}</td>
                                                                                        <td valign="top">{exception.budgetBaseId}</td>
                                                                                        <td valign="top">{exception.workerId}</td>
                                                                                        <td valign="top">{exception.httpStatus}</td>
                                                                                        <td valign="top" className="u-textError u-textBold">
                                                                                            <div className="Table Table-Compressed">
                                                                                                <table>
                                                                                                    <tbody>
                                                                                                        {Object.keys(exception.details).map((key) => {
                                                                                                            const detail = exception.details[key];
                                                                                                            const isObject = typeof detail === "object" && detail !== null;
                                                                                                            if (detail && !isObject) {
                                                                                                                return (
                                                                                                                    <tr key={key}>
                                                                                                                        <th>{key}</th>
                                                                                                                        <td>{detail}</td>
                                                                                                                    </tr>
                                                                                                                );
                                                                                                            } else {
                                                                                                                return null;
                                                                                                            }
                                                                                                        })}
                                                                                                    </tbody>
                                                                                                </table>
                                                                                            </div>
                                                                                        </td>
                                                                                    </tr>
                                                                                );
                                                                            })}
                                                                        </tbody>
                                                                    </table>
                                                                </div>
                                                            )}
                                                        </div>
                                                    )}
                                                    {run.view === "options" && (
                                                        <div className="HeroPanel u-mt-md">
                                                            <Grid>
                                                                <GridCell className="u-width1of3">
                                                                    <h5 className="u-mb-md">Dataset filters</h5>
                                                                    <ul className="disc u-pl-md">
                                                                        {datasetFilters.length > 0 && datasetFilters.map((option) => <li key={option}>{option}</li>)}
                                                                        {datasetFilters.length === 0 && <li>No filters selected</li>}
                                                                    </ul>
                                                                </GridCell>
                                                                <GridCell className="u-width1of3">
                                                                    <h5 className="u-mb-md">Run options</h5>
                                                                    <ul className="disc u-pl-md">
                                                                        {runOptions.length > 0 && runOptions.map((option) => <li key={option}>{option}</li>)}
                                                                        {runOptions.length === 0 && <li>No options selected</li>}
                                                                    </ul>
                                                                </GridCell>
                                                                <GridCell className="u-width1of3">
                                                                    <h5 className="u-mb-md">Model features</h5>
                                                                    <ul className="disc u-pl-md">
                                                                        {(run.modelFeatures || []).length > 0 && run.modelFeatures.map((option) => <li key={option}>{option}</li>)}
                                                                        {(run.modelFeatures || []).length === 0 && <li>No features selected</li>}
                                                                    </ul>
                                                                </GridCell>
                                                            </Grid>
                                                        </div>
                                                    )}
                                                    {
                                                        <div>
                                                            {run.succeeded > 0 && (
                                                                <div>
                                                                    <Panel title={"Results - Graphs and Tables ('" + run.name + "' Upgrade Run)"} skyBlue>
                                                                        <div className="u-p-10 u-pt-0 u-pl-lg">
                                                                            <ul className="SubMenu">
                                                                                <li className={`SubMenu-item ${rawSummaryResults.before === "nLossBefore" || rawSummaryResults.before === "nLossPerHaBefore" ? "is-active" : ""}`}>
                                                                                    <ActionLink id={`nLossTotal-${run.id}`} onClick={rawResultsAnalysisSummary(run.id, "nLossBefore", "nLossAfter", "nLossPercentage", "Scatter")}>
                                                                                        Nitrogen
                                                                                    </ActionLink>
                                                                                </li>
                                                                                <li className={`SubMenu-item ${rawSummaryResults.before === "pLossBefore" || rawSummaryResults.before === "pLossPerHaBefore" ? "is-active" : ""}`}>
                                                                                    <ActionLink id={`pLossTotal-${run.id}`} onClick={rawResultsAnalysisSummary(run.id, "pLossBefore", "pLossAfter", "pLossPercentage", "Scatter")}>
                                                                                        Phosporus
                                                                                    </ActionLink>
                                                                                </li>
                                                                                <li className={`SubMenu-item ${rawSummaryResults.before === "ghgBefore" || rawSummaryResults.before === "ghgPerHaBefore" ? "is-active" : ""}`}>
                                                                                    <ActionLink id={`ghgTotal-${run.id}`} onClick={rawResultsAnalysisSummary(run.id, "ghgBefore", "ghgAfter", "ghgPercentage", "Scatter")}>
                                                                                        GHG
                                                                                    </ActionLink>
                                                                                </li>
                                                                                <li className={`SubMenu-item ${rawSummaryResults.graphType === "Bar" ? "is-active" : ""}`}>
                                                                                    <ActionLink id={`getSummaryResults-${run.id}`} onClick={rawResultsAnalysisSummary(run.id, "nPerHaBefore", "nPerHaAfter", "nLossPercentage", "Bar")}>
                                                                                        Change distribution
                                                                                    </ActionLink>
                                                                                </li>
                                                                                <li className={`SubMenu-item ${rawSummaryResults.graphType === "IncreaseTable" ? "is-active" : ""}`}>
                                                                                    <ActionLink id={`getSummaryResults-${run.id}`} onClick={rawResultsAnalysisSummary(run.id, "nPerHaBefore", "nPerHaAfter", "nLossPercentage", "IncreaseTable")}>
                                                                                        Top 50 increases
                                                                                    </ActionLink>
                                                                                </li>
                                                                                <li className={`SubMenu-item ${rawSummaryResults.graphType === "DecreaseTable" ? "is-active" : ""}`}>
                                                                                    <ActionLink id={`getSummaryResults-${run.id}`} onClick={rawResultsAnalysisSummary(run.id, "nPerHaBefore", "nPerHaAfter", "nLossPercentage", "DecreaseTable")}>
                                                                                        Top 50 decreases
                                                                                    </ActionLink>
                                                                                </li>
                                                                                <li className={`SubMenu-item ${rawSummaryResults.graphType === "NoResult" || rawSummaryResults.graphType === "Result" ? "is-active" : ""}`}>
                                                                                    <ActionLink id={`ghgPerHa-${run.id}`} onClick={rawResultsAnalysisSummary(run.id, "nPerHaBefore", "nPerHaAfter", "nLossPercentage", "NoResult")}>
                                                                                        Loss / Gain result
                                                                                    </ActionLink>
                                                                                </li>
                                                                            </ul>
                                                                        </div>
                                                                        <div>
                                                                            {graphData && rawSummaryResults.id === run.id && (
                                                                                <div>
                                                                                    <div>
                                                                                        {rawSummaryResults.attribute === "nLossPercentage" ? (
                                                                                            <div className="Radio_group Radio_group--inline">
                                                                                                <label className="Radio u-pl-xxl">
                                                                                                    <input type="radio" id="nTotal" className="Radio-input" name="nTotal" checked={rawSummaryResults.graphType === "Scatter" && rawSummaryResults.before === "nLossBefore"} onChange={() => rawSummaryResults && setRawSummaryResults({ ...rawSummaryResults, before: "nLossBefore", after: "nLossAfter", attribute: "nLossPercentage", graphType: "Scatter" })} />
                                                                                                    <span className="Radio-label">Total</span>
                                                                                                </label>
                                                                                                <label className="Radio">
                                                                                                    <input type="radio" id="nPerha" className="Radio-input" name="nPerha" checked={rawSummaryResults.graphType === "Scatter" && rawSummaryResults.before === "nLossPerHaBefore"} onChange={() => rawSummaryResults && setRawSummaryResults({ ...rawSummaryResults, before: "nLossPerHaBefore", after: "nLossPerHaAfter", attribute: "nLossPercentage", graphType: "Scatter" })} />
                                                                                                    <span className="Radio-label">Per hectare</span>
                                                                                                </label>
                                                                                            </div>
                                                                                        ) : rawSummaryResults.attribute === "pLossPercentage" ? (
                                                                                            <div className="Radio_group Radio_group--inline">
                                                                                                <label className="Radio u-pl-xxl">
                                                                                                    <input type="radio" id="pTotal" className="Radio-input" name="pTotal" checked={rawSummaryResults.graphType === "Scatter" && rawSummaryResults.before === "pLossBefore"} onChange={() => rawSummaryResults && setRawSummaryResults({ ...rawSummaryResults, before: "pLossBefore", after: "pLossAfter", attribute: "pLossPercentage", graphType: "Scatter" })} />
                                                                                                    <span className="Radio-label">Total</span>
                                                                                                </label>
                                                                                                <label className="Radio">
                                                                                                    <input type="radio" id="pPerha" className="Radio-input" name="pPerha" checked={rawSummaryResults.graphType === "Scatter" && rawSummaryResults.before === "pLossPerHaBefore"} onChange={() => rawSummaryResults && setRawSummaryResults({ ...rawSummaryResults, before: "pLossPerHaBefore", after: "pLossPerHaAfter", attribute: "pLossPercentage", graphType: "Scatter" })} />
                                                                                                    <span className="Radio-label">Per hectare</span>
                                                                                                </label>
                                                                                            </div>
                                                                                        ) : (
                                                                                            <div className="Radio_group Radio_group--inline">
                                                                                                <label className="Radio u-pl-xxl">
                                                                                                    <input type="radio" id="ghgTotal" className="Radio-input" name="ghgTotal" checked={rawSummaryResults.graphType === "Scatter" && rawSummaryResults.before === "ghgBefore"} onChange={() => rawSummaryResults && setRawSummaryResults({ ...rawSummaryResults, before: "ghgBefore", after: "ghgAfter", attribute: "ghgPercentage", graphType: "Scatter" })} />
                                                                                                    <span className="Radio-label">Total</span>
                                                                                                </label>
                                                                                                <label className="Radio">
                                                                                                    <input type="radio" id="ghgPerha" className="Radio-input" name="ghgPerha" checked={rawSummaryResults.graphType === "Scatter" && rawSummaryResults.before === "ghgPerHaBefore"} onChange={() => rawSummaryResults && setRawSummaryResults({ ...rawSummaryResults, before: "ghgPerHaBefore", after: "ghgPerHaAfter", attribute: "ghgPercentage", graphType: "Scatter" })} />
                                                                                                    <span className="Radio-label">Per hectare</span>
                                                                                                </label>
                                                                                            </div>
                                                                                        )}
                                                                                    </div>
                                                                                    <Scatter data={scatterData} options={options} />
                                                                                </div>
                                                                            )}
                                                                        </div>
                                                                        <div>
                                                                            {barData && rawSummaryResults.id === run.id && (
                                                                                <div>
                                                                                    <div style={{ paddingLeft: "60px" }}>
                                                                                        <Bar data={barData} options={barChartOptions} className="padding-left: 120px" />
                                                                                    </div>
                                                                                    <div>
                                                                                        <div className="Table">
                                                                                            <table style={{ tableLayout: "fixed" }}>
                                                                                                <thead>
                                                                                                    <tr>
                                                                                                        <th className="u-textCenter" style={{ width: "130px" }}></th>
                                                                                                        <th>No res</th>
                                                                                                        <th>-50</th>
                                                                                                        <th>-40 -50</th>
                                                                                                        <th className="u-textCenter">-30 -40</th>
                                                                                                        <th className="u-textCenter">-20 -30</th>
                                                                                                        <th className="u-textCenter">-10 -20</th>
                                                                                                        <th className="u-textCenter">-5 -10</th>
                                                                                                        <th className="u-textCenter">0 -5</th>
                                                                                                        <th className="u-textCenter">0</th>
                                                                                                        <th className="u-textCenter">0 to 5</th>
                                                                                                        <th className="u-textCenter">5 to 10</th>
                                                                                                        <th className="u-textCenter">10 to 20</th>
                                                                                                        <th className="u-textCenter">20 to 30</th>
                                                                                                        <th className="u-textCenter">30 to 40</th>
                                                                                                        <th className="u-textCenter">40 to 50</th>
                                                                                                        <th className="u-textCenter">Greater 50</th>
                                                                                                        <th className="u-textCenter">A result</th>
                                                                                                    </tr>
                                                                                                </thead>
                                                                                                <tbody>
                                                                                                    {barData.datasets.map((t) => (
                                                                                                        <tr key={t.data}>
                                                                                                            {t.label === "% change in N" ? (
                                                                                                                <td valign="u-textCenter" className="Colour-td--nloss">
                                                                                                                    {t.label}
                                                                                                                </td>
                                                                                                            ) : t.label === "% change in P" ? (
                                                                                                                <td valign="u-textCenter" className="Colour-td--ploss">
                                                                                                                    {t.label}
                                                                                                                </td>
                                                                                                            ) : (
                                                                                                                <td valign="u-textCenter" className="Colour-td--sky">
                                                                                                                    {t.label}
                                                                                                                </td>
                                                                                                            )}

                                                                                                            <td valign="u-textCenter">{t.data[0]}</td>
                                                                                                            <td valign="u-textCenter">{t.data[1]}</td>
                                                                                                            <td valign="u-textCenter">{t.data[2]}</td>
                                                                                                            <td valign="u-textCenter">{t.data[3]}</td>
                                                                                                            <td valign="u-textCenter">{t.data[4]}</td>
                                                                                                            <td valign="u-textCenter">{t.data[5]}</td>
                                                                                                            <td valign="top">{t.data[6]}</td>
                                                                                                            <td valign="top">{t.data[7]}</td>
                                                                                                            <td valign="top">{t.data[8]}</td>
                                                                                                            <td valign="top">{t.data[9]}</td>
                                                                                                            <td valign="top">{t.data[10]}</td>
                                                                                                            <td valign="top">{t.data[11]}</td>
                                                                                                            <td valign="top">{t.data[12]}</td>
                                                                                                            <td valign="top">{t.data[13]}</td>
                                                                                                            <td valign="top">{t.data[14]}</td>
                                                                                                            <td valign="top">{t.data[15]}</td>
                                                                                                            <td valign="top">{t.data[16]}</td>
                                                                                                        </tr>
                                                                                                    ))}
                                                                                                </tbody>
                                                                                                <tfoot>
                                                                                                    <tr>
                                                                                                        <td></td>
                                                                                                    </tr>
                                                                                                </tfoot>
                                                                                            </table>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            )}
                                                                        </div>
                                                                        <div>
                                                                            {tableData && rawSummaryResults.id === run.id && (
                                                                                <div>
                                                                                    <div className="Radio_group Radio_group--inline">
                                                                                        <label className="Radio u-pl-xxl">
                                                                                            <input type="radio" id="nitrogen" className="Radio-input" name="nitrogen" checked={rawSummaryResults.attribute === "nLossPercentage"} onChange={() => rawSummaryResults && setRawSummaryResults({ ...rawSummaryResults, attribute: "nLossPercentage" })} />
                                                                                            <span className="Radio-label">Nitrogen</span>
                                                                                        </label>
                                                                                        <label className="Radio">
                                                                                            <input type="radio" id="phosporus" className="Radio-input" name="phosporus" checked={rawSummaryResults.attribute === "pLossPercentage"} onChange={() => rawSummaryResults && setRawSummaryResults({ ...rawSummaryResults, attribute: "pLossPercentage" })} />
                                                                                            <span className="Radio-label">Phosporus</span>
                                                                                        </label>
                                                                                        <label className="Radio">
                                                                                            <input type="radio" id="ghg" className="Radio-input" name="ghg" checked={rawSummaryResults.attribute === "ghgPercentage"} onChange={() => rawSummaryResults && setRawSummaryResults({ ...rawSummaryResults, attribute: "ghgPercentage" })} />
                                                                                            <span className="Radio-label">GHG</span>
                                                                                        </label>
                                                                                    </div>
                                                                                    {tableData.length > 0 ? (
                                                                                        <div className="Table u-mt-sm">
                                                                                            <table>
                                                                                                <thead>
                                                                                                    <tr>
                                                                                                        <th data-width="20">Farm Name</th>
                                                                                                        <th data-width="20">Analysis/Publication Name</th>
                                                                                                        <th data-width="5">Type</th>
                                                                                                        <th data-width="5">N Before</th>
                                                                                                        <th data-width="5">N After</th>
                                                                                                        <th data-width="5">N Percentage</th>
                                                                                                        <th data-width="5">P Before</th>
                                                                                                        <th data-width="5">P After</th>
                                                                                                        <th data-width="5">P Percentage</th>
                                                                                                        <th data-width="5">GHG Before</th>
                                                                                                        <th data-width="5">GHG After</th>
                                                                                                        <th data-width="5">GHG Percentage</th>
                                                                                                        <th data-width="10"></th>
                                                                                                    </tr>
                                                                                                </thead>
                                                                                                <tbody>
                                                                                                    {tableData.map((t) => (
                                                                                                        <tr key={t.analysisId}>
                                                                                                            <td valign="top">{t.farmName}</td>
                                                                                                            <td valign="top">{t.analysisName}</td>
                                                                                                            <td valign="top">{t.isBudget ? "Analysis" : "Publication"}</td>
                                                                                                            <td valign="top">{t.nLossPerHaBefore}</td>
                                                                                                            <td valign="top">{t.nLossPerHaAfter}</td>
                                                                                                            <td valign="top">{t.nLossPercentage}</td>
                                                                                                            <td valign="top">{t.pLossPerHaBefore}</td>
                                                                                                            <td valign="top">{t.pLossPerHaAfter}</td>
                                                                                                            <td valign="top">{t.pLossPercentage}</td>
                                                                                                            <td valign="top">{t.ghgPerHaBefore}</td>
                                                                                                            <td valign="top">{t.ghgPerHaAfter}</td>
                                                                                                            <td valign="top">{t.ghgPercentage}</td>
                                                                                                            <td valign="top">
                                                                                                                {t.isBudget === true && (
                                                                                                                    <Link className="u-link IconLink--edit" target="_blank" to={`/app/farm/${t.farmId}/analysis/${t.budgetBaseId}`}>
                                                                                                                        View analysis
                                                                                                                    </Link>
                                                                                                                )}
                                                                                                                {t.isBudget !== true && (
                                                                                                                    <Link className="u-link IconLink--edit" target="_blank" to={`/app/farm/${t.farmId}/analysis/${t.budgetBaseId}/overview`}>
                                                                                                                        View publication
                                                                                                                    </Link>
                                                                                                                )}
                                                                                                            </td>
                                                                                                        </tr>
                                                                                                    ))}
                                                                                                </tbody>
                                                                                            </table>
                                                                                        </div>
                                                                                    ) : (
                                                                                        <div>
                                                                                            <Alert type="info" text={rawSummaryResults.attribute === "nLossPercentage" ? "There are no differences in Nitrogen results between before run and after run values (if the before run had a value)" : rawSummaryResults.attribute === "pLossPercentage" ? "There are no differences in Phosporus results between before run and after run values (if the before run had a value)" : "There are no differences in GHG results between before run and after run values (if the before run had a value)"} className="u-border" />
                                                                                        </div>
                                                                                    )}
                                                                                </div>
                                                                            )}
                                                                        </div>
                                                                        <div>
                                                                            {lossGainResultsData && rawSummaryResults.id === run.id && (
                                                                                <div>
                                                                                    <div className="Radio_group Radio_group--inline">
                                                                                        <label className="Radio u-pl-xxl">
                                                                                            <input type="radio" id="resultToNoResult" className="Radio-input" name="resultToNoResult" checked={rawSummaryResults.graphType === "NoResult"} onChange={() => rawSummaryResults && setRawSummaryResults({ ...rawSummaryResults, attribute: "nLossPercentage", graphType: "NoResult" })} />
                                                                                            <span className="Radio-label">Results lost</span>
                                                                                        </label>
                                                                                        <label className="Radio">
                                                                                            <input type="radio" id="noResultToResult" className="Radio-input" name="noResultToResult" checked={rawSummaryResults.graphType === "Result"} onChange={() => rawSummaryResults && setRawSummaryResults({ ...rawSummaryResults, attribute: "nLossPercentage", graphType: "Result" })} />
                                                                                            <span className="Radio-label">Results gained</span>
                                                                                        </label>
                                                                                    </div>
                                                                                    {lossGainResultsData.length > 0 ? (
                                                                                        <div className="Table u-mt-sm">
                                                                                            <table>
                                                                                                <thead>
                                                                                                    <tr>
                                                                                                        <th data-width="20">Farm Name</th>
                                                                                                        <th data-width="20">Analysis/Publication Name</th>
                                                                                                        <th data-width="5">Type</th>
                                                                                                        <th data-width="5">N Before</th>
                                                                                                        <th data-width="5">N After</th>
                                                                                                        <th data-width="5">N Percentage</th>
                                                                                                        <th data-width="30">{rawSummaryResults.graphType === "NoResult" ? "Error Message After" : "Error Message Before"}</th>
                                                                                                        <th data-width="10"></th>
                                                                                                    </tr>
                                                                                                </thead>
                                                                                                <tbody>
                                                                                                    {lossGainResultsData.map((t) => (
                                                                                                        <tr key={t.analysisId}>
                                                                                                            <td valign="top">{t.farmName}</td>
                                                                                                            <td valign="top">{t.analysisName}</td>
                                                                                                            <td valign="top">{t.isBudget ? "Analysis" : "Publication"}</td>
                                                                                                            <td valign="top">{t.nLossPerHaBefore}</td>
                                                                                                            <td valign="top">{t.nLossPerHaAfter}</td>
                                                                                                            <td valign="top">{t.nLossPercentage}</td>
                                                                                                            <td valign="top">{rawSummaryResults.graphType === "NoResult" ? t.messageAfter : t.messageBefore}</td>
                                                                                                            <td valign="top">
                                                                                                                {t.isBudget === true && (
                                                                                                                    <Link className="u-link IconLink--edit" target="_blank" to={`/app/farm/${t.farmId}/analysis/${t.budgetBaseId}`}>
                                                                                                                        View analysis
                                                                                                                    </Link>
                                                                                                                )}
                                                                                                                {t.isBudget !== true && (
                                                                                                                    <Link className="u-link IconLink--edit" target="_blank" to={`/app/farm/${t.farmId}/analysis/${t.budgetBaseId}/overview`}>
                                                                                                                        View publication
                                                                                                                    </Link>
                                                                                                                )}
                                                                                                            </td>
                                                                                                        </tr>
                                                                                                    ))}
                                                                                                </tbody>
                                                                                            </table>
                                                                                        </div>
                                                                                    ) : (
                                                                                        <div>
                                                                                            <Alert type="info" text={rawSummaryResults.graphType === "NoResult" ? "There are no analyses that had a result before and do not have a result after the upgrade" : "There are no analyses that did not have a result before and now have a result after the upgrade"} className="u-border" />
                                                                                        </div>
                                                                                    )}
                                                                                </div>
                                                                            )}
                                                                        </div>
                                                                    </Panel>
                                                                </div>
                                                            )}
                                                        </div>
                                                    }
                                                </div>
                                            )}
                                        </div>
                                    </Panel>
                                </div>
                            );
                        })}
                </PanelBody>
            </Panel>

            {createUpgradeRunModal && <CreateUpgradeRunModal {...createUpgradeRunModal.modalProps} />}
        </>
    );
};

export default compose(withAppContext, withActions({ getAnalysisUpgradeRuns, deleteAnalysisUpgradeRun, downloadAnalysisUpgradeResults, downloadAnalysisBlocksUpgradeResults, getUpgradeRunRawResultsSummary }))(UpgradeRuns);
