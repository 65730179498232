import React, { Component } from "react";
import { compose, withActions, withState } from "containers/HOC";
import { v4 as uuidv4 } from "uuid";
import { Redirect, Link, withRouter } from "react-router-dom";
import { Form, Field } from "react-final-form";
import * as validations from "common/validations";
import TextField from "components/FormFields/TextField";
import CheckboxField from "components/FormFields/CheckboxField";
import { register, registerCancel, getUserInvitationCancel, getFarmAccessInvitationCancel } from "./_actions";
import PdfModal from "components/PdfModal";
import { modalInlineClose, modalInlineOpen } from "containers/App/_actions";
import moment from "moment";
import ActionLink from "components/ActionLink";
import { Grid, GridCell } from "components/Grid";
import { logEmailTracking } from "containers/App/_actions";
import { ZendeskLink } from "components/Help";

class Register extends Component {
    constructor(props) {
        super(props);
        this.state = {
            terms: false,
        };
    }

    componentDidMount() {
        this.props.logEmailTracking("Registration page opened", this.props.emailId, this.props.emailTime);
    }

    componentWillUnmount() {
        this.props.registerCancel();
        this.props.getUserInvitationCancel();
        this.props.getFarmAccessInvitationCancel();
    }

    _validate = (values) => {
        const errors = {};

        errors.email = validations.required(values.email);
        errors.email = errors.email || validations.email(values.email);
        errors.email = errors.email || validations.maxLength(255)(values.email);

        errors.fullName = validations.required(values.fullName);
        errors.fullName = errors.fullName || validations.nameRegex(values.fullName);
        errors.fullName = errors.fullName || validations.maxLength(50)(values.fullName);

        errors.password = validations.required(values.password);
        errors.password = errors.password || validations.minLength(8)(values.password);
        errors.password = errors.password || validations.maxLength(50)(values.password);

        errors.confirmPassword = validations.required(values.confirmPassword);
        errors.confirmPassword = errors.confirmPassword || validations.minLength(8)(values.confirmPassword);
        errors.confirmPassword = errors.confirmPassword || validations.maxLength(50)(values.confirmPassword);
        errors.confirmPassword = errors.confirmPassword || values.confirmPassword !== values.password ? "Password does not match" : undefined;

        errors.terms = validations.required(values.terms);

        return errors;
    };

    _register = (values) => {
        let { userInvitation = {} } = this.props;
        if (userInvitation === null) userInvitation = {};
        const { existingAccount } = userInvitation;
        if (existingAccount) {
            existingAccount.expires = moment.utc().add(90, "days").startOf("day");
            existingAccount.name = values.accountName ? values.accountName : values.fullName;
            existingAccount.parentAccountId = userInvitation.accountId;
            existingAccount.target = "Organisation";
            existingAccount.invitationId = values.invitationId;
            existingAccount.user = existingAccount.users[0];
            existingAccount.user.fullName = values.fullName;
            existingAccount.user.password = values.password;
            existingAccount.parentAccountRef = userInvitation.group;
        }

        const registrationDetails = existingAccount || {
            id: uuidv4(),
            name: values.accountName ? values.accountName : values.fullName,
            parentAccountId: userInvitation.accountId,
            target: userInvitation.target,
            user: {
                id: uuidv4(),
                fullName: values.fullName,
                email: values.email,
                password: values.password,
            },
            invitationId: values.invitationId,
        };
        this.props.register(registrationDetails, this.props.location.state);
    };

    _openPdf = (e, name) => {
        e.preventDefault();
        this.setState({ documentName: name });
        this.props.modalInlineOpen();
    };

    render() {
        const { online, isAuthenticated, referrer, initialValues, userInvitation, farmAccessInvitation, registrationError, match, farmId } = this.props;
        const userInvitationPending = userInvitation && userInvitation.status === "Pending";
        const farmAccessInvitationPending = farmAccessInvitation && farmAccessInvitation.status === "Pending";
        const isInvite = userInvitationPending || farmAccessInvitationPending;
        const isStudentInvite = userInvitation && userInvitation.target === "Organisation" && process.env.REACT_APP_NO_REGISTER === "true";
        const studentAlreadyRegistered = isStudentInvite && userInvitation.existingAccount && moment(userInvitation.existingAccount.expires) > moment().valueOf();
        if (userInvitation && userInvitation.existingAccount) {
            initialValues.fullName = userInvitation.existingAccount.name;
        }
        const displayPayment = match.path.endsWith("dp");

        if (isAuthenticated) {
            if (farmAccessInvitationPending) {
                const path = farmId ? `/app/farm/${farmId}${displayPayment ? "/dp" : ""}` : referrer || "/";
                return <Redirect to={path} />;
            }
            return <Redirect to={referrer || "/"} />;
        }

        if (process.env.REACT_APP_NO_REGISTER === "true" && !isInvite) {
            return <Redirect to={"/pub/login"} />;
        }

        return (
            <div className="Tile u-pb-xl">
                {this.state.documentName === "Terms" ? <PdfModal fileName={`https://docs.overseer.org.nz/${process.env.REACT_APP_PRODUCT}/OverseerTermsOfUse.pdf`} title="Terms of use" close={() => this.props.modalInlineClose()} /> : <PdfModal fileName={`https://docs.overseer.org.nz/${process.env.REACT_APP_PRODUCT}/OverseerRegistrationGuide.pdf`} title="Registration" close={() => this.props.modalInlineClose()} />}
                {(!isInvite || registrationError) && (
                    <ul className="SubTabs SubTabs--simple SubTabs--center">
                        <li className="SubTabs-item">
                            <Link id="auth-login-tab" to="/pub/login">
                                Login
                            </Link>
                        </li>
                        <li className="SubTabs-item is-active">
                            <Link id="auth-register-tab" to="/pub/register">
                                Register
                            </Link>
                        </li>
                    </ul>
                )}
                <Grid>
                    <GridCell className="Grid-cell--center u-md-width2of3 u-lg-width1of2">
                        {userInvitationPending && (
                            <div className="Alert Alert--help u-mt-lg">
                                <i className="icon icon-alert" id="register-helpmsg" />
                                {isStudentInvite ? <span>{userInvitation.accountName} has invited you to register as a student.</span> : <span>You have been invited to register as a user with the '{userInvitation.accountName}' organisation.</span>}
                            </div>
                        )}
                        {farmAccessInvitationPending && !userInvitationPending && (
                            <div className="Alert Alert--help u-mt-lg">
                                <i className="icon icon-alert" id="register-helpmsg" />
                                You have been granted access to the '{farmAccessInvitation.farmName}' farm.
                            </div>
                        )}
                        {studentAlreadyRegistered && (
                            <div className="Alert Alert--error u-mt-lg">
                                <i className="icon icon-alert" id="register-errormsg" />
                                <span>You are already registered with another education provider. Are you sure you want to move to {userInvitation.accountName}?</span>
                            </div>
                        )}
                        {!userInvitationPending && (
                            <div className="Alert Alert--info u-mt-lg">
                                <i className="icon icon-info" id="register-info" />
                                <span>
                                    <b>{farmAccessInvitation ? "PLEASE BE AWARE, if" : "If"} you are working for an organisation (eg. a consultancy, dairy company, fertiliser company or council)</b> please request an invitation from the organisation's administrator {farmAccessInvitation ? "before" : "rather than"} registering below. If you register as an individual you will not be able to share farm information with your colleagues.
                                    <br />
                                    <br />
                                    <b>
                                        For more help with the registration process, please
                                        <ZendeskLink url="https://support.overseer.org.nz/hc/en-us/articles/900000867506-Registration-guide"> click here</ZendeskLink>
                                    </b>
                                </span>
                            </div>
                        )}
                        {!online && (
                            <div className="Alert Alert--error u-mt-lg">
                                <i className="icon icon-alert" id="register-errormsg" />
                                Overseer is currently offline. Please try again later.
                            </div>
                        )}
                        {online && registrationError && (
                            <div className="Alert Alert--error u-mt-lg">
                                <i className="icon icon-alert" id="register-errormsg" />
                                {registrationError}
                            </div>
                        )}
                        <Form initialValues={initialValues} validate={this._validate} onSubmit={this._register}>
                            {({ values, handleSubmit }) => {
                                return (
                                    <form onSubmit={handleSubmit}>
                                        <Field name="email" id="register-email" type="text" label="Your email" placeholder="Enter your email" disabled={initialValues.email} required component={TextField} />
                                        <Field name="fullName" id="register-fullName" type="text" label="Your full name" placeholder="Enter your full name" required component={TextField} />
                                        <Field name="password" id="register-password" type="password" label="Password" placeholder="Enter your password" required component={TextField} />
                                        <Field name="confirmPassword" id="register-confirm-password" type="password" label="Confirm password" placeholder="Confirm your password" required component={TextField} />
                                        <Grid>
                                            <GridCell>
                                                <div className="u-textCenter u-mt-lg">
                                                    <Field
                                                        name="terms"
                                                        label={
                                                            <div>
                                                                I agree to the <ActionLink onClick={(e) => this._openPdf(e, "Terms")}>terms of use</ActionLink>
                                                            </div>
                                                        }
                                                        vertical
                                                        type="checkbox"
                                                        component={CheckboxField}
                                                    />
                                                </div>
                                                <button type="submit" id="register-submit" disabled={!online || !values.terms} className="Button Button--primary Button--block u-mt-md">
                                                    Register
                                                </button>
                                            </GridCell>
                                            <GridCell className="u-mt-lg">
                                                {!isInvite && (
                                                    <p className="u-mt-lg u-textCenter">
                                                        Already have an account? <Link to="/pub/login">Login</Link>
                                                    </p>
                                                )}
                                            </GridCell>
                                        </Grid>
                                    </form>
                                );
                            }}
                        </Form>
                    </GridCell>
                </Grid>
            </div>
        );
    }
}

export default compose(
    withRouter,
    withState((state, ownProps) => {
        const { app, auth } = state;
        const { userInvitation, farmAccessInvitation } = auth;

        let initialValues = {};
        if (userInvitation && userInvitation.status === "Pending") initialValues.email = userInvitation.email;
        else if (farmAccessInvitation && farmAccessInvitation.status === "Pending") initialValues.email = farmAccessInvitation.inviteeEmail;
        if (ownProps.match.params.invitationId) initialValues.invitationId = ownProps.match.params.invitationId;

        return {
            online: app.online,
            isAuthenticated: auth.isAuthenticated,
            referrer: auth.referrer,
            registrationError: auth.registrationError,
            initialValues,
            userInvitation,
            farmAccessInvitation,
            farmId: ownProps.match.params.farmId,
            emailId: app.emailId,
            emailTime: app.emailTime,
        };
    }),
    withActions({ register, registerCancel, modalInlineClose, modalInlineOpen, getUserInvitationCancel, getFarmAccessInvitationCancel, logEmailTracking })
)(Register);
