import React from "react";
import { Link } from "react-router-dom";
import * as utils from "common/utils";
import Spinner from "components/Spinner/Spinner";
import SortableTable from "components/new/SortableTable";
import SortableTableHeader from "components/new/SortableTableHeader";
import { useToggleMyFarmAsync } from "containers/hooks";
import { useOnline } from "common/hooks";
import { Panel, PanelBody } from "components/Panel";

export default function PublicationList({ myPublications = [], onToggleMyFarm, pageSize = 0, isLoading = false }) {
    const online = useOnline();
    const toggleMyFarmAsync = useToggleMyFarmAsync();
    const [myFarmOverride, setMyFarmOverride] = React.useState([]);

    const localFarmToggle = async (farmId, isMyFarm) => {
        await toggleMyFarmAsync(farmId, isMyFarm);
        setMyFarmOverride((prev) => {
            const newOverrides = prev.filter((f) => f.farmId !== farmId);
            if (isMyFarm) {
                newOverrides.push({ farmId, isMyFarm });
            }
            return newOverrides;
        });
    };

    const publications = myPublications.map((publication) => {
        const isPrivateYearEnd = publication.budgetType === "PrivateYearEnd";

        return {
            id: publication.id,
            farmId: publication.farmId,
            farmName: publication.farmName,
            address: publication.address,
            budgetName: isPrivateYearEnd ? `Private year end ${publication.year}` : publication.budgetName,
            reference: publication.reference,
            version: publication.version,
            publishedBy: publication.publishedBy && publication.publishedBy.userFullName,
            email: publication.publishedBy && publication.publishedBy.userEmail,
            publishedOn: publication.publishedOn,
            status: publication.status,
            isMyFarm: publication.isMyFarm || myFarmOverride.some((f) => f.farmId === publication.farmId),
            farmIdentifier: publication.farmIdentifier,
        };
    });

    return (
        <Panel>
            <PanelBody>
                <SortableTable data={publications} pageSize={pageSize} defaultSortCriteria={{ fieldName: "publishedOn", fieldType: "date", desc: true }}>
                    {({ data, sortCriteria, handleSort }) => {
                        return (
                            <table>
                                <thead>
                                    <tr>
                                        <SortableTableHeader label="Farm name" fieldName="farmName" sortCriteria={sortCriteria} handleSort={handleSort} />
                                        <SortableTableHeader label="Address" fieldName="address" sortCriteria={sortCriteria} handleSort={handleSort} />
                                        <SortableTableHeader label="Analysis" fieldName="budgetName" sortCriteria={sortCriteria} handleSort={handleSort} />
                                        <SortableTableHeader label="Reference" fieldName="reference" sortCriteria={sortCriteria} handleSort={handleSort} />
                                        <SortableTableHeader label="Version" fieldName="version" sortCriteria={sortCriteria} handleSort={handleSort} number />
                                        <SortableTableHeader label="Publishee's farm ID" fieldName="farmIdentifier" sortCriteria={sortCriteria} handleSort={handleSort} />
                                        <SortableTableHeader label="Published by" fieldName="publishedBy" sortCriteria={sortCriteria} handleSort={handleSort} />
                                        <SortableTableHeader label="Email" fieldName="email" sortCriteria={sortCriteria} handleSort={handleSort} />
                                        <SortableTableHeader label="Published on" fieldName="publishedOn" sortCriteria={sortCriteria} handleSort={handleSort} date />
                                        <SortableTableHeader label="State" fieldName="status" sortCriteria={sortCriteria} handleSort={handleSort} />
                                        {online && <SortableTableHeader label="My farm" fieldName="isMyFarm" sortCriteria={sortCriteria} handleSort={handleSort} bool />}
                                        {online && <th className="th--shrink u-textCenter"></th>}
                                    </tr>
                                </thead>
                                <tbody>
                                    {data.length === 0 && (
                                        <tr>
                                            <td colSpan={online ? "11" : "9"}>
                                                <div className="Tile-body-message">
                                                    {isLoading && <Spinner dark />}
                                                    <p className="lead">{isLoading ? "Loading..." : "No results"}</p>
                                                </div>
                                            </td>
                                        </tr>
                                    )}
                                    {data.length > 0 &&
                                        data.map((publication) => {
                                            return (
                                                <tr key={publication.id} className="hover">
                                                    <td>
                                                        <Link to={`/app/farm/${publication.farmId}`} id={`view-farm-${publication.farmId}`} className="u-link u-textBold">
                                                            {publication.farmName}
                                                        </Link>
                                                    </td>
                                                    <td>{publication.address}</td>
                                                    <td>{publication.budgetName}</td>
                                                    <td>{publication.reference}</td>
                                                    <td className="u-textCenter">{publication.version}</td>
                                                    <td>{publication.farmIdentifier}</td>
                                                    <td>{publication.publishedBy}</td>
                                                    <td>{publication.email}</td>
                                                    <td>{utils.dateLong(publication.publishedOn)}</td>
                                                    <td>{publication.status}</td>
                                                    {online && (
                                                        <td className="u-textCenter">
                                                            <label className="Checkbox" htmlFor={"my-farm-" + publication.farmId}>
                                                                <input className="Checkbox-input" type="checkbox" id={"my-farm-" + publication.farmId} checked={publication.isMyFarm} onChange={(e) => localFarmToggle(publication.farmId, !publication.isMyFarm)} />
                                                                <span className="Checkbox-label"></span>
                                                            </label>
                                                        </td>
                                                    )}
                                                    {online && (
                                                        <td>
                                                            <Link to={`/app/farm/${publication.farmId}`} id={`open-farm-${publication.farmId}`} className="RoundButton RoundButton--right"></Link>
                                                        </td>
                                                    )}
                                                </tr>
                                            );
                                        })}
                                </tbody>
                            </table>
                        );
                    }}
                </SortableTable>
            </PanelBody>
        </Panel>
    );
}
