import { useEffect, useState } from "react";
import * as utils from "common/utils";
import { useRefData } from "common/hooks";
import * as geoJsonUtils from "common/geoJsonUtils";
import { useMapLayerToggleControl } from "./_hooks";
import MapLabel from "components/Map/MapLabel";

const MAX_ZOOM = 12;

export default function BlockLabelsLayerToggle({ mapData, mapId, blocks, geoJson}) {
    const layerId = geoJsonUtils.LAYER.BlockLabels;
    const showLayer = useMapLayerToggleControl(mapData, mapId, layerId, MAX_ZOOM);
    return <BlockLabels map={mapData?.getMap()} blocks={blocks} geoJson={geoJson} showLayer={showLayer} />;
}

function BlockLabels({ map, blocks, geoJson, showLayer }) {
    const blockLabels = useBlockLabels(blocks, geoJson);

    if (!map || !blockLabels) return null;

    return (
        <>
            {blockLabels.map((label) => (
                <MapLabel key={label.id} map={map} {...label} hidden={!showLayer} />
            ))}
        </>
    )
}

function useBlockLabels(blocks, geoJson) {
    const [labels, setLabels] = useState();
    const refData = useRefData();

    useEffect(() => {
        if (blocks && geoJson && refData) {
            const blockFeatures = geoJson.features.filter((f) => f.properties.blockId);
            const blockLabels = blockFeatures.reduce((results, feature) => {
                const block = blocks.find((b) => b.id === feature.properties.blockId);
                if (block) {
                    const position = geoJsonUtils.center(feature);
                    const blockDescription = utils.getBlockTypeText(refData, block);
                    const innerHTML = `<div class="u-textBold">${block.name}${feature.properties.imported ? " (auto assigned)" : ""}</div><div>${blockDescription}</div>`;
                    results.push({ id: feature.id, position, innerHTML });
                }
                return results;
            }, []);
            setLabels(blockLabels);
        }
    }, [blocks, geoJson, refData]);

    return labels;
}
