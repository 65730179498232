import React from "react";
import Select, { components } from "react-select";

const getBlockOption = (block, showBlockArea, icon) => {
    let label = block.name;
    const blockArea = block.areaInHectares || block.rotationArea;
    if (showBlockArea && blockArea) {
        label = `${block.name} (${blockArea}ha)`;
    }
    if (icon) {
        label = (
            <span>
                {icon} {label}
            </span>
        );
    }
    return { value: block.id, label };
};

const getBlockOptions = (availableBlocks, availableBlockGroups, showBlockArea) => {
    if (availableBlockGroups && availableBlockGroups.length > 0) {
        return availableBlockGroups.map((bg) => {
            return {
                label: bg.label,
                options: bg.blocks.map((b) => getBlockOption(b, showBlockArea, bg.icon)).sort((a, b) => (a.label >= b.label ? 1 : -1)),
            };
        });
    } else {
        return availableBlocks.map((b) => getBlockOption(b, showBlockArea)).sort((a, b) => (a.label >= b.label ? 1 : -1));
    }
};

const handleChange = (input, isMulti) => (value, action) => {
    const values = isMulti ? (value ? value.map((v) => v.value) : []) : value.value;
    if (input.onChange) input.onChange(values, action);
};

const handleBlur = (input) => (e) => {
    if (input.onBlur) input.onBlur(input.value);
};

const ClearIndicator = (props) => {
    return (
        <components.ClearIndicator {...props}>
            <i className="icon icon-cross-circle" title="Clear all" />
        </components.ClearIndicator>
    );
};

const DropdownIndicator = (props) => {
    return (
        <components.DropdownIndicator {...props}>
            <i className="icon icon-arrow-filled" />
        </components.DropdownIndicator>
    );
};

const getSelectedValues = (input, options, isMulti, isGrouped) => {
    if (isMulti) {
        if (isGrouped) {
            return options.reduce((values, grouping) => {
                values = values.concat(grouping.options.filter((o) => (input.value || []).includes(o.value)));
                return values;
            }, []);
        } else {
            return options.filter((o) => (input.value || []).includes(o.value));
        }
    } else {
        if (isGrouped) {
            return options.reduce((values, grouping) => {
                const option = grouping.options.find((o) => o.value === input.value);
                if (option) values.push(option);
                return values;
            }, []);
        } else {
            return options.find((o) => o.value === input.value);
        }
    }
};

const BlockSelector = ({ input, ...rest }) => {
    const { availableBlocks, availableBlockGroups, isMulti = true, title, label, instructions, placeholder = "Select one or more blocks", blockId, disabled, required, showBlockArea } = rest;

    const options = getBlockOptions(availableBlocks, availableBlockGroups, showBlockArea);
    const value = getSelectedValues(input, options, isMulti, availableBlockGroups);
    const hasError = rest.meta && rest.meta.touched && rest.meta.error;

    const colourStyles = {
        multiValue: (styles, { data }) => {
            return {
                ...styles,
                background: data.value === blockId ? "repeating-linear-gradient(-60deg, #E39153, #E39153 5px, #F39153 5px, #F39153 10px) !important" : "repeating-linear-gradient(-60deg, #4591b9, #4591b9 5px, #549bc0 5px, #549bc0 10px) !important",
                color: "#fff !important",
                padding: "5px 10px !important",
                marginRight: "3px !important",
                marginBottom: "3px !important",
            };
        },
    };

    const fieldId = input.id || input.name || "blockIds";
    const invalid = rest?.meta?.error;
    return (
        <React.Fragment>
            {title && title.length > 0 && <h3>{title}</h3>}
            <div className={`Field ${hasError ? "has-error" : invalid ? "is-invalid" : ""}`}>
                <label htmlFor={fieldId} className="Select Select--block">
                    {label && (
                        <span className={`Field-label ${instructions ? "u-mb-sm" : "u-mb-md"}`}>
                            {label}
                            {required === true && <sup className="required">*</sup>}
                        </span>
                    )}
                    {instructions && <p className="u-mb-md">{instructions}</p>}
                    <Select {...input} {...rest} id={fieldId} value={value} onChange={handleChange(input, isMulti)} onBlur={handleBlur(input)} options={options} isMulti={isMulti} isDisabled={disabled} components={{ ClearIndicator, DropdownIndicator }} closeMenuOnSelect={!isMulti} classNamePrefix="ovr_multiSelect" menuShouldScrollIntoView={false} styles={colourStyles} placeholder={placeholder} noOptionsMessage={(props) => "All available blocks selected"} />
                </label>
                {hasError && (
                    <small className="Field-error u-mt-xs" id={`${input.id || input.name || "blockIds"}-error`}>
                        {rest.meta.error}
                    </small>
                )}
            </div>
        </React.Fragment>
    );
};

export default BlockSelector;
