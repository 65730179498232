import { Form, Field } from "react-final-form";
import { FieldArray } from "react-final-form-arrays";
import arrayMutators from "final-form-arrays";
import * as FormUtils from "common/FormUtils";
import { useModal, useRefData } from "common/hooks";
import * as utils from "common/utils";
import { Modal, ModalBody, ModalFooter, ModalFooterLeft, ModalFooterRight } from "components/Modal";
import { Button } from "components/Button";
import { Grid, GridCell } from "components/Grid";
import { CheckboxField, DecimalNumberField, HiddenField, RadioGroupField, SelectField, WholeNumberField } from "components/FormFields";
import BlockSelector from "components/new/BlockSelector";
import DistributionRemaining from "./DistributionRemaining";
import Alert from "components/Alert";
import * as domain from "common/domain";
import * as _utils from "./_utils";

function DistributionModal({ analysis, destination, onSubmit, close }) {
    const refData = useRefData();
    const destinationType = utils.valueToText(refData.supplementDestinationTypes, destination.type);
    const storageConditionOptions = _utils.getDistributionStorageConditions(refData, destination.type);
    const structureOptions = _utils.getAvailableDistributionStructures(analysis, destination.feedSupplement, destination.id, refData);
    const blockTypeOptions = _utils.getBlockTypes(refData);
    const availableBlockOptions = _utils.getAvailableDistributionBlocks(analysis);
    const enterpriseOptions = _utils.getAvailableDistributionEnterprises(analysis, destination.feedSupplement, destination.id, refData);

    const showAmount = showAmountField(analysis, destination);
    const showAutumnAmount = showAutumnAmountField(analysis, destination);
    const amountIsDecimal = amountIsInTonnes(destination);
    const showStruture = showStructureField(destination);

    const handleBlockTypeChanged = (form, blockType) => {
        if (blockType === "AllBlocks") {
            form.change("applications", [{ months: {} }]);
        } else {
            form.change("applications", []);
        }

        form.change("blockIds", []);
        form.change("specifyFeeding", false);
    };

    const handleBlockIdsChanged = (form, values, blockIds) => {
        const applications = blockIds.map((blockId) => {
            const existingApplication = values.applications.find((app) => app.blockId === blockId);
            if (existingApplication) {
                return existingApplication;
            } else {
                return { blockId, months: {} };
            }
        });
        form.change("applications", applications);
        if (applications.length === 0) {
            form.change("specifyFeeding", false);
        }
    };

    const handleEnterpriseIdChanged = (form, values, enterpriseId) => {
        const applications = values.applications.map((application) => ({ ...application, enterpriseId }));
        if (applications.length === 0) {
            applications.push({ enterpriseId, months: {} });
        }
        form.change("applications", applications);
    };

    const handleSpecifyFeedingChanged = (form, values, specifyFeeding) => {
        if (specifyFeeding && values.type === "AllBlocks" && values.applications.length === 0) {
            form.change("applications", [{ months: {} }]);
        }
    };

    const validateApplications = (applications) => {
        const validations = [];

        applications?.forEach((application) => {
            const validation = {
                months: {},
            };

            Object.keys(application.months).forEach((month) => {
                validation.months[month] = FormUtils.validators.range(1, 100)(application.months[month]);
            });

            validations.push(validation);
        });

        return validations;
    };

    const validate = (formValues) => {
        const validation = {};

        if (showAmount) {
            validation.amount = FormUtils.validators.required(formValues.amount);
            if (!validation.amount) {
                const minAmount = amountIsDecimal ? 0.1 : 1;
                const maxAmount = getMaxAmount(analysis, destination);
                validation.amount = FormUtils.validators.range(minAmount, maxAmount)(formValues.amount);
            }
        }

        if (showAutumnAmount) {
            validation.amountAutumn = FormUtils.validators.required(formValues.amountAutumn);
            if (!validation.amountAutumn) {
                const minAutumnAmount = 1;
                const maxAutumnAmount = getMaxAutumnAmount(destination);
                validation.amountAutumn = FormUtils.validators.range(minAutumnAmount, maxAutumnAmount)(formValues.amountAutumn);
            }
        }

        if (showStorageConditionField(formValues)) {
            validation.storageCondition = FormUtils.validators.required(formValues.storageCondition);
        }

        if (showUtilisationField(analysis, formValues)) {
            validation.utilisation = FormUtils.validators.required(formValues.utilisation);
        }

        if (showStructureField(formValues)) {
            validation.structureId = FormUtils.validators.required(formValues.structureId);
        }

        if (showStructureUtilisationField(analysis, formValues)) {
            validation.structureUtilisation = FormUtils.validators.required(formValues.structureUtilisation);
        }

        if (showBlockTypeField(formValues)) {
            validation.type = FormUtils.validators.required(formValues.type);
        }

        if (showBlocksField(formValues)) {
            validation.blockIds = FormUtils.validators.required(formValues.blockIds);
        }

        if (showEnterpriseField(formValues)) {
            validation.enterpriseId = FormUtils.validators.required(formValues.enterpriseId);
        }

        const specifiedFeedingMustEqual100Percent = formValues.specifyFeeding;
        if (specifiedFeedingMustEqual100Percent) {
            const specifiedFeedingTotals = _utils.getSpecifiedFeedingTotals(formValues.applications);
            if (specifiedFeedingTotals.total !== 100) {
                validation.specifiedFeedingError = "Total distribution must equal 100%";
            }
        }

        return validation;
    };

    const submitAsync = async (formValues) => {
        if (onSubmit) {
            formValues.applications = formValues.specifyFeeding
                ? formValues.applications
                : formValues.applications.map((application) => {
                      return {
                          ...application,
                          months: {},
                      };
                  });

            await onSubmit(formValues);
        }
        close();
    };

    return (
        <Form initialValues={destination} mutators={{ ...arrayMutators }} validate={validate} onSubmit={submitAsync}>
            {({ form, values, handleSubmit, submitting }) => {
                const amountUom = _utils.getAmountTypeMeta(values.feedSupplement?.amountType)?.uom;

                const showStorageCondition = showStorageConditionField(values);
                const storageConditionTip = showStorageCondition && domain.SupplementStorageTips[values.storageCondition];

                const showUtilisation = showUtilisationField(analysis, values);
                const utilisationTip = showUtilisation && domain.SupplementPaddockConditionTips[values.utilisation];

                const showStructureUtilisation = showStructureUtilisationField(analysis, values);
                const structureUtilisationTip = showStructureUtilisation && domain.SupplementStructureConditionTips[values.structureUtilisation];

                const showBlockType = showBlockTypeField(values);
                const showBlocks = showBlocksField(values);

                const showEnterprise = showEnterpriseField(values);
                const showDistributeToFields = showBlockType || showBlocks || showEnterprise;

                const showSpecifiedFeeding = showSpecifiedFeedingFields(values);
                const specifiedFeedingTotals = _utils.getSpecifiedFeedingTotals(values.applications);
                const specifiedFeedingError = form.getState()?.errors?.specifiedFeedingError;

                return (
                    <form onSubmit={handleSubmit}>
                        <Modal title={`Supplement distribution - ${destinationType}`} submitting={submitting} close={close} fluid>
                            <ModalBody>
                                <Grid>
                                    <GridCell className="u-md-width2of3">
                                        <Field name="id" component={HiddenField} />
                                        <Grid title="Distribution details" instructions={showAmount || showAutumnAmount ? "Enter details of how much to distribute and where" : "There is not enough remaining feed to distribute. You will need to reduce other amounts distributed or remove this distribution."}>
                                            {showAmount && (
                                                <GridCell className="u-lg-width1of2">
                                                    <Field name="amount" label={`${showAutumnAmount ? "Spring/summer amount" : "Amount"} (${amountUom})`} placeholder={0} uom={amountUom} required component={amountIsDecimal ? DecimalNumberField : WholeNumberField} decimalPlaces={amountIsDecimal ? 1 : undefined} />
                                                </GridCell>
                                            )}
                                            {showAutumnAmount && (
                                                <GridCell className="u-lg-width1of2">
                                                    <Field name="amountAutumn" label="Autumn/winter amount (cuts)" placeholder={0} uom="cuts" required component={WholeNumberField} />
                                                </GridCell>
                                            )}
                                            {showStorageCondition && (
                                                <GridCell className="u-lg-width1of2">
                                                    <Field name="storageCondition" label="Storage condition" placeholder="Select an option" options={storageConditionOptions} tip={storageConditionTip?.text} required component={SelectField} />
                                                </GridCell>
                                            )}
                                            {showUtilisation && (
                                                <GridCell className="u-lg-width1of2">
                                                    <Field name="utilisation" label="Utilisation" placeholder="Select an option" options={refData.supplementUtilisations} tip={utilisationTip?.text} required component={SelectField} />
                                                </GridCell>
                                            )}
                                            {showStruture && (
                                                <GridCell className="u-lg-width1of2">
                                                    <Field name="structureId" label="Structure" placeholder="Select a structure" options={structureOptions} required component={SelectField} />
                                                </GridCell>
                                            )}
                                            {showStructureUtilisation && (
                                                <GridCell className="u-lg-width1of2">
                                                    <Field name="structureUtilisation" label="Utilisation" placeholder="Select an option" options={refData.supplementStructureUtilisations} tip={structureUtilisationTip?.text} required component={SelectField} />
                                                </GridCell>
                                            )}
                                        </Grid>
                                    </GridCell>
                                    <GridCell className="u-md-width1of3">
                                        <DistributionRemaining destination={destination} showAutumnAmount={showAutumnAmount} amounts={destination.amounts} uom={amountUom} />
                                    </GridCell>
                                </Grid>
                                {showDistributeToFields && (
                                    <Grid title="Distribute to">
                                        {showBlockType && (
                                            <GridCell>
                                                <Field name="type" options={blockTypeOptions} onChange={(blockType) => handleBlockTypeChanged(form, blockType)} required component={RadioGroupField} inline />
                                            </GridCell>
                                        )}
                                        {showBlocks && (
                                            <GridCell>
                                                <Field name="blockIds" availableBlocks={availableBlockOptions} onChange={(blockIds) => handleBlockIdsChanged(form, values, blockIds)} required component={BlockSelector} />
                                            </GridCell>
                                        )}
                                        {showEnterprise && (
                                            <GridCell>
                                                <Field name="enterpriseId" options={enterpriseOptions} onChange={(enterpriseId) => handleEnterpriseIdChanged(form, values, enterpriseId)} required component={RadioGroupField} inline />
                                            </GridCell>
                                        )}
                                        {showSpecifiedFeeding && (
                                            <>
                                                <GridCell>
                                                    <Field name="specifyFeeding" label="Specify feeding % by months (leave unchecked to use defaults)" onChange={(specifyFeeding) => handleSpecifyFeedingChanged(form, values, specifyFeeding)} component={CheckboxField} />
                                                </GridCell>
                                                {values.specifyFeeding && (
                                                    <GridCell>
                                                        <div className="Table u-mt-md">
                                                            <Alert type="help" text="Enter the percentage of the distributed amount fed by month. The total must equal 100%" />
                                                            <table>
                                                                <thead>
                                                                    <tr>
                                                                        <th>Destination</th>
                                                                        <th className="u-textCenter">Jul</th>
                                                                        <th className="u-textCenter">Aug</th>
                                                                        <th className="u-textCenter">Sep</th>
                                                                        <th className="u-textCenter">Oct</th>
                                                                        <th className="u-textCenter">Nov</th>
                                                                        <th className="u-textCenter">Dec</th>
                                                                        <th className="u-textCenter">Jan</th>
                                                                        <th className="u-textCenter">Feb</th>
                                                                        <th className="u-textCenter">Mar</th>
                                                                        <th className="u-textCenter">Apr</th>
                                                                        <th className="u-textCenter">May</th>
                                                                        <th className="u-textCenter">Jun</th>
                                                                        <th className="th--shrink u-textCenter">Total</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    <FieldArray name="applications" validate={validateApplications}>
                                                                        {({ fields }) => {
                                                                            return fields.map((fieldName, index) => {
                                                                                const application = fields.value[index];
                                                                                const total = Object.keys(application.months).reduce((sum, month) => {
                                                                                    sum += parseInt(application.months[month], 10);
                                                                                    return sum;
                                                                                }, 0);
                                                                                const title = getSpecifiedFeedingDestinationTitle(analysis, values, application, refData);
                                                                                return (
                                                                                    <tr key={index}>
                                                                                        <td className="td--keyValuePair" title={title}>
                                                                                            <h5 className="u-mt-0 u-textEllipsis">{title}</h5>
                                                                                        </td>
                                                                                        {domain.farmYear.map((month) => (
                                                                                            <td key={month}>
                                                                                                <Field name={`${fieldName}.months[${month}]`} placeholder="0" hideError component={WholeNumberField} />
                                                                                            </td>
                                                                                        ))}
                                                                                        <td className="td--alignTop u-textCenter">
                                                                                            <h5 className={`${total > 100 ? "u-textError" : ""}`}>{total} %</h5>
                                                                                        </td>
                                                                                    </tr>
                                                                                );
                                                                            });
                                                                        }}
                                                                    </FieldArray>
                                                                </tbody>
                                                                <tfoot>
                                                                    <tr>
                                                                        <th>Total</th>
                                                                        <th className={`u-textCenter ${specifiedFeedingTotals.July > 100 ? "u-textError" : ""}`}>{specifiedFeedingTotals.July} %</th>
                                                                        <th className={`u-textCenter ${specifiedFeedingTotals.August > 100 ? "u-textError" : ""}`}>{specifiedFeedingTotals.August} %</th>
                                                                        <th className={`u-textCenter ${specifiedFeedingTotals.September > 100 ? "u-textError" : ""}`}>{specifiedFeedingTotals.September} %</th>
                                                                        <th className={`u-textCenter ${specifiedFeedingTotals.October > 100 ? "u-textError" : ""}`}>{specifiedFeedingTotals.October} %</th>
                                                                        <th className={`u-textCenter ${specifiedFeedingTotals.November > 100 ? "u-textError" : ""}`}>{specifiedFeedingTotals.November} %</th>
                                                                        <th className={`u-textCenter ${specifiedFeedingTotals.December > 100 ? "u-textError" : ""}`}>{specifiedFeedingTotals.December} %</th>
                                                                        <th className={`u-textCenter ${specifiedFeedingTotals.January > 100 ? "u-textError" : ""}`}>{specifiedFeedingTotals.January} %</th>
                                                                        <th className={`u-textCenter ${specifiedFeedingTotals.February > 100 ? "u-textError" : ""}`}>{specifiedFeedingTotals.February} %</th>
                                                                        <th className={`u-textCenter ${specifiedFeedingTotals.March > 100 ? "u-textError" : ""}`}>{specifiedFeedingTotals.March} %</th>
                                                                        <th className={`u-textCenter ${specifiedFeedingTotals.April > 100 ? "u-textError" : ""}`}>{specifiedFeedingTotals.April} %</th>
                                                                        <th className={`u-textCenter ${specifiedFeedingTotals.May > 100 ? "u-textError" : ""}`}>{specifiedFeedingTotals.May} %</th>
                                                                        <th className={`u-textCenter ${specifiedFeedingTotals.June > 100 ? "u-textError" : ""}`}>{specifiedFeedingTotals.June} %</th>
                                                                        <th className="u-textCenter">
                                                                            <h4 className={`u-mt-0 ${specifiedFeedingTotals.total === 100 ? "u-textSuccess" : "u-textError"}`}>{specifiedFeedingTotals.total} %</h4>
                                                                        </th>
                                                                    </tr>
                                                                </tfoot>
                                                            </table>
                                                            {specifiedFeedingError && <div className="Field-error u-block">{specifiedFeedingError}</div>}
                                                        </div>
                                                    </GridCell>
                                                )}
                                            </>
                                        )}
                                    </Grid>
                                )}
                            </ModalBody>
                            <ModalFooter>
                                <ModalFooterLeft>
                                    <Button id="cancel-button" secondary disabled={submitting} onClick={close}>
                                        Cancel
                                    </Button>
                                </ModalFooterLeft>
                                <ModalFooterRight>
                                    <Button id="confirm-button" submit waiting={submitting}>
                                        Done
                                    </Button>
                                </ModalFooterRight>
                            </ModalFooter>
                        </Modal>
                    </form>
                );
            }}
        </Form>
    );
}

export function useSupplementDistributionModal() {
    const [modal, openModal] = useModal(DistributionModal);

    const open = (analysis, destination, onSubmit) => {
        const modalProps = {
            analysis,
            destination,
            onSubmit,
        };
        openModal(modalProps);
    };

    return [modal, open];
}

function showAmountField(analysis, destination) {
    const maxAmount = getMaxAmount(analysis, destination);
    return maxAmount > 0;
}

function getMaxAmount(analysis, destination) {
    let result = destination?.amount || 0;
    const showAutumnAmount = _utils.showAutumnCuts(analysis, destination.feedSupplement);
    if (showAutumnAmount) {
        result += destination?.amounts?.springSummerRemaining || 0;
    } else {
        result += destination?.amounts?.totalRemaining || 0;
    }
    return utils.round(result, 2);
}

function amountIsInTonnes(destination) {
    return _utils.getAmountTypeMeta(destination?.feedSupplement?.amountType)?.uom === "tonnes";
}

function showAutumnAmountField(analysis, destination) {
    const maxAutumnAmount = getMaxAutumnAmount(destination);
    const showAutumnCuts = _utils.showAutumnCuts(analysis, destination.feedSupplement);
    return showAutumnCuts && maxAutumnAmount > 0;
}

function getMaxAutumnAmount(destination) {
    let result = destination?.amountAutumn || 0;
    result += destination?.amounts?.autumnWinterRemaining || 0;
    return utils.round(result, 2);
}

function showStorageConditionField(destination) {
    if (["Storage", "SpecifiedBlocks", "AllBlocks", "Enterprise", "Structure"].includes(destination.type) && destination.feedSupplement.category !== "DirectFeeding") {
        return true;
    } else if (destination.type === "OffFarm" && destination.feedSupplement.silageStack) {
        return true;
    } else {
        return false;
    }
}

function showUtilisationField(analysis, destination) {
    const structure = analysis.structures?.find((s) => s.id === destination.structureId);
    const enterprise = analysis.enterprises?.find((e) => e.id === structure?.id);
    const isDairyGoat = enterprise?.type === "DairyGoat" || false;

    if (["SpecifiedBlocks", "AllBlocks", "Enterprise"].includes(destination.type) && !isDairyGoat) {
        return true;
    }
    return false;
}

function showStructureField(destination) {
    if (destination.type === "Structure") {
        return true;
    }
    return false;
}

function showStructureUtilisationField(analysis, destination) {
    const structure = analysis.structures?.find((s) => s.id === destination.structureId);
    const enterprise = analysis.enterprises?.find((e) => e.id === structure?.enterpriseId);
    const isDairyGoat = enterprise?.type === "DairyGoat" || false;

    if (destination.type === "Structure" && (!isDairyGoat || structure?.type === "MilkingShed")) {
        return true;
    }
    return false;
}

function showBlockTypeField(destination) {
    if (["SpecifiedBlocks", "AllBlocks"].includes(destination.type)) {
        return true;
    }
    return false;
}

function showBlocksField(destination) {
    if (destination.type === "SpecifiedBlocks") {
        return true;
    }
    return false;
}

function showEnterpriseField(destination) {
    if (destination.type === "Enterprise") {
        return true;
    }
    return false;
}

function showSpecifiedFeedingFields(destination) {
    const hasApplications = destination.applications?.length > 0;
    const hasBlocks = destination.blockIds?.length > 0;
    const isAllBlocks = destination.type === "AllBlocks";
    const hasEnterprise = !!destination.enterpriseId;
    return hasApplications || hasBlocks || isAllBlocks || hasEnterprise;
}

function getSpecifiedFeedingDestinationTitle(analysis, destination, application, refData) {
    if (destination.type === "AllBlocks") {
        return "All blocks";
    }

    const block = analysis.blocks.find((b) => b.id === application.blockId);
    if (block) {
        return block.name;
    }

    const enterprise = analysis.enterprises.find((e) => e.id === application.enterpriseId);
    if (enterprise) {
        return utils.valueToText(refData.enterpriseTypes, enterprise.type);
    }

    return "Destination";
}
