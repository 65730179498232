import { useState, useEffect } from "react";
import { httpClient } from "common/httpClient";
import * as geoJsonUtils from "common/geoJsonUtils";
import { useMapLayerToggleControl, useHideShowMapLayer, useMapBounds } from "./_hooks";

const MAX_ZOOM = 12;

export default function LinzParcelsLayerToggle({ mapData, mapId }) {
    const layerId = geoJsonUtils.LAYER.LegalParcel;
    const showLayer = useMapLayerToggleControl(mapData, mapId, layerId, MAX_ZOOM);
    useHideShowMapLayer(mapData, layerId, showLayer);
    useLinzParcels(mapData, layerId, showLayer);
    return null;
}

function useLinzParcels(mapData, layerId, showLayer) {
    const [linzParcelsGeoJson, setLinzParcelsGeoJson] = useState(null);
    const bounds = useMapBounds(mapData);

    useEffect(() => {
        if (showLayer && bounds?.west) {
            getLegalParcelsGeoJsonAsync(bounds.west, bounds.south, bounds.east, bounds.north)
                .then(response => setLinzParcelsGeoJson(response))
                .catch(() => setLinzParcelsGeoJson([]));
        } else {
            setLinzParcelsGeoJson(null);
        }
    }, [mapData, showLayer, bounds]);

    useEffect(() => {
        if (mapData) {
            mapData.forEach((feature) => {
                if (feature.getProperty("layer") === layerId) {
                    mapData.remove(feature);
                }
            });
            if (linzParcelsGeoJson) {
                mapData.addGeoJson(linzParcelsGeoJson);
            }
        }
    }, [mapData, layerId, linzParcelsGeoJson]);
}

/**
* Fetch legal parcels GeoJSON from the server
**/
async function getLegalParcelsGeoJsonAsync(minX, minY, maxX, maxY) {
    const response = await httpClient.get(`legalParcels?minX=${minX}&minY=${minY}&maxX=${maxX}&maxY=${maxY}`);
    if (response) {
        response.features = response.features.map((feature) => {
            feature.properties.visible = true;
            feature.properties.layer = geoJsonUtils.LAYER.LegalParcel;
            return feature;
        });
    }
    console.log(response);
    return response;
}
