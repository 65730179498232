import { useModal } from "common/hooks";
import ActionLink from "components/ActionLink";
import { OrgModal } from "containers/Admin/Orgs";

export default function EditOrgActionLink({ org, label, className = "IconLink--edit" }) {
    const [orgModal, openEditOrgModal] = useEditOrgModal(org);
    return (<>
        <ActionLink id={`edit-org-${org.id}`} className={className} onClick={openEditOrgModal}>
            {label}
        </ActionLink>
        {orgModal}
    </>);
}

const useEditOrgModal = (org) => {
    const [modal, openModal] = useModal(OrgModal);

    const openEditOrgModal = () => {
        const modalProps = {
            org
        };
        openModal(modalProps);
    };

    return [modal, openEditOrgModal];
}
