import Page from "components/Page";
import PageBody from "components/PageBody";
import NationalReporting from "../Admin/NationalReporting/NationalBenchmarks";

export default function Benchmarking({ farm }) {
    return (
        <Page>
            <PageBody>
                <NationalReporting farm={farm} />
            </PageBody>
        </Page>
    );
}
