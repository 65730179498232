
export default function RadioField({ input, children, disabled, onChange }) {
    const handleOnChange = (e) => {
        const value = e.target.value;

        if (input.onChange) input.onChange(value);

        if (onChange) onChange(value);
    };

    const id = `${input.name}_${input.value}`;
    return (
        <label className="Radio" htmlFor={id}>
            <input type="radio" {...input} id={id} data-test-id={id} onChange={handleOnChange} className="Radio-input" disabled={disabled} />
            <span className={disabled ? "Radio-label u-textMidGrey" : "Radio-label"}>{children}</span>
        </label>
    );
}
