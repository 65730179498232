import ActionLink from "components/ActionLink";
import { useMoveUserModal } from "containers/Admin/Users/MoveUserModal";

export default function MoveUserActionLink({ user, children = null, className = "IconLink--users" }) {
    const [moveUserModal, openMoveUserModal] = useMoveUserModal(user);
    return (<>
        <ActionLink id={`move-user-${user.id}`} title="Move user" className={className} onClick={openMoveUserModal}>
            {children}
        </ActionLink>
        {moveUserModal}
    </>)
}
