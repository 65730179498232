import { useQuery } from "@tanstack/react-query";
import { Link } from "react-router-dom";
import { httpClient } from "common/httpClient";
import { useIsCouncilUser } from "common/hooks";
import { Panel, PanelBody } from "components/Panel";
import SortableTable from "components/new/SortableTable";
import SortableTableHeader from "components/new/SortableTableHeader";
import PublicationList from "./PublicationList";
import { ZendeskLink } from "components/Help";
import { useMyPublicationsSummary } from "containers/Reporting/_hooks";

export default function MyPublications() {
    const { isLoading: myPublicationsSummaryLoading, data: myPublicationsSummary } = useMyPublicationsSummary();
    const { isLoading: myRecentPublicationsLoading, data: myRecentPublications } = useMyRecentPublications();

    const hasPublicationsSummary = myPublicationsSummary?.length > 0;
    const hasRecentPublications = myRecentPublications?.length > 0;
    if (!hasPublicationsSummary && !hasRecentPublications) {
        return null;
    }

    const statuses = ["Draft", "Submitted", "Returned", "Reviewing", "Processed", "Closed"];
    const flattenedData = myPublicationsSummary.map((p) => {
        const { publicationSummaryCount: psc = [] } = p;
        const total = psc.reduce((total, s) => (total += s.count), 0);
        const statusObj = statuses.reduce(
            (obj, status) => {
                const value = psc.find((s) => s.status === status) ? psc.find((s) => s.status === status).count : 0;
                obj[status.toLowerCase()] = value;
                return obj;
            },
            { total }
        );
        return { ...p, ...statusObj };
    });

    const info = (
        <>
            The following table shows Year End analyses that have been published to your organisation. You can view aggregated reporting and benchmarking for these publications by selecting from the reports on the left of each row. To see a user guide on aggregated reporting,{" "}
            <b>
                <ZendeskLink title="click here" url="https://support.overseer.org.nz/hc/en-us/articles/900000809586" target="_blank" rel="noopener noreferrer" id="lnk-open-guide" />{" "}
            </b>
        </>
    );

    return (
        <>
            <Panel title="Publications" iconClassName="IconLink--bookmarks" info={info} loading={myPublicationsSummaryLoading || myRecentPublicationsLoading}>
                <PanelBody loading={myPublicationsSummaryLoading || myRecentPublicationsLoading}>
                    <h3 className="u-mt-0 u-mb-md">Publication report summary</h3>
                    <SortableTable data={flattenedData} defaultSortCriteria={{ fieldName: "year", fieldType: "number", desc: true }}>
                        {({ data, sortCriteria, handleSort }) => {
                            return (
                                <table>
                                    <thead>
                                        <tr>
                                            <SortableTableHeader label="Year" fieldName="year" sortCriteria={sortCriteria} handleSort={handleSort} number />
                                            <SortableTableHeader label="Publications" fieldName="total" sortCriteria={sortCriteria} handleSort={handleSort} number />
                                            <SortableTableHeader label="Draft" fieldName="draft" sortCriteria={sortCriteria} handleSort={handleSort} number />
                                            <SortableTableHeader label="Submitted" fieldName="submitted" sortCriteria={sortCriteria} handleSort={handleSort} number />
                                            <SortableTableHeader label="Returned" fieldName="returned" sortCriteria={sortCriteria} handleSort={handleSort} number />
                                            <SortableTableHeader label="Reviewing" fieldName="reviewing" sortCriteria={sortCriteria} handleSort={handleSort} number />
                                            <SortableTableHeader label="Processed" fieldName="processed" sortCriteria={sortCriteria} handleSort={handleSort} number />
                                            <SortableTableHeader label="Closed" fieldName="closed" sortCriteria={sortCriteria} handleSort={handleSort} number />
                                            <th className="th--shrink"></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {data.map((summary) => {
                                            return (
                                                <tr key={summary.year} className="hover">
                                                    <td>
                                                        <Link to={`/app/publicationReporting/publications?year=${summary.year}`} id="link-year">
                                                            {summary.year}
                                                        </Link>
                                                    </td>
                                                    <td>{summary.total}</td>
                                                    <td>
                                                        {summary.draft > 0 ? (
                                                            <Link to={`/app/publicationReporting/publications?year=${summary.year}&status=Draft`} id="link-draft">
                                                                {summary.draft}
                                                            </Link>
                                                        ) : (
                                                            <span>{summary.draft}</span>
                                                        )}
                                                    </td>
                                                    <td>
                                                        {summary.submitted > 0 ? (
                                                            <Link to={`/app/publicationReporting/publications?year=${summary.year}&status=Submitted`} id="link-submitted">
                                                                {summary.submitted}
                                                            </Link>
                                                        ) : (
                                                            <span>{summary.submitted}</span>
                                                        )}
                                                    </td>
                                                    <td>
                                                        {summary.returned > 0 ? (
                                                            <Link to={`/app/publicationReporting/publications?year=${summary.year}&status=Returned`} id="link-returned">
                                                                {summary.returned}
                                                            </Link>
                                                        ) : (
                                                            <span>{summary.returned}</span>
                                                        )}
                                                    </td>
                                                    <td>
                                                        {summary.reviewing > 0 ? (
                                                            <Link to={`/app/publicationReporting/publications?year=${summary.year}&status=Reviewing`} id="link-reviewing">
                                                                {summary.reviewing}
                                                            </Link>
                                                        ) : (
                                                            <span>{summary.reviewing}</span>
                                                        )}
                                                    </td>
                                                    <td>
                                                        {summary.processed > 0 ? (
                                                            <Link to={`/app/publicationReporting/publications?year=${summary.year}&status=Processed`} id="link-closed">
                                                                {summary.processed}
                                                            </Link>
                                                        ) : (
                                                            <span>{summary.processed}</span>
                                                        )}
                                                    </td>
                                                    <td>
                                                        {summary.closed > 0 ? (
                                                            <Link to={`/app/publicationReporting/publications?year=${summary.year}&status=Closed`} id="link-closed">
                                                                {summary.closed}
                                                            </Link>
                                                        ) : (
                                                            <span>{summary.closed}</span>
                                                        )}
                                                    </td>
                                                    <td>
                                                        <Link to={`/app/publicationReporting/aggregated?year=${summary.year}`} id="aggregated-link" className="IconLink--stats-dots"></Link>
                                                    </td>
                                                </tr>
                                            );
                                        })}
                                    </tbody>
                                </table>
                            );
                        }}
                    </SortableTable>
                    {hasRecentPublications && (
                        <>
                            <h3 className="u-mt-lg u-mb-md">Recent publications</h3>
                            <PublicationList myPublications={myRecentPublications} />
                        </>
                    )}
                </PanelBody>
            </Panel>
        </>
    );
}

function useMyRecentPublications() {
    const isCouncilUser = useIsCouncilUser();

    const query = useQuery({
        queryKey: ["dashboard", "recent-publications"],
        queryFn: async () => {
            if (isCouncilUser) {
                const searchDate = new Date();
                searchDate.setDate(searchDate.getDate() - 7);
                return httpClient.get(`publications/search?searchText=${searchDate.toLocaleDateString("en-NZ")}`);
            } else {
                return null;
            }
        },
        retry: false,
        refetchOnWindowFocus: false,
    });

    return {
        isFetching: query.isFetching,
        isLoading: query.isLoading,
        data: query.data,
        error: query.error,
    };
}
