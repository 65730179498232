import React from "react";
import { compose, withAppContext } from "containers/HOC";
import PublicationList from "containers/Dashboard/PublicationList";
import { Panel, PanelBody } from "components/Panel";
import useDetectPrint from "react-detect-print";

const Publications = ({ refData, publications = [], loading }) => {
    const printing = useDetectPrint();
    const recordCount = publications.length;

    return (
        <Panel title={`Publications (${recordCount})`} className="skyblue">
            <PanelBody grey={!printing} loading={loading}>
                {recordCount === 0 ? (
                    <div className="HeroPanel">
                        <div className="Tile-body-message">
                            <h3 className="u-mt-md">There are no results for this period</h3>
                        </div>
                    </div>
                ) : (
                    <PublicationList myPublications={publications} pageSize={30} />
                )}
            </PanelBody>
        </Panel>
    );
};

export default compose(withAppContext)(Publications);
